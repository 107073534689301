import React, {useState} from 'react';

import {Form, Input, Button, Row, Col} from 'antd';
import {faArrowLeft, faTrash, faXmark} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {ButtonStyled, ModalStyled} from '../styles';
import {Button as CommonButton} from '@/components/common-components/v2';
import styled from 'styled-components';
import {faCheck} from '@fortawesome/pro-solid-svg-icons';
import {StyledSpin} from '../../../../Utils/styledSpin';

const UpdateServiceModal = ({serviceIndex, setIsModalOpen, onClose, updateData, primaryCategory, modalSuggestion, serviceData = []}) => {
  const selectedValue = serviceData?.find((item, index) => index === serviceIndex);
  const [form] = Form.useForm();
  const {TextArea} = Input;
  const [deletingService, setDeletingService] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [displayNameValue, setDisplayNameValue] = useState(selectedValue?.displayName);
  const [descriptionValue, setDescriptionValue] = useState(selectedValue?.description);
  const [suggestionIgnored, setSuggestionIgnored] = useState(false);
  const suggestedObj = modalSuggestion?.value?.find(item => item?.displayName === selectedValue?.displayName || item?.serviceTypeId === selectedValue?.servieTypeId?.key);

  const handleSubmit = async values => {
    try {
      setButtonLoader(true);
      const newData = {
        type: values.type || 'custom',
        displayName: values.displayName?.trim(),
        price: values.price || null,
        description: values.description,
        ...(selectedValue?.serviceTypeId ? {serviceTypeId: selectedValue?.serviceTypeId} : {categoryName: primaryCategory?.key}),
      };


      const updatedServiceData = [...serviceData];
      updatedServiceData[serviceIndex] = newData;


      updatedServiceData.forEach((service, index) => {
        if (index !== serviceIndex) {
          service.categoryName = primaryCategory?.key;
        }
      });

      const updatedServices = updatedServiceData?.map(service => ({
        type: service.type || 'custom',
        displayName: service.displayName?.trim(),
        price: service?.price || null,
        currency: service?.currency,
        description: service?.description,
        ...(service?.serviceTypeId ? {serviceTypeId: service?.serviceTypeId} : {categoryName: primaryCategory?.key}),
      }));


      const field = [
        {
          fieldName: 'services',
          fieldType: 'FIELD',
          value: updatedServices,
        },
      ];

      await updateData(field, false);
      onClose(true);
      setButtonLoader(false);
    } catch (error) {
      return false;
    }
  };
  const onDisplayNameChange = e => {
    const value = e.target.value;
    setDisplayNameValue(value);
  };
  const onDescriptionChange = e => {
    const value = e.target.value;
    setDescriptionValue(value);
  };

  const handleDelete = async () => {
    setDeletingService(true);
    try {
      const updatedServiceData = serviceData.filter((_, index) => index !== serviceIndex);


      updatedServiceData.forEach(service => {
        service.categoryName = primaryCategory?.key;
      });

      const updatedServices = updatedServiceData?.map(service => ({
        type: service.type || 'custom',
        displayName: service.displayName?.trim(),
        price: service?.price || null,
        currency: service?.currency,
        description: service?.description,
        ...(service?.serviceTypeId ? {serviceTypeId: service?.serviceTypeId} : {categoryName: primaryCategory?.key}),
      }));

      const field = [
        {
          fieldName: 'services',
          fieldType: 'FIELD',
          value: updatedServices,
        },
      ];
      await updateData(field, false);
      setIsModalOpen(true);
      onClose();
      setDeleteModalVisible(false);
    } catch (error) {
      return false;
    } finally {
      setDeletingService(false);
    }
  };

  return (
    <>
      <StyledModal
        width='800px'
        height='800px'
        visible={true}
        closeIcon={<FontAwesomeIcon icon={faXmark} onClick={() => onClose()} />}
        removePaddings
        title={
          <div style={{paddingInline: 30}}>
            <div style={{marginBottom: '10px', fontSize: '14px', fontWeight: '400', cursor: 'pointer'}} onClick={() => onClose(true)}>
              <FontAwesomeIcon icon={faArrowLeft} style={{marginRight: '12px'}} /> Go back</div>
            <div>Edit service details</div>
          </div>
        }>

        <StyledForm form={form} initialValues={{...selectedValue, displayName: selectedValue?.displayName || selectedValue?.serviceTypeId?.label}} layout='vertical' onFinish={handleSubmit}>
          <Row gutter={[20, 4]} style={{paddingInline: 30}}>
            <Col span={24}>
              <CharacterLimit>{displayNameValue?.length || selectedValue?.serviceTypeId?.label?.length || 0} / 120</CharacterLimit>
              <Form.Item
                name='displayName'
                label='Service'
                rules={[
                  {required: true, message: 'Please enter a service name'},
                  {whitespace: true, message: 'Please enter a service name'},
                  {validator: (_, value) => {
                    if (value?.length > 120) {
                      return Promise.reject(new Error('Service name should not be more than 120 characters'));
                    } else return Promise.resolve();
                  }},
                ]}
              >

                <InputStyled
                  placeholder='Enter service name'
                  maxLength={120}
                  onChange={onDisplayNameChange}
                  disabled={Boolean(selectedValue?.serviceTypeId)}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24}>
              <Form.Item
                name='price'
                label='Service price (USD)'
                rules={[{required: false}]}
              >
                <InputStyled type='number' placeholder='Enter service price'/>
              </Form.Item>
            </Col>

            <Col span={24}>
              <CharacterLimit >{descriptionValue?.length || 0} / 300</CharacterLimit>
              <Form.Item
                name='description'
                label='Service description'
                rules={[
                  {required: false},
                  {validator: (_, value) => {
                    if (value?.length > 300) {
                      return Promise.reject(new Error('Description should not be more than 300 characters'));
                    } else return Promise.resolve();
                  }},
                ]}
              >
                <TextArea
                  style={{height: '90px', borderRadius: '8px'}}
                  onChange={onDescriptionChange}
                  placeholder='Enter service description'
                />
              </Form.Item>
            </Col>

            {(suggestedObj?.description?.length > 0 && !suggestionIgnored && suggestedObj?.description !== selectedValue?.description) && <Col span={24}>
              <Form.Item
                label='OTTO Suggested description'
                rules={[{required: false}]}
                style={{marginBottom: 0}}
              >
                <SuggestionTextArea maxLength={300} rows={3} className='suggestionTextarea' value={suggestedObj?.description} disabled />
              </Form.Item>
              <div className={'suggestionButtons'}>
                <CommonButton onClick={() => {
                  form.setFieldValue('description', suggestedObj?.description);
                  setDescriptionValue(suggestedObj?.description);
                }} icon={<FontAwesomeIcon icon={faCheck} color='#2D6CCA' fontSize={16} />} htmlType='button' background='#2D6CCA18' style={{color: '#2D6CCA'}} className={'suggestionButton'}>Use this description</CommonButton>
                <CommonButton onClick={() => setSuggestionIgnored(true)} htmlType='button' background='#4E51561A' style={{color: '#4E5156'}} className={'suggestionButton'}>Decline</CommonButton>
              </div>
            </Col>}
            <Col span={24}>
              <div className='buttons-container' style={{marginLeft: '-14px'}}>
                <Button type='link' onClick={() => setDeleteModalVisible(true)} style={{color: '#2D6CCA', marginTop: 18, marginBottom: 18}}>
                  <FontAwesomeIcon icon={faTrash} style={{marginRight: '8px'}} />Delete service
                </Button>
              </div>
            </Col>
          </Row>
          <div className={'footer'}>
            <ButtonStyled disabled={buttonLoader} loading={buttonLoader} htmlType='submit' style={{lineHeight: '14px', color: '#fff'}}>Save Changes</ButtonStyled>
            <button onClick={() => onClose(true)} type='button' disabled={buttonLoader} className={'unstyledButton'}>Cancel</button>
          </div>
        </StyledForm>

        {deleteModalVisible && <div className={'deleteOverlay'}>
          <div className={'blurOverlay'} />
          <div className={'deleteModal'}>
            <div className={'content'}>
              <div>
                <div className={'contentTitle'}>Delete service?</div>
                <div className={'contentDescription'}>{selectedValue?.displayName} will be removed from all categories and will no longer be visible on your Business Profile</div>
              </div>
              <div className={'deleteFooter'}>
                <button disabled={deletingService} className={'unstyledButton'} type='button' onClick={() => setDeleteModalVisible(false)} style={{color: '#2D6CCA'}}>Cancel</button>
                <button disabled={deletingService} className={'unstyledButton'} type='button' onClick={() => handleDelete()} style={{color: '#2D6CCA'}}>
                  {deletingService && <StyledSpin fontSize={14} />} Delete
                </button>
              </div>
            </div>
          </div>
        </div>}
      </StyledModal>
    </>
  );
};

export default UpdateServiceModal;

const StyledModal = styled(ModalStyled)`
    .rc-dialog-header {
      padding: 0px;
    }
  .rc-dialog-content {
    padding-top: 30px !important;
  }
  .unstyledButton {
    background: transparent;
    border: none;
    padding: 0;
    cursor: pointer;
    outline: none;
    display: flex;
    align-items: center;
    gap: 4px;
    &:disabled {
      cursor: not-allowed;
      opacity: 60%;
    }
  }

  .deleteOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border-radius: 10px;
    overflow: hidden;

    .blurOverlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(249, 249, 251, 0.8);
    }

    .deleteModal {
      width: 90%;
      max-width: 620px;
      padding: 17px;
      border-radius: 8px;
      overflow: hidden;
      background-color: #fff;
      box-shadow: 0px 4px 4px 0px #00000040;
      z-index: 1;
      border: 1px solid #D8D8D8;

      .content {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .contentTitle {
          color: #121212;
          font-size: 16px;
          font-weight: 500;
          line-height: 19.36px;
          margin-bottom: 4px;
        }
        .contentDescription {
          color: #4E5156;
          font-size: 12px;
          font-weight: 400;
          line-height: 14.52px;
        }

        .deleteFooter {
          display: flex;
          align-items: center;
          gap: 30px;
          justify-content: flex-end;
        }
      }
    }
  }
`;

const StyledForm = styled(Form)`
    textarea {
      resize: none;
    }
    .footer {
    padding: 18px 30px;
    display: flex;
    align-items: center;
    border-top: 1px solid #E8E8E8;
    gap: 30px;
  }
    .formFieldsContainer {
      padding-inline: 30px;
      margin-bottom: 20px;
    }

    .ant-form-item {
      margin-bottom: 6px;
    }
    .ant-form-item-label {
      padding-bottom: 3px;
      label {
        font-size: 12px;
        font-weight: 400;
        line-height: 14.52px;
        color: #4E5156;
      }

    }
    .ant-select-selector {
      height: 38px !important;
      border: 1px solid #D8D8D8 !important;
      border-radius: 8px !important;
    }
    .suggestionTextarea {
      border-radius: 8px;
      border-bottom: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    .suggestionButtons {
      border-radius: 8px;
      border: 1px solid #D8D8D8;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-top: 0;
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 10px 15px;
      background-color: #fff;
      .suggestionButton {
        max-height: 30px;
        border-radius: 8px;
        font-size: 12px;
        font-weight: 500;
        line-height: 14.52px;
        margin: 0;
        display: flex;
        align-items: center;
        gap: 5px;
      }
    }
`;

const InputStyled = styled(Input)`
  font-family: Inter;
  height:38px !important;
  font-size: 14px;
  border:1px solid #D8D8D8;
  border-radius: 8px;
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: lightgray;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;
const CharacterLimit = styled.div`
  font-family: Inter;
  font-size: 12px;
  color: #4E5156;
  text-align: right;
  font-weight: 400;
  position: relative;
    top: 17px;
`;
const SuggestionTextArea = styled(Input.TextArea)`
  &:disabled {
    color: #121212 !important;
    background: #fff;
  }
`;
