import {Button} from '@/components/common-components/v2';
import {useStore} from '@/store/root-store';
import {updateGTMDatalayer} from '@/utils/gtm';
import {faStar, faTrophy, faGem, faRocket, faArrowUpRight} from '@fortawesome/pro-regular-svg-icons';
import {faCaretDown, faCaretUp, faCircleInfo, faPlus} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Tooltip} from 'antd';
import React, {useEffect, useRef, useState} from 'react';
import styles from './styles.module.scss';
import {SkeletonRingLoader} from '@/components/common-components/components';
import {GROWTH_PLAN_ID, PRO_PLAN_ID, STARTER_PLAN_ID, CUSTOM_PLAN_ID} from '@/constants';
import {useTranslation} from 'next-i18next';
import styled from 'styled-components';
import {openUrl} from '@/utils/router';
import {getRequestPricelink} from '@/utils/legacy';
import {adjustLightness, hexToRgba, metaClickEvents} from '@/utils/functions';
import {observer} from 'mobx-react';
import classNames from 'classnames';
import {faGlobe} from '@fortawesome/pro-light-svg-icons';
import {faInfo} from '@fortawesome/pro-solid-svg-icons';
import useWindowSize from '@/utils/hooks/useWindowSize';

interface Props {
  handleSelection?: (...params) => void;
  planNameStyle?: {
    color?: string;
    fontSize?: string;
  };
  subHeadingStyle?: {
    color?: string;
    fontSize?: string;
    width?:string;
  };
  priceStyle?: {
    color?: string;
    fontSize?: string;
  } ;
  ctaButtonStyle?: {
    background?: string;
    color?: string;
  };
  cardBackgroudStyle?: {
    backgroundColor?: string;
    backgroundImage?: string;
    border?: string;
    borderTop?: string;
    maxWidth?: string;
  };
  planDetailStyle?: {
    color?: string;
  };
  activeClass?: string;
  contactButtonText?: string;
  subscribeButtonText?: string;
  responsive?: boolean;
  activeButtonText?: string;
  isResponsive?: boolean;
  isSidebar?: boolean;
  displayBrandColor?: boolean;
  darkMode?: boolean;
}

const PricingPlan: React.FC<Props> = observer(({
  handleSelection,
  planNameStyle,
  subHeadingStyle,
  priceStyle,
  ctaButtonStyle,
  cardBackgroudStyle,
  planDetailStyle,
  activeClass = 'activePlanCardDark',
  contactButtonText = 'Get a Quote',
  subscribeButtonText,
  responsive,
  isResponsive = false,
  activeButtonText = 'Unsubscribe',
  isSidebar = false,
  displayBrandColor = false,
  darkMode = true,
}) => {
  const modalRef = useRef<HTMLInputElement>(null);
  const footerModalRef = useRef<HTMLInputElement>(null);
  const [shakingEffect, setShakingEffect] = useState(false);
  const [expandedPlans, setExpandedPlans] = useState([]);
  const {width} = useWindowSize();
  const {t} = useTranslation('common');
  const {
    plans: {getPlansAndPrices, loadPlans, payAnnualValue, getPlansList, planAndPricesLoading},
    settings: {
      customer: {
        profile,
        setTrialPagePlan,
        loading,
        profile: {
          isWhitelabel,
          priceStarterPlan,
          priceGrowthPlan,
          priceProPlan,
          shouldShowPricingPage,
          whitelabelOtto,
          brandColor,
          isVendastaClient,
          isParentAccount,
        },
      },
    }}= useStore('');

  useEffect(() => {
    function handleClickOutside(event) {
      if (modalRef.current && (!modalRef.current.contains(event.target) && !footerModalRef.current?.contains(event.target))) {
        setShakingEffect(true);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [modalRef]);
  useEffect(() => {
    setTimeout(() => {
      shakingEffect && setShakingEffect(false);
    }, 900);
  }, [shakingEffect]);

  const plansToShow = [GROWTH_PLAN_ID, PRO_PLAN_ID, STARTER_PLAN_ID, CUSTOM_PLAN_ID];

  const planIcons = {
    [STARTER_PLAN_ID]: faRocket,
    [GROWTH_PLAN_ID]: faTrophy,
    [PRO_PLAN_ID]: faStar,
    [CUSTOM_PLAN_ID]: faGem,
  };
  const capitalizeText = text => {
    if (typeof text !== 'string') return text;
    const loweredText = text?.toLowerCase();
    return `${loweredText?.toUpperCase()?.slice(0, 1)}${loweredText?.slice(1)}`;
  };
  useEffect(() => {
    loadPlans(t, isWhitelabel, whitelabelOtto);
  }, []);

  const getPricingBtnText = plan => {
    if (((profile?.plan === 8 || profile?.plan === 1) && (plan.id === 10 || plan.id===1))) {
      return t('downgrade');
    } else {
      return t('upgrade');
    }
  };

  const planPrice = (name: string) => {
    if (name === 'STARTER') {
      return shouldShowPricingPage ? priceStarterPlan ? `$${priceStarterPlan}` : 'Call us' : false;
    } else if (name.toLowerCase() === 'growth') {
      return shouldShowPricingPage ? priceGrowthPlan ? `$${priceGrowthPlan}` : 'Call us' : false;
    } else if (name === 'PRO') {
      return shouldShowPricingPage ? priceProPlan ? `$${priceProPlan}` : 'Call us' : false;
    } else if (name === 'Enterprise') {
      return 'Call us';
    }
  };

  const brandVariables = {
    '--brand-color': adjustLightness(brandColor, 29),
    '--brand-color-darkened': hexToRgba(brandColor, 1),
    '--brand-color-lightened': hexToRgba(brandColor, 0.8),
    '--brand-button-color': adjustLightness(brandColor, 29),
    '--brand-button-hover-color': `linear-gradient(0deg, rgba(0, 0, 0, 0.18), rgba(0, 0, 0, 0.18)), ${(isWhitelabel && brandColor) ? brandColor : '#7f4ead'}`,
  };

  const showBrandColor = !!(displayBrandColor && isWhitelabel && brandColor);


  const getIcon = (iconName, isSubscribed)=>{
    const color = showBrandColor ? hexToRgba(brandColor, 1) : '#AA85EC';
    const whiteColor = isSubscribed ? '#fff' : color;

    switch (iconName) {
      case 'faGlobe':
        return <FontAwesomeIcon icon={faGlobe} color={whiteColor} />;
      case 'faInfo':
        return <FontAwesomeIcon icon={faInfo} color={whiteColor} />;
      case 'faCheck':
        return <IconImage src='/img/choose-plan/circleCheck.svg'/>;
      default:
        return <FontAwesomeIcon icon={faPlus} color={color} />;
    }
  };

  return (
    <div
      className={classNames({[styles.wrapper]: isResponsive})}
      style={{width: responsive ? '100%' : '', overflowX: 'auto', ...(showBrandColor ? brandVariables : {})}}
      ref={modalRef}
    >
      {planAndPricesLoading? (
        <div style={{width: '100%', minWidth: 400}}>
          <SkeletonRingLoader height={'400'}/>
        </div>
      ) : (
        <div style={{display: !isResponsive && 'grid'}} className={classNames(styles.ProductPackage, {[styles.pricingContainer]: isResponsive})}>{getPlansList?.filter(item => plansToShow?.includes(item?.id) && (item.name === 'Enterprise' ? (isResponsive && width <= 754 ? true : false) : true))?.map((item, idx) => {
          const localPlanNameStyle= item?.id == profile?.plan ? {} : planNameStyle;
          const planCurrentPrice = getPlansAndPrices?.find(x => x?.planId == item?.id)?.price ?? 'Call us';
          const planAnnualPrice = getPlansAndPrices?.find(x => x?.planId == item?.id)?.yearlyCost || 'Call us';
          const isSubscribed = item?.id == profile?.plan;
          return (
            <div
              style={{width: responsive && '100%', minWidth: responsive ? '25%' : 240, ...cardBackgroudStyle}}
              key={idx}
              // className={`${item?.id == profile?.plan ? styles[activeClass] : ''} ${styles.package} ${styles.cardBackgroud} ${styles.pricingCard}`}
              className={classNames(
                styles.package,
                styles.cardBackgroud,
                {
                  [styles[activeClass]]: isSubscribed,
                  [styles.pricingCard]: isResponsive,
                  [styles.activePlanCardBranded]: isSubscribed && showBrandColor,
                  [styles.planCardBranded]: showBrandColor,
                  [styles.cardBackgroudResponsive]: !isResponsive,
                  ...(darkMode ? {[styles.showBrandColor]: isSubscribed && showBrandColor} : {[styles.showBrandColorNonLinear]: isSubscribed && showBrandColor}),
                  ...(darkMode ? {[styles.showDefaultColor]: isSubscribed && !showBrandColor} : {[styles.showDefaultColorNonLinear]: isSubscribed && !showBrandColor}),
                },
              )}
            >
              {item?.bestValueTag && <PTag responsive={isResponsive} brandColor={hexToRgba(brandColor, 1)} showBrandColor={showBrandColor}>MOST POPULAR</PTag>}
              <div
                className={classNames({[styles.topWrapper]: isResponsive})}
                onClick={() => {
                  if (!isResponsive) return;
                  if (expandedPlans?.includes(item?.id)) {
                    setExpandedPlans(prev => prev?.filter(it => it != item?.id));
                  } else {
                    setExpandedPlans(prev => [...prev, item?.id]);
                  }
                }}

              >
                <div className={classNames({[styles.topWrapperInner]: isResponsive})}>
                  <div className={classNames(styles.icon, {[styles.planIcon]: isResponsive})}><FontAwesomeIcon icon={planIcons[item?.id?.toString()] || faRocket} /></div>
                  <div>
                    <div style={{display: 'flex', alignItems: 'center', gap: '7px'}}>
                      <div className={classNames(styles.planName, {[styles.titleText]: isResponsive})} style={localPlanNameStyle}>{capitalizeText(item?.name)}</div>
                      {item?.bestValueTag && <MPTag brandColor={hexToRgba(brandColor, 1)} showBrandColor={showBrandColor} defaultColor={!isSubscribed && item?.bestValueTag ? '#936BDA' : '#533B80'} responsive={isResponsive} className={styles.mpTagStyling}>MOST POPULAR</MPTag> }
                    </div>
                    <div className={classNames(styles.subHeading, {[styles.descriptionText]: isResponsive})} style={{...subHeadingStyle}}>{item?.description}</div>
                  </div>
                </div>
                {/* <del className={styles.cutPrice}>{item?.cutPrice}</del> */}
                <div className={classNames({[styles.priceContainer]: isResponsive})}>
                  <div className={classNames(styles.price, {[styles.priceText]: isResponsive})}
                    style={{display: 'flex', alignItems: payAnnualValue ? 'center' : 'baseline', gap: '5px', marginTop: '26px', ...priceStyle}}>
                    {!isWhitelabel? payAnnualValue ? planAnnualPrice : planCurrentPrice : planPrice(item?.name) ? planPrice(item?.name) : payAnnualValue ? planAnnualPrice : planCurrentPrice}
                    {!(CUSTOM_PLAN_ID == item?.id || (isWhitelabel && planPrice(item?.name) == 'Call us')) &&
                    <StyledSpan
                      className={classNames({[styles.perMonthTextStyle]: isSubscribed})}
                      style={{display: 'block', minWidth: CUSTOM_PLAN_ID != item?.id && payAnnualValue ? '100px' : 'auto'}}>
                      {payAnnualValue ? 'month / billed annually' : (
                        <>
                          <span style={!payAnnualValue ? {whiteSpace: 'nowrap'} : {}} className={classNames({[styles.perText]: isResponsive})}>
                            <span className={classNames({[styles.perMonthTextStyle]: isSubscribed})}>/ month</span>
                          </span>
                          <span className={classNames({[styles.perTextResponsive]: isResponsive})} style={{display: 'none'}}>/mo.</span>
                        </>
                      )}
                    </StyledSpan>
                    }
                  </div>
                  <FontAwesomeIcon
                    className={classNames(styles.collapseIcon, {[styles.collapseIconResponsive]: isResponsive}, {[styles.collapseIconStyling]: isResponsive})}
                    icon={expandedPlans?.includes(item?.id) ? faCaretUp : faCaretDown}
                    fontSize={14}
                    color={item?.id == profile?.plan ? '#fff' : '#936BDA'}
                  />
                </div>
              </div>
              <div className={classNames(styles.offerWrapperStyling, {[styles.collapsedItem]: !expandedPlans?.includes(item?.id) && isResponsive})}>
                <Tooltip zIndex={isSidebar && 10000} title={(isWhitelabel && !isParentAccount) ? 'Child accounts don\'t have access to checkout' : (loading || (planPrice(item?.name) == 'Call us' && profile?.isWhitelabel && !profile?.isParentAccount)) ? 'Contact us via email or phone to discuss upgrading your plan' : ''} overlayInnerStyle={{
                  maxWidth: 200,
                  padding: '10px',
                  background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), #2D2F34',
                  boxShadow: '0px 4px 30px rgba(0, 0, 0, 0.08)',
                  borderRadius: 8,
                }}>
                  <div
                    className={classNames(
                      styles.roundButton,
                      styles.button,
                      styles.roundButtonStyling,
                      {
                        [styles.actionButton]: isResponsive,
                      },
                    )}
                    style={ctaButtonStyle}
                  ><Button onClick={e => {
                      if (CUSTOM_PLAN_ID == item?.id) {
                        openUrl(getRequestPricelink(), '_blank');
                        return;
                      }
                      setTrialPagePlan(item.id);
                      metaClickEvents('click_plan_step_1', {plan: item.name});
                      updateGTMDatalayer({
                        event: CUSTOM_PLAN_ID == item?.id ? contactButtonText : item?.id == profile?.plan ? activeButtonText : subscribeButtonText ? `Choose ${capitalizeText(item?.name)}` : getPricingBtnText(item),
                        plan: item.name,
                      });
                      updateGTMDatalayer({'event': 'select plan', 'plan': item.name});
                      handleSelection({e: e, name: item?.name, id: item?.id});
                    }}
                    disabled={loading || (planPrice(item?.name) == 'Call us' && profile?.isWhitelabel && !profile?.isParentAccount) || isVendastaClient || (isWhitelabel && !isParentAccount)}>
                      {CUSTOM_PLAN_ID == item?.id ? contactButtonText : item?.id == profile?.plan ? activeButtonText : subscribeButtonText ? `Choose ${capitalizeText(item?.name)}` : getPricingBtnText(item)} {
                        <FontAwesomeIcon style={{marginLeft: 7}} icon={faArrowUpRight} color={isSubscribed ? (showBrandColor ? hexToRgba(brandColor, 1) : '#AA85EC' ) : '#FFF'}/>
                      }</Button>
                  </div>
                </Tooltip>
                <div
                  style={{paddingBottom: 0}}
                  className={classNames(
                    styles.offerPackage,
                    styles.offerPackageStyling,
                    {
                      [styles.offerPackageStylingResponsive]: isResponsive,
                    },
                  )}
                >
                  <>
                    {item?.features?.map((obj, i) => {
                      const localDetailTextStyle = item?.id == profile?.plan ? {} : planDetailStyle;
                      const isHeaderColored = obj?.header && !isSubscribed;
                      const iconName = getIcon(obj?.iconName, isSubscribed);

                      return (<DetailText
                        className={classNames({[styles.bottomSpacing]: obj.header, [styles.borderLight]: (darkMode || isSubscribed) && obj.header, [styles.borderDark]: !darkMode && !isSubscribed && obj.header})} key={`sub_headings_${i}`}>
                        <span style={{width: '18px'}} className={classNames({[styles.iconWrapper]: obj?.iconName == 'faPlus' && showBrandColor})}>
                          {iconName}
                        </span>
                        <div
                          className={i === 1 && item.name != 'Enterprise' ? styles.subHeadingFree : ''}
                          style={idx == 1 ? {color: '#fff', flexGrow: 1} : {flexGrow: 1}}>
                          <DetailTextWraper textBg={obj} style={{...localDetailTextStyle}}
                            className={classNames(
                              {
                                [styles.boldTextStyle]: obj.isBold,
                                [styles.defaultColorText]: isHeaderColored && !showBrandColor,
                                [styles.brandColorText]: isHeaderColored && showBrandColor,
                                [styles.underlineStyles]: obj?.isUnderLine,
                              },
                              styles.detailText,
                            )}
                          >
                            {obj?.str}&nbsp;
                            {obj?.comingSoon && <ComingSoon>{obj?.comingSoon}</ComingSoon>}
                            <span>
                              {obj?.infoTooltip?.length > 0 && <Tooltip title={obj?.infoTooltip?.split(':')?.length >= 2 ? <div><b>{obj?.infoTooltip?.split(':')[0]}</b><p>{obj?.infoTooltip?.split(':')[1]}</p></div> : obj?.infoTooltip} color='#121212' zIndex={9999} overlayInnerStyle={{borderRadius: '6px', maxWidth: '250px', fontSize: '12px'}}>
                                <StyledIcon
                                  color='#80ca9d'
                                  icon={faCircleInfo}/>
                              </Tooltip>}
                            </span>
                          </DetailTextWraper>
                          {obj?.pointsList ? (
                            <ul className={styles.itemList} style={{...localDetailTextStyle}}>
                              {obj?.pointsList?.map(point => <li key={point}><Circle color={showBrandColor ? hexToRgba(brandColor, 1) : '#AA85EC'} />{point}</li>)}
                            </ul>
                          ) : null}
                        </div>
                      </DetailText>);
                    })}
                  </>
                </div>
              </div>
            </div>
          );
        })}


        {isResponsive && width <= 754 ? null : getPlansList?.filter(item => plansToShow?.includes(item?.id) && item.name === 'Enterprise')?.map((item, idx) => {
          const localPlanNameStyle= item?.id == profile?.plan ? {} : planNameStyle;
          const isSubscribed = item?.id == profile?.plan;
          return (
            <div
              style={{gridColumn: 'span 3', padding: '33px 26px', marginTop: 0, minWidth: '100%', ...cardBackgroudStyle}}
              key={idx}
              // className={`${item?.id == profile?.plan ? styles[activeClass] : ''} ${styles.package} ${styles.cardBackgroud} ${styles.pricingCard}`}
              className={classNames(
                styles.package,
                styles.cardBackgroud,
                styles.enterPriseWrapper,
                {
                  [styles[activeClass]]: isSubscribed,
                  [styles.pricingCard]: isResponsive,
                  [styles.activePlanCardBranded]: isSubscribed && showBrandColor,
                  [styles.planCardBranded]: showBrandColor,
                  [styles.cardBackgroudResponsive]: !isResponsive,
                  ...(darkMode ? {[styles.showBrandColor]: isSubscribed && showBrandColor} : {[styles.showBrandColorNonLinear]: isSubscribed && showBrandColor}),
                  ...(darkMode ? {[styles.showDefaultColor]: isSubscribed && !showBrandColor} : {[styles.showDefaultColorNonLinear]: isSubscribed && !showBrandColor}),
                },
              )}
            >
              {item?.bestValueTag && <PTag responsive={isResponsive} brandColor={hexToRgba(brandColor, 1)} showBrandColor={showBrandColor}>MOST POPULAR</PTag>}
              <div
                className={classNames({[styles.topWrapper]: isResponsive})}
                onClick={() => {
                  if (!isResponsive) return;
                  if (expandedPlans?.includes(item?.id)) {
                    setExpandedPlans(prev => prev?.filter(it => it != item?.id));
                  } else {
                    setExpandedPlans(prev => [...prev, item?.id]);
                  }
                }}

              >
                <div className={classNames({[styles.topWrapperInner]: isResponsive})}>
                  <div className={classNames(styles.icon, {[styles.planIcon]: isResponsive})}><FontAwesomeIcon icon={planIcons[item?.id?.toString()] || faRocket} /></div>
                  <div>
                    <div style={{display: 'flex', alignItems: 'center', gap: '7px'}}>
                      <div className={classNames(styles.planName, {[styles.titleText]: isResponsive})} style={localPlanNameStyle}>{capitalizeText(item?.name)}</div>
                      {item?.bestValueTag && <MPTag brandColor={hexToRgba(brandColor, 1)} showBrandColor={showBrandColor} defaultColor={!isSubscribed && item?.bestValueTag ? '#936BDA' : '#533B80'} responsive={isResponsive} className={styles.mpTagStyling}>MOST POPULAR</MPTag> }
                    </div>
                    <div className={classNames(styles.subHeading, {[styles.descriptionText]: isResponsive})} style={{...subHeadingStyle, marginBottom: 23.4}}>{item?.description}</div>
                  </div>
                </div>
                {/* <del className={styles.cutPrice}>{item?.cutPrice}</del> */}
                <div className={classNames({[styles.priceContainer]: isResponsive})}>
                  <FontAwesomeIcon
                    className={classNames(styles.collapseIcon, {[styles.collapseIconResponsive]: isResponsive}, {[styles.collapseIconStyling]: isResponsive})}
                    icon={expandedPlans?.includes(item?.id) ? faCaretUp : faCaretDown}
                    fontSize={14}
                    color={item?.id == profile?.plan ? '#fff' : '#936BDA'}
                  />
                </div>
                <div
                  style={{paddingBottom: 0}}
                  className={classNames(
                    styles.offerPackage,
                    styles.offerPackageStyling,
                    {
                      [styles.offerPackageStylingResponsive]: isResponsive,
                    },
                  )}
                >
                  <>
                    {item?.features?.map((obj, i) => {
                      const localDetailTextStyle = item?.id == profile?.plan ? {} : planDetailStyle;
                      const isHeaderColored = obj?.header && !isSubscribed;
                      const iconName = getIcon(obj?.iconName, isSubscribed);

                      return (<DetailText
                        className={classNames({[styles.bottomSpacing]: obj.header, [styles.borderLight]: (darkMode || isSubscribed) && obj.header, [styles.borderDark]: !darkMode && !isSubscribed && obj.header})} key={`sub_headings_${i}`}>
                        <span style={{width: '18px'}} className={classNames({[styles.iconWrapper]: obj?.iconName == 'faPlus' && showBrandColor})}>
                          {iconName}
                        </span>
                        <div
                          className={i === 1 && item.name != 'Enterprise' ? styles.subHeadingFree : ''}
                          style={idx == 1 ? {color: '#fff', flexGrow: 1} : {flexGrow: 1}}>
                          <DetailTextWraper textBg={obj} style={{...localDetailTextStyle, marginBottom: 0}}
                            className={classNames(
                              {
                                [styles.boldTextStyle]: obj.isBold,
                                [styles.defaultColorText]: isHeaderColored && !showBrandColor,
                                [styles.brandColorText]: isHeaderColored && showBrandColor,
                                [styles.underlineStyles]: obj?.isUnderLine,
                              },
                              styles.detailText,
                            )}
                          >
                            {obj?.str}&nbsp;
                            {obj?.comingSoon && <ComingSoon>{obj?.comingSoon}</ComingSoon>}
                            <span>
                              {obj?.infoTooltip?.length > 0 && <Tooltip title={obj?.infoTooltip?.split(':')?.length >= 2 ? <div><b>{obj?.infoTooltip?.split(':')[0]}</b><p>{obj?.infoTooltip?.split(':')[1]}</p></div> : obj?.infoTooltip} color='#121212' zIndex={9999} overlayInnerStyle={{borderRadius: '6px', maxWidth: '250px', fontSize: '12px'}}>
                                <StyledIcon
                                  color='#80ca9d'
                                  icon={faCircleInfo}/>
                              </Tooltip>}
                            </span>
                          </DetailTextWraper>
                          {obj?.pointsList ? (
                            <ul className={styles.itemList} style={{...localDetailTextStyle}}>
                              {obj?.pointsList?.map(point => <li key={point}><Circle color={showBrandColor ? hexToRgba(brandColor, 1) : '#AA85EC'} />{point}</li>)}
                            </ul>
                          ) : null}
                        </div>
                      </DetailText>);
                    })}
                  </>
                </div>
              </div>
              <div>
                <h1 style={{color: darkMode ? 'white': 'black'}} className={styles.headingStyle}>Get a Quote</h1>
                <Tooltip zIndex={isSidebar && 10000} title={(isWhitelabel && !profile?.isParentAccount) ? 'Child accounts don\'t have access to checkout' : (loading || (profile?.isWhitelabel && !profile?.isParentAccount)) ? 'Contact us via email or phone to discuss upgrading your plan' : ''} overlayInnerStyle={{
                  maxWidth: 200,
                  padding: '10px',
                  background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), #2D2F34',
                  boxShadow: '0px 4px 30px rgba(0, 0, 0, 0.08)',
                  borderRadius: 8,


                }}>
                  <div
                    className={classNames(
                      styles.roundButton,
                      styles.button,
                      styles.roundButtonStyling,
                      styles.enterpriseBtnStyling,
                      {
                        [styles.actionButton]: isResponsive,
                      },
                    )}
                    style={ctaButtonStyle}
                  ><Button onClick={() => {
                      if (CUSTOM_PLAN_ID == item?.id) {
                        openUrl(getRequestPricelink(), '_blank');
                        return;
                      }
                    }}
                    disabled={loading || (profile?.isWhitelabel && !profile?.isParentAccount) || (isWhitelabel && !profile?.isParentAccount)}>
                          Get a Quote
                      <FontAwesomeIcon style={{marginLeft: 7}} icon={faArrowUpRight} color={isSubscribed ? (showBrandColor ? hexToRgba(brandColor, 1) : '#AA85EC' ) : '#FFF'}/>
                    </Button>
                  </div>
                </Tooltip>

              </div>
            </div>
          );
        })}
        </div>
      )}
    </div>
  );
});

export default PricingPlan;


const StyledSpan = styled.span`
  font-weight: 300;
  line-height: 18px;
  width: 100;
  font-size: 14px;
  color: #A3A4A4;
`;

const DetailText = styled.p`
  display: flex;
  flex-direction: row;
  align-items: start;
`;


const ComingSoon = styled.p`
   background-color: #431d66;
   padding: 4px;
   border-radius: 12px;
   width: 90px;
   text-align: center;
   color: #E8E8E8;
   white-space: nowrap;
`;

const DetailTextWraper = styled.p<{textBg: any}>`
   font-size: 14px;
`;

const StyledIcon = styled(FontAwesomeIcon)`
   cursor: pointer; 
   background: #FFFFFF;
   border-radius: 100px;
`;

const MPTag = styled.div<{brandColor?:string; defaultColor?:string; showBrandColor?:boolean; responsive?:boolean}>`
  width: fit-content;
  padding: 6px 10px;
  border-radius: 6px;
  background: ${e=>e?.showBrandColor ? e?.brandColor : e?.defaultColor};
  font-size: 13px;
  font-weight: 500;
  color: #F2F1F6;
  margin-top: 15px;

  ${e=> e?.responsive ?
    `@media screen and (max-width: 754px) {
    display: none;
  }` :
    null}
  
`;


const IconImage = styled.img`
    background: 0 !important;
    height: 17px !important;
    width: 17px !important;
    padding: 0 !important;
`;


const PTag = styled.p<{responsive: any; brandColor:string; showBrandColor:boolean}>`
  color: white;
  text-align: center;
  background: ${e=>e?.showBrandColor ? e?.brandColor : '#936BDA'};
  position: absolute;
  width: fit-content;
  font-size: 11px;
  text-align: center;
  align-items: center;
  height: 20px;
  margin-top: -25px;
  display: flex;
  justify-content: center;
  padding: 12px 10px;
  font-size: 13px;
  font-weight: 500;
  color: #F2F1F6;
  display: none;
  border-radius: 2px;


  ${e=> e?.responsive ?
    `@media screen and (max-width: 754px) {
    display: flex;
  }` :
    null}
`;


const Circle = styled.div<{color:string}>`
   width: 6px;
   height: 6px;
   background: ${e=>e.color};
   border-radius: 50%;
`;
