import {useStore} from '@/store/root-store';
import {observer} from 'mobx-react';
import styled from 'styled-components';
import {useEffect, useState} from 'react';
import ReactEcharts from 'echarts-for-react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {SkeletonRingLoader} from '@/components/common-components';
import {faArrowTrendUp, faBug, faCircleCheck, faPage} from '@fortawesome/pro-solid-svg-icons';
import {faCircleInfo} from '@fortawesome/pro-regular-svg-icons';
import {faCode, faGaugeMin, faGaugeMax, faSparkles} from '@fortawesome/pro-duotone-svg-icons';
import {Popover, Spin, Switch, Tooltip, Button as AntdButton} from 'antd';
import {LoadingOutlined} from '@ant-design/icons';
import {numberWithCommas} from '@/utils/number';
import {Button} from '@/components/common-components/v2';
import {copyAndModifyQueryParams, getSingleUrlParam} from '@/utils/url';
import {useRouter} from 'next/router';
import DisengageConfirmModal from './DisengageConfirmModal';
import {newNotification} from '@/utils/notification-v3';
import FreezeWrapper from './freezTooltip';
import PublicPageFreezeWrapper from './publicPageFreeze';
import {faRotateLeft} from '@fortawesome/pro-regular-svg-icons';
interface Props {
  currentProjectLoader?: boolean;
  currentProject?: any;
  openSettings?: () => void | Promise<void>;
  loadingAutoPilot?: boolean;
  onChangeAutoPilot?: (value: any) => void | Promise<void>;
}

const OttoSummary = observer(({currentProjectLoader, currentProject, openSettings, onChangeAutoPilot, loadingAutoPilot}: Props) => {
  const {
    ottoV2Store: {getOttoV2Project, deployOttoUrls, deploying, loadOttoV2Project, setAllDeployed, categoriesLength},
    settings: {customer: {profile: {isLinkgraph, whitelabelOtto}}},
    siteAuditorV2: {
      recrawlOverviewDetail,
      getAuditedSiteDetailData,
      siteAuditSpacificData,
      setIsInOtto,
    },
  } = useStore('');
  const [seriesData, setSeriesData] = useState<any[]>([]);
  const [confirmModalVisible, setConfirmModalVisible] = useState<boolean>(false);
  const [ottoStats, setOttoStats] = useState({
    optimization: 0,
    pages: 0,
    issues: 0,
    isCompleted: false,
  });
  const [isCrawling, setIsCrawling] = useState(false);

  const uuid = getSingleUrlParam('uuid');
  const router = useRouter();
  const isPublicPage = !!getSingleUrlParam('public_hash');

  const getChartColor = (value: number, type: string) => {
    const completeColors = {
      optimization: '#2A8745',
      pages: '#2AC155',
      issues: '#70F596',
    };
    if (value <= 30 || !value) {
      return '#F44343';
    } else if (value >= 31 && value <= 80) {
      return '#F1AA3E';
    } else {
      return completeColors[type] || '#70F596';
    }
  };

  const onDisengageConfirm = async () => {
    await onChangeAutoPilot(false);
    setConfirmModalVisible(false);
  };

  const optimizationScore = getOttoV2Project && getOttoV2Project.afterSummary['seoOptimizationScore'];
  const healthyPages = getOttoV2Project && (getOttoV2Project.afterSummary['healthyPages'] / getOttoV2Project.afterSummary['totalPages']) * 100;
  const issues = getOttoV2Project && (getOttoV2Project.afterSummary['deployedFixes'] / getOttoV2Project.afterSummary['foundIssues']) * 100;

  const isCompletedV2 = optimizationScore == 100 || healthyPages == 100 || issues == 100;

  useEffect(() => {
    if (getAuditedSiteDetailData?.shouldRepoll) {
      setIsCrawling(true);
      setIsInOtto(true);
    } else if (getAuditedSiteDetailData?.shouldRepoll === false) {
      setIsCrawling(false);
      setIsInOtto(false);
    }
  }, [getAuditedSiteDetailData?.shouldRepoll]);

  useEffect(() => {
    if (getOttoV2Project?.afterSummary) {
      const optimizationScore = getOttoV2Project.afterSummary['seoOptimizationScore'];
      const healthyPages = (getOttoV2Project.afterSummary['healthyPages'] / getOttoV2Project.afterSummary['totalPages']) * 100;
      const issues = (getOttoV2Project.afterSummary['deployedFixes'] / getOttoV2Project.afterSummary['foundIssues']) * 100;
      const statsArr = [
        {value: issues, name: 'Issues', label: `Issues fixed: ${getOttoV2Project.afterSummary['deployedFixes'] || 0}`, total: getOttoV2Project.afterSummary['foundIssues'], remaining: getOttoV2Project.afterSummary['foundIssues']-getOttoV2Project.afterSummary['deployedFixes']},
        {value: healthyPages, name: 'Healthy pages', label: `Healthy pages: ${getOttoV2Project.afterSummary['healthyPages'] || 0}`, total: getOttoV2Project.afterSummary['totalPages'], remaining: getOttoV2Project.afterSummary['totalPages']-getOttoV2Project.afterSummary['healthyPages']},
        {value: optimizationScore, name: 'optimization', label: `Optimization Score: ${getOttoV2Project.afterSummary['seoOptimizationScore'] || 0}%`, total: 100, remaining: 100-optimizationScore},
      ];
      const isCompleted = statsArr.filter(stat => stat.value === 100).length === statsArr.length;
      let series: any[] = statsArr.map((stat, index) => {
        return [1, 2].map(n => (
          {
            name: stat.label,
            type: 'pie',
            radius: [25 + (20 * index), 37 + (20 * index)],
            hoverOffset: 0,
            labelLine: {
              show: false,
            },
            clockwise: false,
            silent: n === 2 ? true : false,
            z: n === 2 ? 0 : 1,
            animation: n === 2 ? false : true,
            data: n === 2 ? [
              {value: 100, name: stat.name, itemStyle: {color: '#4E5156'}, label: {show: false}, total: stat.total, remaining: stat.remaining},
            ] : [
              {value: Math.round(stat.value), name: stat.name, itemStyle: {color: getChartColor(stat.value, stat.name), borderRadius: 12}, label: {show: false}, total: stat.total, remaining: stat.remaining},
              {value: Math.round(100 - stat.value), name: stat.name, itemStyle: {color: 'transparent'}, label: {show: false}, total: stat.total, remaining: stat.remaining},
            ],
          }
        ));
      });
      series = series?.flatMap(el => el);
      setSeriesData(series);
      setOttoStats({
        optimization: optimizationScore,
        pages: healthyPages,
        issues,
        isCompleted,
      });
    }
  }, [getOttoV2Project]);

  // const getDate = (activatedAt: string) => {
  //   const seconds = new Date().getTime() - new Date(activatedAt).getTime();
  //   const days = Math.ceil(seconds / (1000 * 60 * 60 * 24));
  //   return numberWithCommas(days);
  // };
  const antIcon = <LoadingOutlined style={{fontSize: 14, color: '#fff'}} spin />;

  const feelingLucky = async (issueType: string, toDeploy: boolean) => {
    const isOk = await deployOttoUrls({toDeploy: toDeploy, uuid: uuid, issueType: issueType, feelingLuckyBanner: true});
    isOk && newNotification('All issues deployed successfully', 2, 'deploy');
    const res = await loadOttoV2Project(uuid);
    isOk && setAllDeployed(res?.isActive);
    router.push(`${copyAndModifyQueryParams([{key: 'subGroup', value: 'page_title'}])}`, undefined, {shallow: true});
  };

  useEffect(() => {
    if (getOttoV2Project) {
      setAllDeployed(getOttoV2Project?.isActive);
    }
  }, [getOttoV2Project?.isActive]);

  return (
    <Wrapper>
      <StatsWrapper>
        <div style={{minWidth: 160, height: 'fit-content', position: 'relative'}}>
          <ReactEcharts
            style={{width: '160px', height: '160px', alignSelf: 'center', transform: 'rotate(5deg)'}}
            opts={{renderer: 'svg'}}
            option={{
              title: [{
                text: '{titleIcon|}',
                top: '-2px',
                left: '40%',
                textAlign: 'center',
                textStyle: {
                  rich: {
                    titleIcon: {
                      height: 14,
                      width: 14,
                      backgroundColor: {
                        image: '/icons/arrow-trend.svg',
                      },
                      transform: 'rotate(-30deg)',
                      opacity: 0.7,
                    },
                  },
                },
              }, {
                text: '{titleIcon|}',
                top: '20px',
                left: '43%',
                textAlign: 'center',
                textStyle: {
                  rich: {
                    titleIcon: {
                      height: 10,
                      width: 10,
                      backgroundColor: {
                        image: '/icons/page-icon.svg',
                      },
                      rotate: 30,
                      opacity: 0.7,
                    },
                  },
                },
              }, {
                text: '{titleIcon|}',
                top: '40px',
                left: '44%',
                textAlign: 'center',
                textStyle: {
                  rich: {
                    titleIcon: {
                      height: 10,
                      width: 10,
                      backgroundColor: {
                        image: '/icons/bug-icon.svg',
                      },
                      transform: 'rotate(-30deg)',
                      opacity: 0.7,
                    },
                  },
                },
              }],
              tooltip: {
                trigger: 'item',
                formatter: function(params) {
                  const value = params.data.value;
                  const name = params.data.name;
                  const remaining = params.data.remaining;
                  const total = params.data.total;
                  if (params.componentIndex === 0) {
                    if (params.dataIndex === 0) {
                      return `Issues fixed: ${total-remaining}`;
                    } else {
                      return `Remaining issues: ${remaining}`;
                    }
                  } else if (params.componentIndex === 4) {
                    if (params.dataIndex === 0) {
                      return `Optimization score: ${value}%`;
                    } else {
                      return `Remaining score: ${value}%`;
                    }
                  } else {
                    if (params.dataIndex === 0) {
                      return `${name}: ${total-remaining}`;
                    } else {
                      return `Remaining pages: ${remaining}`;
                    }
                  }
                },
                // formatter: '{a}',
                confine: true,
                appendToBody: true,
              },
              series: seriesData,
            }}
          />
          <div style={{color: '#A3A4A4', marginTop: 16, fontWeight: 400, fontSize: 12, lineHeight: '14.52px', textAlign: 'center', opacity: 0}}>Before / After</div>
          {isCompletedV2 && <ImgStyled src={'/icons/white-check-mark.svg'}/>}
        </div>
        <div className='scores-wrapper'>
          <div className='score'>
            <Tooltip title='A metric that indicates how well-optimized and issue-free your website is. A higher score is better.'>
              <FontAwesomeIcon
                icon={faArrowTrendUp}
                color={getChartColor(ottoStats?.optimization, 'optimization')}
                fontSize={20}
              />
            </Tooltip>
            <div>
              <Tooltip title='A metric that indicates how well-optimized and issue-free your website is. A higher score is better.'>
                <p>Optimization Score</p>
              </Tooltip>
              <div>
                <span className='fixed-count'>{numberWithCommas(getOttoV2Project?.afterSummary['seoOptimizationScore'] || 0) ?? 'N/A'}</span>
                <span className='fixed-total'>/ 100</span>
              </div>
            </div>
          </div>
          <div className='score'>
            <Tooltip overlayInnerStyle={{minWidth: '260px'}} title={(
              <div>
                {`Pages that had no issue during the initial crawl or pages that were fixed with ${whitelabelOtto}.`}
                <div>{`Note: pages must have ALL issues fixed or they won't show as healthy.`}</div>
              </div>
            )}>
              <FontAwesomeIcon
                icon={faPage}
                color={getChartColor(ottoStats?.pages, 'pages')}
                fontSize={20}
              />
            </Tooltip>
            <div>
              <Tooltip overlayInnerStyle={{minWidth: '260px'}} title={(
                <div>
                  {`Pages that had no issue during the initial crawl or pages that were fixed with ${whitelabelOtto}.`}
                  <div>{`Note: pages must have ALL issues fixed or they won't show as healthy.`}</div>
                </div>
              )}>
                <p>Flawless pages</p>
              </Tooltip>
              <div>
                <span className='fixed-count'>{numberWithCommas(getOttoV2Project?.afterSummary['healthyPages'] || 0) ?? 'N/A'}</span>
                <span className='fixed-total'>/ {numberWithCommas(getOttoV2Project?.afterSummary['totalPages'] || 0) ?? 'N/A'}</span>
              </div>
              {!isPublicPage && <FreezeWrapper>
                <Tooltip
                  title={(
                    <div>
                      <p>A site crawl detects significant changes to your site (add or removed pages, modified pages, etc) and updates them within OTTO.</p>
                      <span>Last crawl: {siteAuditSpacificData?.lastCrawled ? siteAuditSpacificData?.lastCrawled : 'not available'}</span>
                    </div>
                  )}
                  placement='bottom'
                  overlayInnerStyle={{borderRadius: '8px', backgroundColor: '#000', width: '370px', fontSize: 12, padding: 10}}
                >
                  <div>
                    <span style={{color: '#E8E8E8', fontSize: '12px', cursor: 'default'}}>Modified your site?</span>
                    {isCrawling ? <LastCrawl style={{cursor: 'not-allowed'}}>
                      {' crawling'}
                      <RecrawlButton style={{cursor: 'not-allowed'}}>
                        <FontAwesomeIcon icon={faRotateLeft} className='fa-spin' style={{animationDirection: 'reverse'}}/>
                      </RecrawlButton>
                    </LastCrawl> : <LastCrawl style={{cursor: 'pointer'}} onClick={()=>!getAuditedSiteDetailData?.shouldRepoll && recrawlOverviewDetail(getOttoV2Project?.siteAudit)}>
                      {' Recrawl'}
                      <RecrawlButton style={{cursor: getAuditedSiteDetailData?.shouldRepoll? 'not-allowed': ''}}>
                        <FontAwesomeIcon icon={faRotateLeft} />
                      </RecrawlButton>
                    </LastCrawl>}
                  </div>
                </Tooltip>
              </FreezeWrapper>}
            </div>
          </div>
          <div className='score'>
            <Tooltip title={`Only the issues fixed ${isLinkgraph ? `with ${whitelabelOtto}` : ''} (excluding issues fixed by other means).`}>
              <FontAwesomeIcon
                icon={faBug}
                color={getChartColor(ottoStats?.issues, 'issues')}
                fontSize={20}
              />
            </Tooltip>
            <div>
              <Tooltip title={`Only the issues fixed ${isLinkgraph ? `with ${whitelabelOtto}` : ''} (excluding issues fixed by other means).`}>
                <p>{isLinkgraph ? `Issues fixed with ${whitelabelOtto}` : `Issues fixed`}</p>
              </Tooltip>
              <div>
                <span className='fixed-count'>{numberWithCommas(getOttoV2Project?.afterSummary['deployedFixes'] || 0) ?? 'N/A'}</span>
                <span className='fixed-total'>/ {numberWithCommas(getOttoV2Project?.afterSummary['foundIssues'] || 0) ?? 'N/A'}</span>
              </div>
            </div>
          </div>
        </div>
      </StatsWrapper>
      {currentProjectLoader ? (
        <EngagedWrapper className={'pixel-loader'}>
          <div className='text-wrapper'><SkeletonRingLoader height='190px'/></div>
        </EngagedWrapper>) : !currentProjectLoader && (currentProject !== null) && (currentProject?.pixelTagState === 'not_installed' || currentProject?.pixelTagState === 'wrong_uuid'|| currentProject?.pixelTagState === 'wrong_domain') ? (
        <EngagedWrapper className={'not-installed'}>
          <FontAwesomeIcon icon={faCode} style={{width: 'fit-content'}} fontSize='26px' color='#fff'/>
          <div className='text-wrapper'>
            <div className='heading'>{(currentProject?.pixelTagState == 'not_installed' || currentProject?.pixelTagState == 'wrong_domain') ? `${whitelabelOtto} Pixel is not installed correctly.` : `${whitelabelOtto} Pixel has the wrong UUID`}</div>
            <div className='description'>{currentProject?.pixelTagState == 'not_installed' ? 'You can’t deploy fixes unless the Pixel is installed correctly.' : `To fix this issue, Copy & Paste the ${whitelabelOtto} Pixel to your website again.`}</div>
            <PublicPageFreezeWrapper><div className='open-settings-btn' onClick={openSettings}><FontAwesomeIcon icon={faCircleInfo} fontSize='16px' color='#121212'/><span>Open Instructions</span></div></PublicPageFreezeWrapper>
          </div>
        </EngagedWrapper>) : (currentProject?.pixelTagState === 'installed' || currentProject?.pixelTagState === 'wp_plugin') ? (
        <EngagedContainer>
          <EngagedWrapper className={!getOttoV2Project?.isEngaged && 'not-engaged'}>
            <FontAwesomeIcon
              icon={getOttoV2Project?.isEngaged ? faGaugeMax : faGaugeMin}
              style={{width: 'fit-content'}}
              fontSize='20px'
              color={getOttoV2Project?.isEngaged ? '#2AC155' : '#F1AA3E'}
            />
            <div>
              <div className='text-wrapper'>
                <div className='heading'>{`${whitelabelOtto} SEO is ${getOttoV2Project?.isEngaged ? 'Engaged' : 'Not Engaged'}`}{loadingAutoPilot ? <Spin style={{lineHeight: 'normal', marginTop: '-4px'}} indicator={antIcon}/> : null}</div>
                <div className='description'>
                  {getOttoV2Project?.isEngaged ? `All deployed changes are optimizing your website to boost rankings and improve site visibility.` :
                    `Turn on ${whitelabelOtto} SEO to deploy all enabled fixes and optimizations to your website.`}</div>
              </div>
              <PublicPageFreezeWrapper>
                <button disabled={loadingAutoPilot || isPublicPage} className='switch-wrapper' onClick={() => getOttoV2Project?.isEngaged ? setConfirmModalVisible(true) : onChangeAutoPilot(!getOttoV2Project?.isEngaged)}>
                  <Switch className='switch-button' checked={getOttoV2Project?.isEngaged} onChange={value => value && onChangeAutoPilot(value)} loading={loadingAutoPilot} disabled={loadingAutoPilot || isPublicPage}/>
                  {getOttoV2Project?.isEngaged ? 'Enabled' : 'Disabled'}
                </button>
              </PublicPageFreezeWrapper>
            </div>
          </EngagedWrapper>
          {getOttoV2Project?.isEngaged && <FeelingLucky background={'rgba(127, 78, 173, 0.15)'}>
            <FontAwesomeIcon icon={faSparkles} color={'#7F4EAD'} fontSize='20px'/>
            <div style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
              <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <TextWrapper style={{marginLeft: '-15px'}}>{'I’m feeling lucky!'}</TextWrapper>
                <SubtitleTxt>Fix all issues in 1 click</SubtitleTxt>
              </div>
              <FreezeWrapper>
                <ButtonStyled disabled={deploying} onClick={() => feelingLucky('deploy-all', true)}>
                  {deploying ? <Spin spinning indicator={<LoadingOutlined style={{color: '#fff', fontSize: 15}} color='#fff' />}/> : <div style={{fontWeight: 500}}>{deploying ? '' : 'Deploy all'}</div>}
                </ButtonStyled>
              </FreezeWrapper>
            </div>
          </FeelingLucky>}
          <TaskSection>
            <div className='text'>Tasks to do {categoriesLength ? `${getOttoV2Project?.myTasksOrdering?.length} / ${categoriesLength}` : <Spin indicator={antIcon}/>}</div>
            <Tooltip title={!getOttoV2Project?.myTasksOrdering?.length ? 'You have 0 Task to do' : ''}>
              <span>
                <AntdButton disabled={!getOttoV2Project?.myTasksOrdering?.length} className='view-task' onClick={() => {
                  router.push(`/task-v2?uuid=${getOttoV2Project?.uuid}`);
                }}>
                  <FontAwesomeIcon icon={faCircleCheck} color='#FFFFFF' />
                  View
                </AntdButton>
              </span>
            </Tooltip>
          </TaskSection>
        </EngagedContainer>
      ) : <></>}
      <DisengageConfirmModal
        loading={loadingAutoPilot}
        onConfirm={onDisengageConfirm}
        visible={confirmModalVisible}
        setVisible={setConfirmModalVisible}
      />
    </Wrapper>
  );
});

export default OttoSummary;

const Wrapper = styled.div`
  background-color: rgba(163, 164, 164, 0.1);
  border-radius: 14px;
  height: 100%;
  padding: 10px 14px;
  display: flex;
  justify-content: space-between;
  gap: 6px;
  row-gap: 12px;
  @media screen and (max-width: 1400px) {
    flex-direction: column;
  }
`;

const EngagedWrapper = styled.div`
  background-color: rgba(42, 193, 85, 0.15);
  padding: 20px 19px;
  border-radius: 12px;
  display: flex;
  gap: 11px;
  width: 275px;
  height: fit-content;
  @media screen and (max-width: 1400px) {
    width: 100%;
  }
  @media screen and (max-width: 1200px) {
    margin-bottom: 8px;
  }
  &.not-engaged {
    background: rgba(241, 170, 62, 0.15) !important;
    .switch-wrapper {
      background-color: #CA923B !important;
      .switch-button {
        background: #fff !important;
      }
      .ant-switch-handle {
        &::before {
          background-color: #F1AA3E !important;
        }
      }
    }
  }
  &.not-installed {
    background: #DD0A3D !important;
  }
  &.pixel-loader {
    background: #282b2f !important;
    display: flex;
    align-items: center;
    justify-content: center;

    .text-wrapper > div > div {
      div {
        border-color: #2D6CCA transparent transparent !important;
      }
    }
  }
  .text-wrapper {
    max-width: 374px;
    .heading {
      color: #FFFFFF;
      font-size: 16px;
      line-height: 19.36px;
      font-weight: 600;
      font-family: 'Inter', sans-serif;
      margin-bottom: 8px;
    }
    .description {
      max-width: 356px;
      font-family: 'Inter', sans-serif;
      font-size: 12px;
      font-weight: 400;
      color: #E8E8E8;
      line-height: 14.52px;
      margin-bottom: 15px;
    }
    .open-settings-btn {
      cursor: pointer;
      margin-top: 16px;
      display: flex;
      align-items: center;
      gap: 6px;
      padding: 8px 12px;
      box-shadow: 0px 3px 2px 0px #00000005;
      background: #FFFFFF;
      border-radius: 8px;
      max-width: 172px;
      height: 40px;

      span {
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        font-weight: 500;
        color: #121212;
      }
    }
  }
  .switch-wrapper {
    background-color: #219843;
    border-radius: 8px;
    height: 40px;
    width: fit-content !important;
    padding: 9px 15px;
    display: flex;
    align-items: center;
    gap: 6px;
    cursor: pointer;
    outline: none;
    border: none;
    &:disabled {
      cursor: not-allowed;
      opacity: 80%;
    }
    .switch-button {
      background-color: #fff;
    }
    .ant-switch-handle {
      width: 16px;
      height: 16px;
      top: 3px;
      &::before {
        background-color: #2AC155;
      }
    }
  }
`;

const StatsWrapper = styled.div`
  height: 100%;
  display: flex;
  gap: 20px;
  align-items: center;
  @media screen and (max-width: 1400px) {
    justify-content: center;
  }
  p {
    margin-top: 3px !important;
    margin-bottom: 0 !important;
  }
  .scores-wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    color: #fff;
    svg {
      min-width: 20px;
    }
    .score {
      display: flex;
      gap: 10px;
      font-size: 14px;
      line-height: 16.94px;
      font-weight: 500;
      .fixed-count {
        font-size: 24px;
        font-weight: 600;
        line-height: 29.05px;
        margin-right: 5px;
      }
      .fixed-total {
        color: #A3A4A4;
        font-size: 12px;
        font-weight: 400;
        line-height: 14.52px;
      }
    }
  }
`;

const ImgStyled = styled.img`
  position: absolute;
  top: 39%;
  left: 44%;
`;

const FeelingLucky = styled.div<{background: string}>`
  width: 275px;
  height: 48px;
  top: 7020px;
  left: 22304px;
  gap: 0px;
  border-radius: 12px;
  background: ${p => p?.background};
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 14px;
  @media screen and (max-width: 1400px) {
    width: 100%;
  }
  @media screen and (max-width: 1200px) {
    margin-bottom: 8px;
  }
`;

const EngagedContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const TextWrapper = styled.div`
  font-weight: 500;
  line-height: 16.94px;
`;

const SubtitleTxt = styled.div`
  font-size: 12px;
  line-height: 14.52px;
  color: #E8E8E8;
`;

const ButtonStyled = styled(Button)`
  width: 86px !important;
  height: 29px !important;
  padding: 6px 10px 6px 10px !important;
  gap: 8px !important;
  border-radius: 8px !important;
  background: #7F4EAD !important;
  box-shadow: 0px 3px 2px 0px #00000005 !important;
  margin: 0px !important;
  display: flex;
  justify-content: center;

  .ant-btn-loading-icon {
    display: none;
  }
`;

export const PopoverWrapper = styled(Popover)`
.customPopover {
z-index: 3 !important;

  .ant-popover-content {
    .ant-popover-arrow {
      border-color: #25242b;
    }

    .ant-popover-inner {
      .ant-popover-inner-content {
        background-color: #fff;
        border-radius: 10px;
        color: #77757d;
        padding: 8px 15px;

        p {
          cursor: pointer;
          font-size: 14px;
          margin-bottom: 0;
          padding: 4px 0;
        }

        span {
          color: #77757d;
          // margin-right: 4px;
        }
      }
    }
  }
}
`;
const LastCrawl = styled.span`
    font-size: 12px;
    margin: 0;
    color:  #34AEF3;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
`;
const RecrawlButton = styled.span`
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.52px;
  color:  #34AEF3;
  cursor: pointer;
`;

const TaskSection = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 12px;
  background-color: rgba(163, 164, 164, 0.1);
  .text {
    margin-left: 17px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.94px;
    text-align: left;
    color: #FFFFFF;
  }
  .view-task {
    margin-right: 9px;
    width: 85px;
    height: 32px;
    border-radius: 6px;
    background: #2AC155;
    border: 0px;
    color: #FFFFFF;
    &:hover, &:focus {
      background: #2AC155;
      border: 0px;
      color: #FFFFFF;
    }
    svg {
      margin-right: 6px;
    }
  }
`;
