import {observer} from 'mobx-react';
import {WildfireWrapper} from './styles';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowDown, faArrowUp, faAngleUp, faSearch, faAngleDown, faSpinnerThird, faCircleExclamation} from '@fortawesome/pro-regular-svg-icons';
import {useEffect, useState} from 'react';
import {Input, Table, Progress, Tooltip} from 'antd';
import {faCheckCircle} from '@fortawesome/pro-solid-svg-icons';
import {faCheckCircle as progressFull} from '@fortawesome/pro-duotone-svg-icons';
import {useStore} from '@/store/root-store';
import {OTTO_V2_API} from '@/api/otto-v2';
import {notification} from '@/utils/notification-v2';
import moment from 'moment';
import {apiError} from '@/utils/api';
import Image from 'next/image';

export const WILDFIREComponent = observer(() => {
  const [inlinksData, setInlinksData] = useState([]);
  const [outlinksData, setOutlinksData] = useState([]);

  const {ottoV2Store: {getOttoV2Project},
    settings: {customer: {profile: {whitelabelOtto}}},
  } = useStore('');
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState({
    inlinks: '',
    outlinks: '',
  });
  const [showSearchInput, setShowSearchInput] = useState({
    inlinks: false,
    outlinks: false,
  });
  const [deployingIds, setDeployingIds] = useState([]);
  const [addBacklink, setAddBacklink] = useState(true);
  const [allowOutlinkRemove, setAllowOutlinkRemove] = useState(false);

  const filteredData = {
    inlinks: searchTerm?.inlinks ?
      inlinksData?.filter(item => item.isApproved ?
        (item?.renderData?.hostname?.includes(searchTerm?.inlinks) || item.linkOptions?.some(option => option.donorUrl?.includes(searchTerm?.inlinks))) :
        item?.renderData?.hostname?.includes(searchTerm?.inlinks)) :
      inlinksData,
    outlinks: searchTerm?.outlinks ?
      outlinksData?.filter(item => item.isApproved ?
        (item?.renderData?.hostname?.includes(searchTerm?.outlinks) || item.linkOptions?.some(option => option.receiverUrl?.includes(searchTerm?.outlinks))) :
        item?.renderData?.hostname?.includes(searchTerm?.outlinks)) :
      outlinksData,
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await Promise.all(tables?.map(item => ( OTTO_V2_API.getWILDFIREData(getOttoV2Project?.uuid, item.type))));
      if (response?.length) {
        tables.forEach((item, index) => {
          if (item.type == 'inlinks') {
            if (!response[index]?.isCancel) {
              setInlinksData(response?.[index]?.results?.map(item => ({...item, renderData: item?.donor, opposite: item?.receiver})));
              setAddBacklink(response?.[index]?.quota?.canAddInlink);
            }
          } else {
            if (!response?.[index]?.isCancel) {
              setOutlinksData(response?.[index]?.results?.map(item => ({...item, renderData: item?.receiver, opposite: item?.donor})));
              setAllowOutlinkRemove(response?.[index]?.quota?.canRemoveOutlink);
            }
          }
        });
      }
    } catch (e) {
      notification.error('Error', 'Failed to fetch data');
    } finally {
      setLoading(false);
    }
  };

  const [tables, setTables] = useState([
    {
      type: 'outlinks',
      isExpanded: true,
    },
    {
      type: 'inlinks',
      isExpanded: true,
    },
  ]);

  const deployLink = async (obj, type) => {
    try {
      setDeployingIds(prev => [...prev, obj?.backlinkId]);
      setDeployingIds(prev => [...prev, obj?.backlinkId]);
      await setTables(prev => prev?.map((item, index) => !index ? {...item, isLoading: true} : item));
      await OTTO_V2_API.toggleCommunityBacklinks(getOttoV2Project?.uuid, {backlink_id: obj?.backlinkId, link_type: type});
      await getData();
      notification.success('Success', 'Status Updated Successfully');
    } catch (e) {
      const errorMessage = apiError(e);
      notification.error('Error', errorMessage);
    } finally {
      await setTables(prev => prev?.map((item, index) => !index ? {...item, isLoading: false} : item));
      setDeployingIds(prev => prev?.filter(item => item != obj?.backlinkId));
      setDeployingIds(prev => prev?.filter(item => item != obj?.backlinkId));
    }
  };

  const columns = type => ([
    {
      title: 'STATUS',
      dataIndex: 'isApproved',
      key: 'isApproved',
      align: 'left' as 'left',
      sorter: (a, b) => a.isApproved - b.isApproved,
      width: 'max-content',
      render: (item, obj) => (
        <div style={{cursor: deployingIds?.includes(obj?.backlinkId) || (getOttoV2Project?.pixelTagState === 'not_installed' || getOttoV2Project?.pixelTagState === 'wrong_uuid') ? 'not-allowed' : 'pointer'}}>
          <div onClick={() => (!deployingIds?.includes(obj?.backlinkId) && !((getOttoV2Project?.pixelTagState === 'not_installed' || getOttoV2Project?.pixelTagState === 'wrong_uuid')) && deployLink(obj, type))} style={{color: item ? '#219843' :'#A3A4A4', pointerEvents: deployingIds?.includes(obj?.backlinkId) ? 'none' : 'auto'}}>
            {deployingIds?.includes(obj?.backlinkId) ? <FontAwesomeIcon icon={faSpinnerThird} color={'#2AC155'} spin/> :
              <>
                <Tooltip title={(getOttoV2Project?.pixelTagState === 'not_installed' || getOttoV2Project?.pixelTagState === 'wrong_uuid') ? `${whitelabelOtto} Pixel not integrated properly` : (type == 'outlinks' && item && !allowOutlinkRemove) ? 'Remove one backlink in order to remove this outlink' : ''}>
                  <FontAwesomeIcon icon={faCheckCircle} style={{marginRight: 5}} color={item ? '#2AC155' : '#A3A4A4'}/>
                  {item ? 'Approved' : 'Approve'}
                </Tooltip>
              </>
            }
          </div>
        </div>
      ),
    },
    {
      title: type == 'inlinks' ? 'REFERRING PAGE' : 'TARGET PAGE',
      dataIndex: 'linkOptions',
      key: 'hostname',
      align: 'left' as 'left',
      sorter: (a, b) => a.renderData.hostname?.localeCompare(b.renderData.hostname),
      width: 300,
      render: (item, task) => {
        const url = item?.find(val => val.isApproved)?.[(type == 'inlinks' ? 'donorUrl' : 'receiverUrl')] ?? task?.renderData?.hostname;
        return (
          <div className='page-cell' onClick={() => window.open(url?.includes('http') ? url : 'https://' + url, '_blank')}> {url}</div>);
      },
    },
    {
      title: 'REFERRING PAGE',
      dataIndex: 'linkOptions',
      key: 'referringPage',
      align: 'left' as 'left',
      width: 300,
      hidden: type == 'inlinks',
      render: item => {
        const url = item?.find(val => val.isApproved)?.donorUrl ?? '-';
        return (
          <div className='page-cell' onClick={() => window.open(url?.includes('http') ? url : 'https://' + url, '_blank')}> {url}</div>);
      },
    },
    {
      title: 'DOMAIN POWER',
      key: 'domainPower',
      align: 'right' as 'right',
      width: 150,
      sorter: (a, b) => a.renderData.dp - b.renderData.dp,
      render: item => {
        const difference = item?.renderData?.dp - item?.opposite?.dp;
        return <div>{item?.renderData?.dp ?
          (difference == 0 ? item?.renderData?.dp + ' (same as you)': `${item?.renderData?.dp} (${(difference > 0 ? '+' : '') + difference} vs you)`) : '-'}</div>;
      },
    },
    {
      title: 'ORGANIC TRAFFIC',
      dataIndex: ['renderData', 'ot'],
      key: 'ot',
      align: 'center' as 'center',
      width: 150,
      sorter: (a, b) => a.renderData.ot - b.renderData.ot,
      render: item => <div>{item ?? '-'}</div>,
    },
    {
      title: 'TOPICALITY',
      dataIndex: 'projectScore',
      key: 'topicality',
      align: 'right' as 'right',
      width: 150,
      sorter: (a, b) => a.projectScore - b.projectScore,
      render: item => <div>{item + '%'}</div>,
    },
    {
      title: 'ADDED ON',
      dataIndex: 'isApprovedAt',
      key: 'isApprovedAt',
      align: 'right' as 'right',
      width: `${type == 'inlinks' ? 'max-content' : 140}`,
      sort: true,
      sorter: (a, b) => moment(b.isApprovedAt ?? 0).unix() - moment(a.isApprovedAt ?? 0).unix(),
      render: item => <div>{item ? moment(item).format('MMM DD, YYYY') : '-'}</div>,
    },
  ]?.filter(item => !item?.hidden));

  const getIcon = () => {
    const approvedCount = inlinksData?.filter(item => item.isApproved)?.length;
    if (approvedCount == inlinksData?.length && inlinksData?.length !== 0) {
      return <FontAwesomeIcon icon={progressFull} color='#2AC155' />;
    } else {
      return <Tooltip title={inlinksData?.length ? `Approved Backlinks: ${approvedCount}/${inlinksData?.length}` : ''}><Progress
        type='circle'
        trailColor='rgb(229 229 229)'
        percent={(approvedCount/inlinksData?.length)*100}
        strokeColor='#2D6CCA'
        width={16}
        strokeWidth={14}
        gapDegree={0}
        size='small'
        showInfo={false}
      /></Tooltip>;
    }
  };
  const [pagination, setPagination] = useState({
    inlinks: {pageSize: 10, current: 1},
    outlinks: {pageSize: 10, current: 1},
  });

  const handleTableChange = (paginationConfig, type) => {
    setPagination(prev => ({
      ...prev,
      [type]: {
        pageSize: paginationConfig.pageSize,
        current: paginationConfig.current,
      },
    }));
  };
  return (
    <WildfireWrapper loading={loading}>
      { loading ? <FontAwesomeIcon spin icon={faSpinnerThird} color={'#2D6CCA'} fontSize={100} className='loader'/> :
        tables?.map((item, tableIndex) => (
          <div key={item.type} className='section' style={{paddingBottom: !item?.isExpanded && 0}}>
            <div style={{display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap'}} >
              <div className='table-header-wrapper'>
                <FontAwesomeIcon icon={item.type == 'outlinks' ? faArrowUp : faArrowDown} />
                <div className='table-heading'>{item.type == 'inlinks' ? 'Backlinks' : 'Outlinks'} {item.type == 'inlinks' ? 'to' : 'from'} <span onClick={() => window.open(getOttoV2Project?.hostname?.includes('http') ? getOttoV2Project?.hostname : 'https://' + getOttoV2Project?.hostname, '_blank')}>{getOttoV2Project?.hostname}</span></div>
                <FontAwesomeIcon icon={faSearch} className='search' onClick={() => {
                  setShowSearchInput(prev => ({...prev, [item.type]: !prev[item.type]}));
                  searchTerm?.[item.type] && setSearchTerm(prev => ({...prev, [item.type]: ''}));
                }}/>
                {showSearchInput?.[item.type] && <Input className='search-input' value={searchTerm[item.type]} onChange={e => setSearchTerm(prevStat => ({...prevStat, [item.type]: e.target.value}))}
                  placeholder={`${item.type == 'inlinks' ? 'Referring page' : 'Target page'}`}/>}
                {item.type == 'inlinks' && getIcon()}
                <FontAwesomeIcon className='icon' icon={item.isExpanded ? faAngleUp : faAngleDown} onClick={() => setTables(prevItems => prevItems.map((item, index) => index === tableIndex ? {...item, isExpanded: !item.isExpanded} : item))}/>
                {item.type == 'inlinks' && <div className='add-more'>
                  {addBacklink && <div><Image src={'/icons/check.png'} width={14} height={14} alt='Check Icon'/> You can add more backlinks</div>}
                  {!addBacklink && <div className='not-available'>
                    <FontAwesomeIcon icon={faCircleExclamation}/>
                    <div className='not-available-text'>To receive more backlinks, you need to add at least 2 outlinks first </div>
                    <div className='ratio'>2:1 ratio required</div>
                  </div>}
                </div>}
              </div>
            </div>
            {item.isExpanded && <Table
              dataSource={filteredData[item.type]}
              columns={columns(item.type)}
              scroll={{x: 'max-content'}}
              pagination={{
                ...pagination[item.type],
                total: filteredData[item.type]?.length,
                showSizeChanger: true,
                showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} items`,
              }}
              onChange={paginationConfig => handleTableChange(paginationConfig, item.type)}
            />}
          </div>
        ))
      }
    </WildfireWrapper>
  );
});


