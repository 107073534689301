import {cast, flow, getParent, Instance, types} from 'mobx-state-tree';
import {toJS} from 'mobx';
import {COMPETITOR_RESEARCHER_API_POLL_INTERVAL} from '@/constants';
import {notification} from '@/utils/notification-v2';
import {getSingleUrlParam} from '@/utils/url';
import {COMPETITOR_RESEARCHER_API} from '@/api/competitor-researcher-v2';
import Router from 'next/router';
import {ADS_COPY_FILTER, ADS_HISTORY_FILTER, ANCHORS_FILTER, BACKLINKS_FILTER, DEFAULT_PARAMS, DEFAULT_PARAMS_POSITION, ORGANIC_KEYWORD_FILTER, ORGANIC_PAGES_FILTER, ORGANIC_POSITIONS_FILTER, PAID_KEYWORD_FILTER, PAID_PAGES_FILTER, PAID_POSITIONS_FILTER, REF_DOMAINS_FILTER, OUT_DOMAINS_FILTER} from './data';
import {downloadExportFile} from '@/utils/api';
import {getApiUrl} from '@/api/common-utils';
import {BaseApi} from '@/api/base-api';

const organicCompetitorsModel = types.model({
  commonKeywords: types.maybeNull(types.number),
  domain: types.maybeNull(types.string),
  keywordsCount: types.maybeNull(types.number),
  traffic: types.maybeNull(types.number),
});
const allCategories = types.model({
  category: types.maybeNull(types.string),
  categoryId: types.maybeNull(types.number),
  keywordsCount: types.maybeNull(types.number),
  traffic: types.maybeNull(types.number),
  impressionsTraffic: types.maybeNull(types.number),
});
const resultsModel = types.model({
  changeOfTraffic: types.maybeNull(types.number),
  clickPotential: types.maybeNull(types.number),
  cmp: types.maybeNull(types.number),
  cpc: types.maybeNull(types.number),
  keyword: types.maybeNull(types.string),
  keywordDifficulty: types.maybeNull(types.number),
  position: types.maybeNull(types.number),
  results: types.maybeNull(types.number),
  positionDifference: types.maybeNull(types.number),
  positionIsSerpFeature: types.maybeNull(types.boolean),
  positionSerpFeatures: types.maybeNull(types.array(types.string)),
  previousPosition: types.maybeNull(types.number),
  rankingUrl: types.maybeNull(types.string),
  searchVolume: types.maybeNull(types.number),
  clickPontentialPct: types.maybeNull(types.number),
  traffic: types.maybeNull(types.number),
  trafficCost: types.maybeNull(types.number),
  trafficCostPct: types.maybeNull(types.number),
  trafficPct: types.maybeNull(types.number),
  serpFeatures: types.maybeNull(types.array(types.string)),
  intents: types.maybeNull(types.array(types.string)),
  trends: types.maybeNull(types.array(types.number)),
  adwordsBlock: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
});
const pagesResultsModel = types.model({
  commercialKeywordTraffic: types.maybeNull(types.number),
  informationalKeywordCount: types.maybeNull(types.number),
  informationalKeywordTraffic: types.maybeNull(types.number),
  navigationalKeywordCount: types.maybeNull(types.number),
  navigationalKeywordTraffic: types.maybeNull(types.number),
  organicKeywordCount: types.maybeNull(types.number),
  paidKeywordCount: types.maybeNull(types.number),
  transactionalKeywordCount: types.maybeNull(types.number),
  transactionalKeywordTraffic: types.maybeNull(types.number),
  unknownKeywordCount: types.maybeNull(types.number),
  unknownKeywordTraffic: types.maybeNull(types.number),
  url: types.maybeNull(types.string),
  commercialKeywordCount: types.maybeNull(types.number),
  traffic: types.maybeNull(types.number),
  trafficPct: types.maybeNull(types.number),
});
const chartDataTrendModel = types.model({
  commercialKeywordsCount: types.maybeNull(types.number),
  commercialKeywordsTraffic: types.maybeNull(types.number),
  commercialKeywordsTrafficCost: types.maybeNull(types.number),
  date: types.maybeNull(types.string),
  informationalKeywordsCount: types.maybeNull(types.number),
  informationalKeywordsTraffic: types.maybeNull(types.number),
  informationalKeywordsTraffic_cost: types.maybeNull(types.number),
  navigationalKeywordsCount: types.maybeNull(types.number),
  navigationalKeywordsKraffic: types.maybeNull(types.number),
  navigationalKeywordsTrafficCost: types.maybeNull(types.number),
  organicKeywords: types.maybeNull(types.number),
  organicKeywords4To10: types.maybeNull(types.number),
  organicKeywords11To20: types.maybeNull(types.number),
  organicKeywords21To30: types.maybeNull(types.number),
  organicKeywords31To40: types.maybeNull(types.number),
  organicKeywords41To50: types.maybeNull(types.number),
  organicKeywords51To100: types.maybeNull(types.number),
  organicKeywordsTop3: types.maybeNull(types.number),
  organicTraffic: types.maybeNull(types.number),
  organicTrafficBranded: types.maybeNull(types.number),
  organicTrafficCost: types.maybeNull(types.number),
  organicTrafficNonBranded: types.maybeNull(types.number),
  paidKeywordsTop3: types.maybeNull(types.number),
  paidKeywords4To10: types.maybeNull(types.number),
  paidKeywords11To20: types.maybeNull(types.number),
  paidKeywords21To30: types.maybeNull(types.number),
  paidKeywords31To40: types.maybeNull(types.number),
  paidKeywords41To50: types.maybeNull(types.number),
  paidKeywords51To100: types.maybeNull(types.number),
  paidKeywords1toT3: types.maybeNull(types.number),
  paidTraffic: types.maybeNull(types.number),
  paidTrafficCost: types.maybeNull(types.number),
  transactionalKeywordsCount: types.maybeNull(types.number),
  transactionalKeywordsTraffic: types.maybeNull(types.number),
  transactionalKeywordsTrafficCost: types.maybeNull(types.number),
});
const trendModel = types.model({
  date: types.maybeNull(types.string),
  declined: types.maybeNull(types.number),
  improved: types.maybeNull(types.number),
  lost: types.maybeNull(types.number),
  new: types.maybeNull(types.number),
});
const competitorResultsModel = types.model({
  competitor: types.maybeNull(types.string),
  commonKeywords: types.maybeNull(types.number),
  competitionLevel: types.maybeNull(types.number),
  competitorTraffic: types.maybeNull(types.number),
  competitorKeywords: types.maybeNull(types.number),
  trafficCost: types.maybeNull(types.number),
  circleSize: types.maybeNull(types.number),
  circleColor: types.maybeNull(types.string),
});

const dataModel = types.model({
  newPositionChanges: types.maybeNull(types.number),
  lostPositionChanges: types.maybeNull(types.number),
  improvedPositionChanges: types.maybeNull(types.number),
  declinedPositionChanges: types.maybeNull(types.number),
  allPositionChanges: types.maybeNull(types.number),
  commercialKeywordsCount: types.maybeNull(types.number),
  commercialKeywordsTraffic: types.maybeNull(types.number),
  commercialKeywordsTrafficCost: types.maybeNull(types.number),
  date: types.maybeNull(types.string),
  informationalKeywordsCount: types.maybeNull(types.number),
  informationalKeywordsTraffic: types.maybeNull(types.number),
  informationalKeywordsTraffic_cost: types.maybeNull(types.number),
  navigationalKeywordsCount: types.maybeNull(types.number),
  navigationalKeywordsKraffic: types.maybeNull(types.number),
  navigationalKeywordsTrafficCost: types.maybeNull(types.number),
  organicKeywords: types.maybeNull(types.number),
  organicKeywords4To10: types.maybeNull(types.number),
  organicKeywords11To20: types.maybeNull(types.number),
  organicKeywords21To30: types.maybeNull(types.number),
  organicKeywords31To40: types.maybeNull(types.number),
  organicKeywords41To50: types.maybeNull(types.number),
  organicKeywords51To100: types.maybeNull(types.number),
  organicKeywordsTop3: types.maybeNull(types.number),
  organicTraffic: types.maybeNull(types.number),
  organicTrafficBranded: types.maybeNull(types.number),
  organicTrafficCost: types.maybeNull(types.number),
  organicTrafficNonBranded: types.maybeNull(types.number),
  paidKeywordsTop3: types.maybeNull(types.number),
  paidKeywords4To10: types.maybeNull(types.number),
  paidKeywords11To20: types.maybeNull(types.number),
  paidKeywords21To30: types.maybeNull(types.number),
  paidKeywords31To40: types.maybeNull(types.number),
  paidKeywords41To50: types.maybeNull(types.number),
  paidKeywords51To100: types.maybeNull(types.number),
  paidKeywords1toT3: types.maybeNull(types.number),
  paidTraffic: types.maybeNull(types.number),
  paidTrafficCost: types.maybeNull(types.number),
  transactionalKeywordsCount: types.maybeNull(types.number),
  transactionalKeywordsTraffic: types.maybeNull(types.number),
  transactionalKeywordsTrafficCost: types.maybeNull(types.number),
  trend: types.maybeNull(types.array(chartDataTrendModel)),
  organicCompetitors: types.maybeNull(types.array(competitorResultsModel)),
  organicPositionChanges: types.maybeNull(types.model({
    dailyTrend: types.maybeNull(types.array(trendModel)),
    monthlyTrend: types.maybeNull(types.array(trendModel)),
  })),
});

const paidTrafficDataModel = types.model({
  paidTrafficBranded: types.maybeNull(types.number),
  paidKeywordsTop3: types.maybeNull(types.number),
  paidKeywords11To20: types.maybeNull(types.number),
  paidKeywords21To30: types.maybeNull(types.number),
  paidKeywords31To40: types.maybeNull(types.number),
  paidKeywords41To50: types.maybeNull(types.number),
  paidKeywords51To100: types.maybeNull(types.number),
  paidTraffic: types.maybeNull(types.number),
  paidTrafficCost: types.maybeNull(types.number),
  paidKeywords: types.maybeNull(types.number),
  trend: types.maybeNull(types.array(chartDataTrendModel)),
  adwordsCompetitors: types.maybeNull(types.array(competitorResultsModel)),
  adwordsPositionChanges: types.maybeNull(types.model({
    dailyTrend: types.maybeNull(types.array(trendModel)),
    monthlyTrend: types.maybeNull(types.array(trendModel)),
  })),
});

const rankingKeywordsModel = types.model({
  results: types.maybeNull(types.array(resultsModel)),
  totalCount: types.maybeNull(types.number),
});
const anchorsResultsModel =types.model({
  anchor: types.maybeNull(types.string),
  backlinksNum: types.maybeNull(types.number),
  domainsNum: types.maybeNull(types.number),
  firstSeen: types.maybeNull(types.string),
  lastSeen: types.maybeNull(types.string),
  urlanchor: types.maybeNull(types.boolean),
});
const refDomainResultsModel =types.model({
  backlinksNum: types.maybeNull(types.number),
  domainsNum: types.maybeNull(types.number),
  ip: types.maybeNull(types.string),
  country: types.maybeNull(types.string),
  domain: types.maybeNull(types.string),
  domainAscore: types.maybeNull(types.number),
  firstSeen: types.maybeNull(types.string),
  isFollow: types.maybeNull(types.boolean),
  lastSeen: types.maybeNull(types.string),
  new: types.maybeNull(types.boolean),
  lost: types.maybeNull(types.boolean),
  categories: types.maybeNull(types.frozen([])),
});
const outDomainResultsModel =types.model({
  categories: types.maybeNull(types.frozen([])),
  domain: types.maybeNull(types.string),
  domainAscore: types.maybeNull(types.number),
  firstSeen: types.maybeNull(types.string),
  lastSeen: types.maybeNull(types.string),
  linksNum: types.maybeNull(types.number),
  redirectsNum: types.maybeNull(types.number),
  isRedirect: types.maybeNull(types.boolean),
  new: types.maybeNull(types.boolean),
  lost: types.maybeNull(types.boolean),
});
const outLinkResultsModel = types.model({
  anchor: types.maybeNull(types.string),
  canonical: types.maybeNull(types.boolean),
  canonicalResolved: types.maybeNull(types.boolean),
  domainAscore: types.maybeNull(types.number),
  externalLinkNum: types.maybeNull(types.number),
  firstSeen: types.maybeNull(types.string),
  form: types.maybeNull(types.boolean),
  frame: types.maybeNull(types.boolean),
  image: types.maybeNull(types.boolean),
  imageAlt: types.maybeNull(types.string),
  imageUrl: types.maybeNull(types.string),
  lostlink: types.maybeNull(types.boolean),
  mobile: types.maybeNull(types.boolean),
  newlink: types.maybeNull(types.boolean),
  nofollow: types.maybeNull(types.boolean),
  internalLinkNum: types.maybeNull(types.number),
  lastSeen: types.maybeNull(types.string),
  ip: types.maybeNull(types.string),
  lang: types.maybeNull(types.string),
  pageAscore: types.maybeNull(types.number),
  // platform: types.maybeNull(types.string),
  redirectUrl: types.maybeNull(types.string),
  position: types.maybeNull(types.string),
  redirect: types.maybeNull(types.boolean),
  redirectCode: types.maybeNull(types.number),
  responseCode: types.maybeNull(types.number),
  sitewide: types.maybeNull(types.boolean),
  sourceSize: types.maybeNull(types.number),
  sourceTitle: types.maybeNull(types.string),
  sourceUrl: types.maybeNull(types.string),
  targetTitle: types.maybeNull(types.string),
  targetUrl: types.maybeNull(types.string),
  sponsored: types.maybeNull(types.boolean),
  text: types.maybeNull(types.boolean),
  ugc: types.maybeNull(types.boolean),
  urlanchor: types.maybeNull(types.boolean),
});
const backlinkDataModel = types.model({
  ascore: types.maybeNull(types.number),
  date: types.maybeNull(types.string),
  lostLinks: types.maybeNull(types.number),
  lostRefDomains: types.maybeNull(types.number),
  newLinks: types.maybeNull(types.number),
  newRefDomains: types.maybeNull(types.number),
  totalLinks: types.maybeNull(types.number),
  totalRefdomains: types.maybeNull(types.number),
});
const backlinksChartDataModel = types.model({
  authorityScore: types.maybeNull(types.number),
  backlinkCount: types.maybeNull(types.number),
  dofollowPages: types.maybeNull(types.number),
  forms: types.maybeNull(types.number),
  frames: types.maybeNull(types.number),
  images: types.maybeNull(types.number),
  ips: types.maybeNull(types.number),
  nofollowPages: types.maybeNull(types.number),
  refferingDomains: types.maybeNull(types.number),
  texts: types.maybeNull(types.number),
  trend: types.maybeNull(types.array(backlinkDataModel)),
  trendWeekly: types.maybeNull(types.array(backlinkDataModel)),
  topicsProfile: types.maybeNull(types.union(
    types.array(types.model({
      category: types.maybeNull(types.string),
      count: types.maybeNull(types.number),
    })), types.frozen([]),
  )),
});
const refDomainsDataModel = types.model({
  results: types.maybeNull(types.array(refDomainResultsModel)),
  totalCount: types.maybeNull(types.number),
});
const outDomainsDataModel = types.model({
  results: types.maybeNull(types.array(outDomainResultsModel)),
  totalCount: types.maybeNull(types.number),
});
const outLinksDataModel = types.model({
  results: types.maybeNull(types.array(outLinkResultsModel)),
  totalCount: types.maybeNull(types.number),
});
const anchorsDataModel = types.model({
  results: types.maybeNull(types.array(anchorsResultsModel)),
  totalCount: types.maybeNull(types.number),
});
const organicPagesModel = types.model({
  results: types.maybeNull(types.array(pagesResultsModel)),
  totalCount: types.maybeNull(types.number),
});
const relevantPagesModel = types.model({
  keywordsCount: types.maybeNull(types.number),
  page: types.maybeNull(types.string),
  traffic: types.maybeNull(types.number),
  trafficIndexPct: types.maybeNull(types.number),
});
const subDomainsResultsModel = types.model({
  keywordCount: types.maybeNull(types.number),
  paidKeywordCount: types.maybeNull(types.number),
  subdomain: types.maybeNull(types.string),
  traffic: types.maybeNull(types.number),
  trafficPct: types.maybeNull(types.number),
});
const subDomainsModel = types.model({
  results: types.array(subDomainsResultsModel),
  totalCount: types.maybeNull(types.number),
});
const organicPositionChangesModel = types.model({
  results: types.maybeNull(types.array(resultsModel)),
  totalCount: types.maybeNull(types.number),
});
const indexedPageResultsModel = types.model({
  domainAscore: types.maybeNull(types.number),
  externalLinkNum: types.maybeNull(types.number),
  internalLinkNum: types.maybeNull(types.number),
  lastSeen: types.maybeNull(types.string),
  firstSeen: types.maybeNull(types.string),
  sourceSize: types.maybeNull(types.number),
  sourceTitle: types.maybeNull(types.string),
  sourceUrl: types.maybeNull(types.string),
  responseCode: types.maybeNull(types.number),
  pageAscore: types.maybeNull(types.number),
  domainsNum: types.maybeNull(types.number),
  backlinksNum: types.maybeNull(types.number),
  ip: types.maybeNull(types.string),
});
const indexedPageDataModel = types.model({
  results: types.array(indexedPageResultsModel),
  totalCount: types.maybeNull(types.number),
});
export const backlinksResultsModel = types.model({
  anchor: types.maybeNull(types.string),
  canonical: types.maybeNull(types.boolean),
  canonicalResolved: types.maybeNull(types.boolean),
  domainAscore: types.maybeNull(types.number),
  externalLinkNum: types.maybeNull(types.number),
  firstSeen: types.maybeNull(types.string),
  form: types.maybeNull(types.boolean),
  frame: types.maybeNull(types.boolean),
  image: types.maybeNull(types.boolean),
  imageAlt: types.maybeNull(types.string),
  imageUrl: types.maybeNull(types.string),
  lostlink: types.maybeNull(types.boolean),
  mobile: types.maybeNull(types.boolean),
  newlink: types.maybeNull(types.boolean),
  nofollow: types.maybeNull(types.boolean),
  internalLinkNum: types.maybeNull(types.number),
  lastSeen: types.maybeNull(types.string),
  ip: types.maybeNull(types.string),
  lang: types.maybeNull(types.string),
  pageAscore: types.maybeNull(types.number),
  // platform: types.maybeNull(types.string),
  redirectUrl: types.maybeNull(types.string),
  position: types.maybeNull(types.string),
  redirect: types.maybeNull(types.boolean),
  redirectCode: types.maybeNull(types.number),
  responseCode: types.maybeNull(types.number),
  sitewide: types.maybeNull(types.boolean),
  sourceSize: types.maybeNull(types.number),
  sourceTitle: types.maybeNull(types.string),
  sourceUrl: types.maybeNull(types.string),
  targetTitle: types.maybeNull(types.string),
  targetUrl: types.maybeNull(types.string),
  sponsored: types.maybeNull(types.boolean),
  text: types.maybeNull(types.boolean),
  ugc: types.maybeNull(types.boolean),
  urlanchor: types.maybeNull(types.boolean),
  domainPower: types.maybeNull(types.number),
  backlinksCount: types.maybeNull(types.number),
  domainsCount: types.maybeNull(types.number),
});
const paramsModel = types.model({
  page_size: types.maybeNull(types.number),
  page: types.maybeNull(types.number),
  ordering: types.maybeNull(types.string),
  search: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
});

const backlinksDataModel = types.model({
  results: types.maybeNull(types.array(backlinksResultsModel)),
  totalCount: types.maybeNull(types.number),
});
const googlePagesResultsModel = types.model({
  crawlTime: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
  keyword: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  uniqHash: types.maybeNull(types.string),
  url: types.maybeNull(types.string),
  visibleUrl: types.maybeNull(types.string),
  keywordCount: types.maybeNull(types.number),
  keywordTotalCount: types.maybeNull(types.number),
  keywordDataLoading: types.optional(types.boolean, false),
  keywordsData: types.maybeNull(types.array(types.frozen({}))),
  keywordsParams: types.optional(paramsModel, DEFAULT_PARAMS),
});
const adTrendArrayModel = types.model({
  keyword: types.maybeNull(types.string),
  cpc: types.maybeNull(types.number),
  searchVolume: types.maybeNull(types.number),
  traffic: types.maybeNull(types.number),
  description: types.maybeNull(types.string),
  date: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  visibleUrl: types.maybeNull(types.string),
  url: types.maybeNull(types.string),
  trafficPct: types.maybeNull(types.number),
  position: types.maybeNull(types.number),
});
const googleHistoryResultsModel = types.model({
  coverage: types.maybeNull(types.number),
  keyword: types.maybeNull(types.string),
  loadingAdTrendData: types.maybeNull(types.boolean),
  cpc: types.maybeNull(types.number),
  searchVolume: types.maybeNull(types.number),
  traffic: types.maybeNull(types.number),
  pisitionTrend: types.maybeNull(types.array(types.model({
    date: types.maybeNull(types.string),
    position: types.maybeNull(types.number),
  }),
  )),
  adTrendResults: types.maybeNull(types.array(adTrendArrayModel)),
});
const organicCompetitorDataModel = types.model({
  results: types.maybeNull(types.array(competitorResultsModel)),
  totalCount: types.maybeNull(types.number),
});
const googlePagesDataModel = types.model({
  results: types.maybeNull(types.array(googlePagesResultsModel)),
  totalCount: types.maybeNull(types.number),
});
const googleHistoryDataModel = types.model({
  results: types.maybeNull(types.array(googleHistoryResultsModel)),
  totalCount: types.maybeNull(types.number),
});

const oranicPositionsModel = types.model({
  changeOfTraffic: types.maybeNull(types.number),
  clickPontentialPct: types.maybeNull(types.number),
  clickPotential: types.maybeNull(types.number),
  cmp: types.maybeNull(types.number),
  cpc: types.maybeNull(types.number),
  intents: types.maybeNull(types.array(types.string)),
  keyword: types.maybeNull(types.string),
  adwordsBlock: types.maybeNull(types.string),
  keywordDifficulty: types.maybeNull(types.number),
  position: types.maybeNull(types.number),
  results: types.maybeNull(types.number),
  positionDifference: types.maybeNull(types.number),
  previousPosition: types.maybeNull(types.number),
  title: types.maybeNull(types.string),
  rankingUrl: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
  searchVolume: types.maybeNull(types.number),
  serpFeatures: types.maybeNull(types.array(types.string)),
  traffic: types.maybeNull(types.number),
  trafficCost: types.maybeNull(types.number),
  trafficCostPct: types.maybeNull(types.number),
  trafficPct: types.maybeNull(types.number),
  trends: types.maybeNull(types.array(types.maybeNull(types.number))),
});

const organicCompetitorModel = types.model({
  commonKeywords: types.maybeNull(types.number),
  competitionLevel: types.maybeNull(types.number),
  competitor: types.maybeNull(types.string),
  competitorKeywords: types.maybeNull(types.number),
  competitorTraffic: types.maybeNull(types.number),
  trafficCost: types.maybeNull(types.number),
  circleColor: types.maybeNull(types.string),
  circleSize: types.maybeNull(types.number),
  isMainSite: types.maybeNull(types.boolean),
});

const organicSummaryModel = types.model({
  countryCode: types.maybeNull(types.string),
  organicKeywords: types.maybeNull(types.number),
  organicTraffic: types.maybeNull(types.number),
  organicTrafficBranded: types.maybeNull(types.number),
  organicTrafficCost: types.maybeNull(types.number),
  organicTrafficNonBranded: types.maybeNull(types.number),
  paidKeywords: types.maybeNull(types.number),
  paidTraffic: types.maybeNull(types.number),
  paidTrafficCost: types.maybeNull(types.number),
});

const organicTrendModel = types.model({
  commercialKeywordsCount: types.maybeNull(types.number),
  commercialKeywordsTraffic: types.maybeNull(types.number),
  commercialKeywordsTrafficCost: types.maybeNull(types.number),
  date: types.maybeNull(types.string),
  informationalKeywordsCount: types.maybeNull(types.number),
  informationalKeywordsTraffic: types.maybeNull(types.number),
  informationalKeywordsTrafficCost: types.maybeNull(types.number),
  navigationalKeywordsCount: types.maybeNull(types.number),
  navigationalKeywordsTraffic: types.maybeNull(types.number),
  navigationalKeywordsTrafficCost: types.maybeNull(types.number),
  organicKeywords: types.maybeNull(types.number),
  organicKeywords4To10: types.maybeNull(types.number),
  organicKeywords11To20: types.maybeNull(types.number),
  organicKeywords21To30: types.maybeNull(types.number),
  organicKeywords31To40: types.maybeNull(types.number),
  organicKeywords41To50: types.maybeNull(types.number),
  organicKeywords51To100: types.maybeNull(types.number),
  organicKeywordsTop3: types.maybeNull(types.number),
  organicTraffic: types.maybeNull(types.number),
  organicTrafficBranded: types.maybeNull(types.number),
  organicTrafficCost: types.maybeNull(types.number),
  organicTrafficNonBranded: types.maybeNull(types.number),
  paidKeywords: types.maybeNull(types.number),
  paidKeywords4To10: types.maybeNull(types.number),
  paidKeywords11To20: types.maybeNull(types.number),
  paidKeywords21To30: types.maybeNull(types.number),
  paidKeywords31To40: types.maybeNull(types.number),
  paidKeywords41To50: types.maybeNull(types.number),
  paidKeywords51To100: types.maybeNull(types.number),
  paidKeywordsTop3: types.maybeNull(types.number),
  paidTraffic: types.maybeNull(types.number),
  paidTrafficCost: types.maybeNull(types.number),
  transactionalKeywordsCount: types.maybeNull(types.number),
  transactionalKeywordsTraffic: types.maybeNull(types.number),
  transactionalKeywordsTrafficCost: types.maybeNull(types.number),
});

const serpFeatureModel = types.model({
  serpFeature: types.maybeNull(types.string),
  siteFeatureCount: types.maybeNull(types.number),
  siteFeatureCountPct: types.maybeNull(types.number),
  totalFeatureCount: types.maybeNull(types.number),
});

const backlinksTrendModel = types.model({
  ascore: types.maybeNull(types.number),
  date: types.maybeNull(types.string),
  lostLinks: types.maybeNull(types.number),
  lostRefDomains: types.maybeNull(types.number),
  newLinks: types.maybeNull(types.number),
  newRefDomains: types.maybeNull(types.number),
  totalLinks: types.maybeNull(types.number),
  totalRefdomains: types.maybeNull(types.number),
});

const researcherDetailDataModel = types.model({
  adwordsPositionChanges: types.maybeNull(types.model({
    dailyTrend: types.maybeNull(types.array(trendModel)),
    monthlyTrend: types.maybeNull(types.array(trendModel)),
  })),
  authorityScore: types.maybeNull(types.number),
  avgVisitDuration: types.maybeNull(types.number),
  avgVisitDurationDiff: types.maybeNull(types.number),
  backlinkCount: types.maybeNull(types.number),
  backlinks: types.maybeNull(types.model({
    pages: types.maybeNull(types.array(types.frozen({}))),
    anchors: types.maybeNull(types.array(types.frozen({}))),
    backlinks: types.maybeNull(types.array(types.frozen({}))),
    refferingDomains: types.maybeNull(types.array(types.frozen({}))),
  })),
  bounceRate: types.maybeNull(types.number),
  bounceRateDiff: types.maybeNull(types.number),
  citationFlow: types.maybeNull(types.union(types.number, types.string)),
  commercialKeywordsCount: types.maybeNull(types.number),
  commercialKeywordsTraffic: types.maybeNull(types.number),
  commercialKeywordsTrafficCost: types.maybeNull(types.number),
  date: types.maybeNull(types.string),
  dofollowPages: types.maybeNull(types.number),
  forms: types.maybeNull(types.number),
  frames: types.maybeNull(types.number),
  images: types.maybeNull(types.number),
  informationalKeywordsCount: types.maybeNull(types.number),
  informationalKeywordsTraffic: types.maybeNull(types.number),
  informationalKeywordsTrafficCost: types.maybeNull(types.number),
  ips: types.maybeNull(types.number),
  links: types.maybeNull(types.number),
  navigationalKeywordsCount: types.maybeNull(types.number),
  navigationalKeywordsTraffic: types.maybeNull(types.number),
  navigationalKeywordsTrafficCost: types.maybeNull(types.number),
  nofollowPages: types.maybeNull(types.number),
  oranicPositions: types.maybeNull(types.array(oranicPositionsModel)),
  paidPositions: types.maybeNull(types.array(oranicPositionsModel)),
  serpFeatures: types.maybeNull(types.array(serpFeatureModel)),
  organicCompetitors: types.maybeNull(types.array(organicCompetitorModel)),
  paidCompetitors: types.maybeNull(types.array(organicCompetitorModel)),
  organicKeywords: types.maybeNull(types.number),
  organicKeywords4To10: types.maybeNull(types.number),
  organicKeywords11To20: types.maybeNull(types.number),
  organicKeywords21To30: types.maybeNull(types.number),
  organicKeywords31To40: types.maybeNull(types.number),
  organicKeywords41To50: types.maybeNull(types.number),
  organicKeywords51To100: types.maybeNull(types.number),
  organicKeywordsTop3: types.maybeNull(types.number),
  organicPositionChanges: types.maybeNull(types.model({
    dailyTrend: types.maybeNull(types.array(trendModel)),
    monthlyTrend: types.maybeNull(types.array(trendModel)),
  })),
  organicSummary: types.maybeNull(types.array(organicSummaryModel)),
  organicTraffic: types.maybeNull(types.number),
  organicTrafficBranded: types.maybeNull(types.number),
  organicTrafficCost: types.maybeNull(types.number),
  organicTrafficNonBranded: types.maybeNull(types.number),
  organicTrend: types.maybeNull(types.array(organicTrendModel)),
  backlinksTrend: types.maybeNull(types.array(backlinksTrendModel)),
  pagesPerVisit: types.maybeNull(types.number),
  pagesPerVisitDiff: types.maybeNull(types.number),
  paidKeywords: types.maybeNull(types.number),
  paidKeywords4To10: types.maybeNull(types.number),
  paidKeywords11To20: types.maybeNull(types.number),
  paidKeywords21To30: types.maybeNull(types.number),
  paidKeywords31To40: types.maybeNull(types.number),
  paidKeywords41To50: types.maybeNull(types.number),
  paidKeywords51To100: types.maybeNull(types.number),
  paidKeywordsTop3: types.maybeNull(types.number),
  paidKeywordsPos1: types.maybeNull(types.number),
  paidKeywords2To3: types.maybeNull(types.number),
  paidKeywords4To9: types.maybeNull(types.number),
  paidKeywords9: types.maybeNull(types.number),
  paidTraffic: types.maybeNull(types.number),
  paidTrafficCost: types.maybeNull(types.number),
  refferingDomains: types.maybeNull(types.number),
  texts: types.maybeNull(types.number),
  transactionalKeywordsCount: types.maybeNull(types.number),
  transactionalKeywordsTraffic: types.maybeNull(types.number),
  transactionalKeywordsTrafficCost: types.maybeNull(types.number),
  trustFlow: types.maybeNull(types.union(types.number, types.string)),
});

const targetPageDetailDataModel = types.model({
  countryCode: types.maybeNull(types.string),
  id: types.maybeNull(types.number),
  mode: types.maybeNull(types.string),
  initialProcessingStatus: types.maybeNull(types.string),
  // rankingKeywords: types.maybeNull(rankingKeywordsModel),
  traffic: types.maybeNull(types.number),
  keywordsCount: types.maybeNull(types.number),
  url: types.maybeNull(types.string),
});

const crDataModel = types.model({
  competitorResearch: types.maybeNull(researcherDetailDataModel),
  countryCode: types.maybeNull(types.string),
  domainPower: types.maybeNull(types.number),
  domainRating: types.maybeNull(types.number),
  id: types.maybeNull(types.number),
  keywordsCount: types.maybeNull(types.number),
  metaDesc: types.maybeNull(types.string),
  mode: types.maybeNull(types.string),
  processingStatus: types.maybeNull(types.string),
  screenshotUrl: types.maybeNull(types.string),
  spamScore: types.maybeNull(types.number),
  title: types.maybeNull(types.string),
  traffic: types.maybeNull(types.number),
  url: types.maybeNull(types.string),
  urlRating: types.maybeNull(types.number),
  domainAuthority: types.maybeNull(types.number),
});

const competitorResearchDetailModel = types.model({
  data: types.maybeNull(crDataModel),
  customerId: types.maybeNull(types.number),
  id: types.maybeNull(types.number),
  searchedOn: types.maybeNull(types.string),
  publicShareHash: types.maybeNull(types.string),
  shouldShowHistogram: types.maybeNull(types.boolean),
  isSaved: types.maybeNull(types.boolean),
});

const targetPageResearchDetailModel = types.model({
  competitorResearch: types.maybeNull(targetPageDetailDataModel),
  customerId: types.maybeNull(types.number),
  id: types.maybeNull(types.number),
  searchedOn: types.maybeNull(types.string),
  publicShareHash: types.maybeNull(types.string),
});

const filterListModel = types.model({
  id: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
  header: types.maybeNull(types.string),
  customFilterValue: types.optional(types.string, ''),
  type: types.maybeNull(types.string),
  text: types.maybeNull(types.string),
  isDecimals: types.maybeNull(types.boolean),
  maxLimit: types.maybeNull(types.number),
  filterField: types.maybeNull(types.string),
  query: types.maybeNull(types.string),
  from: types.maybeNull(types.string),
  to: types.maybeNull(types.string),
  equal: types.maybeNull(types.union(types.string, types.number)),
  customCategoryType: types.maybeNull(types.string),
  isSearch: types.maybeNull(types.boolean),
  active: types.boolean,
  category: types.maybeNull(types.array(types.string)),
  filterTypes: types.maybeNull(types.array(types.model({
    label: types.maybeNull(types.string),
    value: types.maybeNull(types.string),
  }))),
  customOptions: types.maybeNull(types.array(types.model({
    name: types.maybeNull(types.string),
    info: types.optional(types.string, ''),
    showPercent: types.optional(types.boolean, false),
    min: types.maybeNull(types.union(types.string, types.number)),
    max: types.maybeNull(types.union(types.string, types.number)),
    equal: types.maybeNull(types.union(types.string, types.number)),
  }))),
  customOptionsTop: types.maybeNull(types.array(types.model({
    name: types.maybeNull(types.string),
    info: types.optional(types.string, ''),
    showPercent: types.optional(types.boolean, false),
    min: types.maybeNull(types.union(types.string, types.number)),
    max: types.maybeNull(types.union(types.string, types.number)),
  }))),
  customFields: types.maybeNull(types.array(types.model({
    label: types.maybeNull(types.string),
    operator: types.maybeNull(types.string),
  }))),
  label: types.maybeNull(types.string),
  operator: types.maybeNull(types.string),
  operatorLabel: types.maybeNull(types.string),
  filterType: types.maybeNull(types.string),
  value: types.maybeNull(types.string),
  isAdvanced: types.maybeNull(types.boolean),
});

const paramsPositionsModel = types.model({
  changes_type: types.optional(types.string, ''),
  page_size: types.maybeNull(types.number),
  page: types.maybeNull(types.number),
  ordering: types.maybeNull(types.string),
  search: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
});

const positionParamsModel = types.model({
  date_from: types.maybeNull(types.string),
  date_to: types.maybeNull(types.string),
  date_type: types.maybeNull(types.string),
  date_key: types.maybeNull(types.string),
});

export const OverviewStore = types
  .model({
    loadingDetail: types.boolean,
    loadingSubDomainsDetail: types.boolean,
    loadingRankingKeywordsDetail: types.boolean,
    loadingDrawerData: types.boolean,
    loadingRelevantPagesDetail: types.boolean,
    individualUrlDrawer: types.boolean,
    loadingOrganicCompetitorsDetail: types.boolean,
    loadingDetailTags: types.boolean,
    competitorResearchDetail: types.maybeNull(competitorResearchDetailModel),
    targetPageResearchDetail: types.maybeNull(targetPageResearchDetailModel),
    detailApiCallCheck: types.optional(types.boolean, false),
    organicSubDomains: types.maybeNull(subDomainsModel),
    organicPositionChanges: types.maybeNull(organicPositionChangesModel),
    paidPositionChanges: types.maybeNull(organicPositionChangesModel),
    paidSubDomains: types.maybeNull(subDomainsModel),
    organicPages: types.maybeNull(organicPagesModel),
    paidPages: types.maybeNull(organicPagesModel),
    countAllSubDomains: types.maybeNull(types.number),
    allOrganicKeywords: types.maybeNull(rankingKeywordsModel),
    extendedChartData: types.maybeNull(dataModel),
    paidTrafficData: types.maybeNull(paidTrafficDataModel),
    allPaidKeywords: types.maybeNull(rankingKeywordsModel),
    refDomainsData: types.maybeNull(refDomainsDataModel),
    outDomainsData: types.maybeNull(outDomainsDataModel),
    outLinksData: types.maybeNull(outLinksDataModel),
    indexedPageData: types.maybeNull(indexedPageDataModel),
    organicCompetitorData: types.maybeNull(organicCompetitorDataModel),
    paidCompetitorData: types.maybeNull(organicCompetitorDataModel),
    googlePagesData: types.maybeNull(googlePagesDataModel),
    googleHistoryData: types.maybeNull(googleHistoryDataModel),
    backlinksData: types.maybeNull(backlinksDataModel),
    refIpsData: types.maybeNull(refDomainsDataModel),
    anchorsData: types.maybeNull(anchorsDataModel),
    backlinksChartData: types.maybeNull(backlinksChartDataModel),
    allRankingKeywordsCount: types.maybeNull(types.number),
    allRelevantPages: types.maybeNull(types.array(relevantPagesModel)),
    countAllRelevantPages: types.maybeNull(types.number),
    allOrganicCompetitors: types.maybeNull(types.array(organicCompetitorsModel)),
    countAllOrganicCompetitors: types.maybeNull(types.number),
    allCategories: types.maybeNull(types.array(allCategories)),
    countAllCategories: types.maybeNull(types.number),
    activeTab: types.maybeNull(types.string),
    activeMetricsKey: types.maybeNull(types.string),
    individualUrlDrawerPage: types.maybeNull(types.string),
    individualUrlDrawerUrl: types.maybeNull(types.string),
    filteredCountRankingKeyword: types.maybeNull(types.number),
    isError: types.maybeNull(types.boolean),
    showExportModal: types.maybeNull(types.boolean),
    loadingPagesDetail: types.maybeNull(types.boolean),
    loadingPositionChangesDetail: types.maybeNull(types.boolean),
    loadingBacklinksDetail: types.maybeNull(types.boolean),
    loadingRefDomainsDetail: types.maybeNull(types.boolean),
    loadingOutDomainsDetail: types.maybeNull(types.boolean),
    loadingOutLinksDetail: types.maybeNull(types.boolean),
    loadingReferringData: types.maybeNull(types.boolean),
    loadingAnchorsData: types.maybeNull(types.boolean),
    loadingIndexedData: types.maybeNull(types.boolean),
    loadingCompetitorsData: types.maybeNull(types.boolean),
    loadingGooglePagesData: types.maybeNull(types.boolean),
    loadingChartData: types.maybeNull(types.boolean),
    tabCount: types.maybeNull(types.number),
    allOrganicKeywordsParams: types.maybeNull(paramsModel),
    allPaidKeywordsParams: types.maybeNull(paramsModel),
    allOrganicPagesParams: types.maybeNull(paramsModel),
    allPaidPagesParams: types.maybeNull(paramsModel),
    allGoogleHistoryParams: types.maybeNull(paramsModel),
    allBacklinksParams: types.maybeNull(paramsModel),
    allIndexedPagesParams: types.maybeNull(paramsModel),
    allRefDomainsParams: types.maybeNull(paramsModel),
    allOutDomainsParams: types.maybeNull(paramsModel),
    allOutLinksParams: types.maybeNull(paramsModel),
    allRefIpsParams: types.maybeNull(paramsModel),
    allAnchorsParams: types.maybeNull(paramsModel),
    organicPositionChangesParams: types.maybeNull(paramsPositionsModel),
    allPaidPositionChangesParams: types.maybeNull(paramsPositionsModel),
    allPaidCompetitorParams: types.maybeNull(paramsModel),
    allOrganicCompetitorParams: types.maybeNull(paramsModel),
    allOrganicSubdomainsParams: types.maybeNull(paramsModel),
    allPaidSubdomainsParams: types.maybeNull(paramsModel),
    allAdsCopiesParams: types.maybeNull(paramsModel),
    organicKeywordsFilter: types.array(filterListModel),
    organicKeywordsAdditionalFilter: types.array(filterListModel),
    paidKeywordsFilter: types.array(filterListModel),
    paidKeywordsAdditionalFilter: types.array(filterListModel),
    organicPositionsFilter: types.array(filterListModel),
    organicPositionsAdditionalFilter: types.array(filterListModel),
    paidPositionsFilter: types.array(filterListModel),
    paidPositionsAdditionalFilter: types.array(filterListModel),
    organicPagesFilter: types.array(filterListModel),
    organicPagesAdditionalFilter: types.array(filterListModel),
    paidPagesFilter: types.array(filterListModel),
    paidPagesAdditionalFilter: types.array(filterListModel),
    adsCopyFilter: types.array(filterListModel),
    adsCopyAdditionalFilter: types.array(filterListModel),
    adsHistoryFilter: types.array(filterListModel),
    adsHistoryAdditionalFilter: types.array(filterListModel),
    backlinksFilter: types.array(filterListModel),
    backlinksAdditionalFilter: types.array(filterListModel),
    refDomainsFilter: types.array(filterListModel),
    refDomainsAdditionalFilter: types.array(filterListModel),
    outDomainsFilter: types.array(filterListModel),
    anchorsFilter: types.array(filterListModel),
    backlinksSortFilter: types.array(types.frozen({})),
    refDomainsSortFilter: types.array(types.frozen({})),
    isExporting: types.boolean,
    siteExplorerDateStr: types.string,
    siteExplorerCountry: types.string,
    openBacklinksDrawer: types.boolean,
    backlinksDrawerUrl: types.string,
    networkGraphData: types.maybeNull(types.frozen({})),
    outDomainsVisible: types.boolean,
    outDomainsDomain: types.string,
    positionParams: types.maybeNull(positionParamsModel),
    projectDeleted: types.maybeNull(types.boolean),
  }).views(self => ({
    get getRefDomainsSortFilter() {
      return toJS(self.refDomainsSortFilter);
    },
    get getBacklinksSortFilter() {
      return toJS(self.backlinksSortFilter);
    },
    get getNetworkGraphData() {
      return toJS(self.networkGraphData);
    },
    get getOrganicKeywordsFilter() {
      return toJS(self.organicKeywordsFilter);
    },
    get getPaidKeywordsFilter() {
      return toJS(self.paidKeywordsFilter);
    },
    get getOrganicPositionsFilter() {
      return toJS(self.organicPositionsFilter);
    },
    get getPaidPositionsFilter() {
      return toJS(self.paidPositionsFilter);
    },
    get getOrganicPagesFilter() {
      return toJS(self.organicPagesFilter);
    },
    get getPaidPagesFilter() {
      return toJS(self.paidPagesFilter);
    },
    get getAdsCopyFilter() {
      return toJS(self.adsCopyFilter);
    },
    get getAdsHistoryFilter() {
      return toJS(self.adsHistoryFilter);
    },
    get getBacklinksFilter() {
      return toJS(self.backlinksFilter);
    },
    get getRefDomainsFilter() {
      return toJS(self.refDomainsFilter);
    },
    get getOutDomainsFilter() {
      return toJS(self.outDomainsFilter);
    },
    get getAnchorsFilter() {
      return toJS(self.anchorsFilter);
    },
    get getCompetitorResearcherDetail() {
      return toJS(self.competitorResearchDetail);
    },
    get getPaidSubDomains() {
      return toJS(self.paidSubDomains);
    },
    get getOrganicSubDomains() {
      return toJS(self.organicSubDomains);
    },
    get getrefDomainsData() {
      return toJS(self.refDomainsData);
    },
    get getoutDomainsData() {
      return toJS(self.outDomainsData);
    },
    get getoutLinksData() {
      return toJS(self.outLinksData);
    },
    get getbacklinksData() {
      return toJS(self.backlinksData);
    },
    get getOrganicCompetitorData() {
      return toJS(self.organicCompetitorData);
    },
    get getGooglePagesData() {
      return toJS(self.googlePagesData);
    },
    get getGoogleHistoryData() {
      return toJS(self.googleHistoryData);
    },
    get getExtendedChartData() {
      return toJS(self.extendedChartData);
    },
    get getPaidTrafficData() {
      return toJS(self.paidTrafficData);
    },
    get getBacklinksChartData() {
      return toJS(self.backlinksChartData);
    },
    get getPaidCompetitorData() {
      return toJS(self.paidCompetitorData);
    },
    get getAllOrganicKeywords() {
      return toJS(self.allOrganicKeywords);
    },
    get getAllPaidKeywords() {
      return toJS(self.allPaidKeywords);
    },
    get getOrganicPages() {
      return toJS(self.organicPages);
    },
    get getPaidPages() {
      return toJS(self.paidPages);
    },
    get getPaidPositionChangesTable() {
      return toJS(self.paidPositionChanges);
    },
    get getMainUrl() {
      return toJS(self.competitorResearchDetail?.data?.url);
    },
    get getOrganicPositionChanges() {
      return toJS(self.organicPositionChanges);
    },
    get getRefIpsData() {
      return toJS(self.refIpsData);
    },
    get getAnchorsData() {
      return toJS(self.anchorsData);
    },
    get getpositionParamsData() {
      return toJS(self.positionParams);
    },
  })).actions(self => {
    const parent = getParent(self) as any;

    const getCompetitorResearcherData = flow(function* (id, noLoading?: boolean, reRouteOnError = true) {
      if (!noLoading) {
        self.loadingDetailTags = true;
        self.loadingDetail = true;
      }
      self.isError = false;
      const publicHash = getSingleUrlParam('public_hash');
      try {
        const response = yield COMPETITOR_RESEARCHER_API.getCompetitorResearchDetail(id, publicHash);
        const metricsStatus = ['PENDING', null].includes(response?.data?.processingStatus);
        if (metricsStatus && self.detailApiCallCheck) {
          yield new Promise(r => setTimeout(r, COMPETITOR_RESEARCHER_API_POLL_INTERVAL));
          return getCompetitorResearcherData(id);
        } else {
          if (response?.data?.processingStatus === 'FAILURE') {
            notification?.error('Processing FAILED', `Fail to Process ${response?.data?.mode} - ${response?.data?.url}`);
          }
          self.competitorResearchDetail = cast(response);
          self.siteExplorerCountry = response?.data?.countryCode;
          self.loadingDetailTags = false;
          self.loadingDetail = false;
          self.projectDeleted = false;
          return response;
        }
      } catch (e) {
        if (e?.response?.status == 404) {
          parent.setCompetitorResearcherId('');
          parent.setCompetitorResearcherUrl('');
          if (reRouteOnError) {
            Router.push(`/site-explorer/list`);
            if (e?.message == 'Request failed with status code 404') {
              self.isError = true;
            }
            notification.error('Selected project deleted or doesn\'t exists', '');
          } else {
            self.projectDeleted = true;
          }
        }
      }
    });
    const recrawlCompetitorResearcher = flow(function* (id) {
      try {
        const response = yield COMPETITOR_RESEARCHER_API.recrawlCompetitorResearcher(id);
        return response;
      } catch (err) {
        Promise.reject(err);
      }
    });

    const resetNetworkGraphData = () => {
      self.networkGraphData = null;
    };
    const formatFilters = filterList => {
      const newList = [];
      if (Array.isArray(filterList) && filterList?.length) {
        filterList?.map(item => {
          if (item?.name == 'exclude') {
            newList?.push(`${item?.filterField},${item?.type},${item?.query}`);
          } else if (item?.name == 'checkboxes') {
            item?.category?.map(c => {
              newList?.push(`${item?.customCategoryType},equals,${c}`);
            });
          } else if (item?.name == 'radioButtonsFilter') {
            newList?.push(`${item?.customCategoryType},equals,${item?.type}`);
          } else if (item?.name == 'sortFilters' && item?.value) {
            newList?.push(`${item?.type},${item?.operator},${item?.value}`);
          } else if (item?.name == 'secondSortFilters' && item?.value) {
            newList?.push(`${item?.type},${item?.operator},${item?.value}`);
          } else if (item?.isAdvanced) {
            newList?.push(`${item?.name},${item?.operator},${item?.value}`);
          } else if (item?.name !== 'sortFilters' || item?.name !== 'secondSortFilters') {
            if (item?.equal) {
              newList?.push(`${item?.name},equals,${item?.equal}`);
            } else {
              if (+item?.from) {
                newList?.push(`${item?.name},greater_than,${item?.from}`);
              }
              if (item?.to) {
                newList?.push(`${item?.name},less_than,${item?.to}`);
              }
            }
          }
        });
      } else if (filterList?.value) {
        if (filterList?.name == 'sortFilters') {
          newList?.push(`${filterList?.type},${filterList?.operator},${filterList?.value}`);
        }
      }
      return newList;
    };

    const setTabCount = value=>{
      self.tabCount = value;
    };

    const setLoadingDetail = value =>{
      self.loadingDetailTags = value;
      self.loadingDetail = value;
    };

    const setLoadingBacklinksDetail = value =>{
      self.loadingBacklinksDetail = value;
    };

    const activeMainLoader = ()=>{
      self.loadingDetailTags = true;
    };
    const loadCompetitorResearcherDetail = (id:any, noLoading?: boolean, reRouteOnError = true)=>{
      activeMainLoader();
      return getCompetitorResearcherData(id, noLoading, reRouteOnError);
    };

    const recrawlCompetitorResearcherDetail = flow(function* (id) {
      self.loadingDetailTags = true;
      self.loadingDetail = true;
      try {
        const response = yield COMPETITOR_RESEARCHER_API.recrawlCompetitorResearchDetail(id);
        if (response) {
          getCompetitorResearcherData(id);
        }
      } catch (e) {
        return Promise.reject(e);
      }
    });

    const setDetailApiCall = value => {
      self.detailApiCallCheck = value;
    };

    const loadOrganicSubDomains = flow(function* (id, context) {
      self.loadingSubDomainsDetail = true;
      const publicHash = getSingleUrlParam('public_hash');
      const payLoad = {
        context: context,
        date_str: self.siteExplorerDateStr || getSingleUrlParam('siteExplorer_date') || '',
      };
      if (context == 'organic_subdomains') {
        payLoad['page_size'] = self.allOrganicSubdomainsParams?.page_size;
        payLoad['page'] = self.allOrganicSubdomainsParams?.page;
        payLoad['ordering'] = self.allOrganicSubdomainsParams?.ordering;
      }
      self.filteredCountRankingKeyword = 0;
      try {
        const response = yield COMPETITOR_RESEARCHER_API.getRankingKeywordsDetail(id, payLoad, publicHash);
        if (response?.isCancel) {
          self.loadingSubDomainsDetail = true;
        } else {
          self.organicSubDomains = cast(response);
          self.loadingSubDomainsDetail = false;
        }
      } catch (e) {
        if (e?.response?.status == 404) {
          Router.push(`/site-explorer/list`);
        }
        self.loadingSubDomainsDetail = false;
        return Promise.reject(e);
      }
    });
    const loadPaidSubDomains = flow(function* (id, context) {
      self.loadingSubDomainsDetail = true;
      const publicHash = getSingleUrlParam('public_hash');
      const payLoad = {
        context: context,
        date_str: self.siteExplorerDateStr || getSingleUrlParam('siteExplorer_date') || '',
      };
      if (context == 'adwords_subdomains') {
        payLoad['page_size'] = self.allPaidSubdomainsParams?.page_size;
        payLoad['page'] = self.allPaidSubdomainsParams?.page;
        payLoad['ordering'] = self.allPaidSubdomainsParams?.ordering;
      }
      self.filteredCountRankingKeyword = 0;
      try {
        const response = yield COMPETITOR_RESEARCHER_API.getRankingKeywordsDetail(id, payLoad, publicHash);
        if (response?.isCancel) {
          self.loadingSubDomainsDetail = true;
        } else {
          self.paidSubDomains = cast(response);
          self.loadingSubDomainsDetail = false;
        }
      } catch (e) {
        if (e?.response?.status == 404) {
          Router.push(`/site-explorer/list`);
        }
        self.loadingSubDomainsDetail = false;
        return Promise.reject(e);
      }
    });
    const loadOrganicPages = flow(function* (id, filters?: any) {
      self.loadingPagesDetail = true;
      const publicHash = getSingleUrlParam('public_hash');
      const payLoad = {
        context: 'organic_pages',
        date_str: self.siteExplorerDateStr || getSingleUrlParam('siteExplorer_date') || '',
        ...self.allOrganicPagesParams,
      };
      self.filteredCountRankingKeyword = 0;
      try {
        const response = yield COMPETITOR_RESEARCHER_API.getRankingKeywordsDetail(id, payLoad, publicHash, formatFilters(filters));
        if (response?.isCancel) {
          self.loadingPagesDetail = true;
        } else {
          self.organicPages = cast(response);
          self.loadingPagesDetail = false;
        }
      } catch (e) {
        if (e?.response?.status == 404) {
          Router.push(`/site-explorer/list`);
        }
        self.loadingPagesDetail = false;
        return Promise.reject(e);
      }
    });
    const setAllOrganicPagesParams = (data, id) => {
      self.allOrganicPagesParams = data;
      if (id) {
        loadOrganicPages(id, toJS(self.organicPagesFilter)?.filter(i => i?.active)?.concat(self.organicPagesAdditionalFilter));
      }
    };
    const loadPaidPages = flow(function* (id, filters?: any) {
      self.loadingPagesDetail = true;
      const publicHash = getSingleUrlParam('public_hash');
      const payLoad = {
        context: 'adwords_pages',
        date_str: self.siteExplorerDateStr || getSingleUrlParam('siteExplorer_date') || '',
        ...self.allPaidPagesParams,
      };
      self.filteredCountRankingKeyword = 0;
      try {
        const response = yield COMPETITOR_RESEARCHER_API.getRankingKeywordsDetail(id, payLoad, publicHash, formatFilters(filters));
        if (response?.isCancel) {
          self.loadingPagesDetail = true;
        } else {
          self.paidPages = cast(response);
          self.loadingPagesDetail = false;
        }
      } catch (e) {
        if (e?.response?.status == 404) {
          Router.push(`/site-explorer/list`);
        }
        self.loadingPagesDetail = false;
        return Promise.reject(e);
      }
    });
    const setAllPaidPagesParams = (data, id) => {
      self.allPaidPagesParams = data;
      if (id) {
        loadPaidPages(id, toJS(self.paidPagesFilter)?.filter(i => i?.active)?.concat(self.paidPagesAdditionalFilter));
      }
    };
    const loadOrganicPositionChanges = flow(function* (id, filters?: any) {
      self.loadingPositionChangesDetail = true;
      const publicHash = getSingleUrlParam('public_hash');
      const payLoad = {
        context: 'organic_position_changes_v2',
        date_str: self.siteExplorerDateStr || getSingleUrlParam('siteExplorer_date') || '',
        ...self.organicPositionChangesParams,
      };
      self.filteredCountRankingKeyword = 0;
      try {
        const response = yield COMPETITOR_RESEARCHER_API.getRankingKeywordsDetail(id, payLoad, publicHash, formatFilters(filters));
        if (response?.isCancel) {
          self.loadingPositionChangesDetail = true;
        } else {
          self.organicPositionChanges = cast(response);
          self.loadingPositionChangesDetail = false;
        }
      } catch (e) {
        self.loadingPositionChangesDetail = false;
        return Promise.reject(e);
      }
    });
    const loadOrganicPositionChangesV2 = flow(function* (id, filters?: any) {
      self.loadingPositionChangesDetail = true;
      const publicHash = getSingleUrlParam('public_hash');
      const payLoad = {
        context: 'organic_position_changes_v2',
        ...self.organicPositionChangesParams,
      };
      if (self.positionParams) {
        Object.keys(self.positionParams).forEach(key => {
          payLoad[key] = self.positionParams[key];
        });
        delete payLoad['date_key'];
      }
      self.filteredCountRankingKeyword = 0;
      try {
        const response = yield COMPETITOR_RESEARCHER_API.getRankingKeywordsDetailV2(id, payLoad, publicHash, formatFilters(filters));
        if (response?.isCancel) {
          self.loadingPositionChangesDetail = true;
        } else {
          self.organicPositionChanges = cast(response);
          self.loadingPositionChangesDetail = false;
        }
      } catch (e) {
        self.loadingPositionChangesDetail = false;
        return Promise.reject(e);
      }
    });
    const setOrganicPositionChangesParams = (data, id) => {
      self.organicPositionChangesParams = data;
      if (id) {
        loadOrganicPositionChanges(id, toJS(self.organicPositionsFilter)?.filter(i => i?.active)?.concat(self.organicPositionsAdditionalFilter));
      }
    };
    const setOrganicPositionChangesParamsV2 = (data, id) => {
      self.organicPositionChangesParams = data;
      if (id) {
        loadOrganicPositionChangesV2(id, toJS(self.organicPositionsFilter)?.filter(i => i?.active)?.concat(self.organicPositionsAdditionalFilter));
      }
    };
    const getPaidPositionChanges = flow(function* (id, filters?: any) {
      self.loadingPositionChangesDetail = true;
      const publicHash = getSingleUrlParam('public_hash');
      const payLoad = {
        context: 'adwords_position_changes',
        ...self.allPaidPositionChangesParams,
      };
      self.filteredCountRankingKeyword = 0;
      if (self.positionParams) {
        Object.keys(self.positionParams).forEach(key => {
          payLoad[key] = self.positionParams[key];
          delete payLoad['date_key'];
        });
      }
      try {
        const response = yield COMPETITOR_RESEARCHER_API.getRankingKeywordsDetail(id, payLoad, publicHash, formatFilters(filters));
        if (response?.isCancel) {
          self.loadingPositionChangesDetail = true;
        } else {
          self.paidPositionChanges = cast(response);
          self.loadingPositionChangesDetail = false;
        }
      } catch (e) {
        if (e?.response?.status == 404) {
          Router.push(`/site-explorer/list`);
        }
        self.loadingPositionChangesDetail = false;
        return Promise.reject(e);
      }
    });
    const setAllPaidPositionChangesParams = (data, id) => {
      self.allPaidPositionChangesParams = data;
      if (id) {
        getPaidPositionChanges(id, toJS(self.paidPositionsFilter)?.filter(i => i?.active)?.concat(self.paidPositionsAdditionalFilter));
      }
    };
    const setAllOrganicSubdomainsParams = (data, id, context) => {
      self.allOrganicSubdomainsParams = data;
      if (id) {
        loadOrganicSubDomains(id, context);
      }
    };
    const setAllPaidSubdomainsParams = (data, id, context) => {
      self.allPaidSubdomainsParams = data;
      if (id) {
        loadPaidSubDomains(id, context);
      }
    };

    const loadAllOrganicKeywords = flow(function* (id, filters?: any) {
      const index = filters.findIndex(item => item?.header === 'Positions Type');
      let positionTypeFilter = null;

      if (index !== -1) {
        positionTypeFilter = filters[index];

        filters.splice(index, 1);
      }
      self.loadingRankingKeywordsDetail = true;
      const publicHash = getSingleUrlParam('public_hash');
      const payLoad = {
        context: 'organic_keywords',
        date_str: self.siteExplorerDateStr || getSingleUrlParam('siteExplorer_date') || '',
        ...self.allOrganicKeywordsParams,
      };
      self.filteredCountRankingKeyword = 0;
      try {
        const response = yield COMPETITOR_RESEARCHER_API.getRankingKeywordsDetail(id, payLoad, publicHash, formatFilters(filters), positionTypeFilter);
        if (response?.isCancel) {
          self.loadingRankingKeywordsDetail = true;
        } else {
          self.allOrganicKeywords = cast(response);
          self.loadingRankingKeywordsDetail = false;
        }
      } catch (e) {
        if (e?.response?.status == 404) {
          Router.push(`/site-explorer/list`);
        }
        self.loadingRankingKeywordsDetail = false;
        return Promise.reject(e);
      }
    });
    const setAllOrganicKeywordsParams = (data, id) => {
      self.allOrganicKeywordsParams = data;
      if (id) {
        loadAllOrganicKeywords(id, toJS(self.organicKeywordsFilter)?.filter(i => i?.active)?.concat(self.organicKeywordsAdditionalFilter));
      }
    };
    const loadAllPaidKeywords = flow(function* (id, filters?: any) {
      self.loadingRankingKeywordsDetail = true;
      const publicHash = getSingleUrlParam('public_hash');
      const payLoad = {
        context: 'adwords_keywords',
        date_str: self.siteExplorerDateStr || getSingleUrlParam('siteExplorer_date') || '',
        ...self.allPaidKeywordsParams,
      };
      self.filteredCountRankingKeyword = 0;
      try {
        const response = yield COMPETITOR_RESEARCHER_API.getRankingKeywordsDetail(id, payLoad, publicHash, formatFilters(filters));
        if (response?.isCancel) {
          self.loadingRankingKeywordsDetail = true;
        } else {
          self.allPaidKeywords = cast(response);
          self.loadingRankingKeywordsDetail = false;
        }
      } catch (e) {
        if (e?.response?.status == 404) {
          Router.push(`/site-explorer/list`);
        }
        self.loadingRankingKeywordsDetail = false;
        return Promise.reject(e);
      }
    });
    const setAllPaidKeywordsParams = (data, id) => {
      self.allPaidKeywordsParams = data;
      if (id) {
        loadAllPaidKeywords(id, toJS(self.paidKeywordsFilter)?.filter(i => i?.active)?.concat(self.paidKeywordsAdditionalFilter));
      }
    };

    function replaceSameValues(data) {
      const uniqueTitles = new Set();
      const result = data.map(title => {
        if (!uniqueTitles.has(title.sourceTitle)) {
          uniqueTitles.add(title.sourceTitle);
          return {...title};
        }
        return {...title, sourceTitle: 'emptyRow'};
      });
      return result;
    }
    const loadBacklinksData = flow(function* (id, context, filters?: any, sortFilters?: any, secondSortFilters?: any) {
      self.loadingBacklinksDetail = true;
      const publicHash = getSingleUrlParam('public_hash');
      const payLoad = {
        context: context,
        date_str: self.siteExplorerDateStr || getSingleUrlParam('siteExplorer_date') || '',
      };
      if (context == 'backlinks') {
        payLoad['page_size'] = self.allBacklinksParams?.page_size;
        payLoad['page'] = self.allBacklinksParams?.page;
        payLoad['ordering'] = self.allBacklinksParams?.ordering;
      }
      if (self.backlinksDrawerUrl) {
        payLoad['url'] = self.backlinksDrawerUrl;
      }
      if (sortFilters || secondSortFilters) {
        const firstIndex = (self.backlinksSortFilter || []).findIndex(z => z['type'] === (sortFilters?.type));
        const secondIndex = (self.backlinksSortFilter || []).findIndex(z => z['type'] === (secondSortFilters?.type));
        if (sortFilters?.value || secondSortFilters?.value) {
          if (firstIndex !== -1 || secondIndex !== -1) {
            self.backlinksSortFilter[firstIndex] = sortFilters;
            self.backlinksSortFilter[secondIndex] = secondSortFilters;
          } else {
            self.backlinksSortFilter = cast([...toJS(self.backlinksSortFilter), sortFilters, secondSortFilters]);
          }
        } else {
          self.backlinksSortFilter = cast(toJS(self.backlinksSortFilter)?.filter(i => i['type'] !== sortFilters?.type));
          self.backlinksSortFilter = cast(toJS(self.backlinksSortFilter)?.filter(i => i['type'] !== secondSortFilters?.type));
        }
      } else {
        self.backlinksSortFilter = cast([]);
      }
      try {
        const response = yield COMPETITOR_RESEARCHER_API.getRankingKeywordsDetail(id, payLoad, publicHash, [...formatFilters(filters), ...formatFilters(toJS(self.backlinksSortFilter))]);
        if (response?.isCancel) {
          self.loadingBacklinksDetail = true;
        } else {
          const formatList = replaceSameValues(response?.results);
          const newObject = {results: formatList, totalCount: response?.totalCount};
          self.backlinksData = cast(newObject);
          self.loadingBacklinksDetail = false;
        }
      } catch (e) {
        if (e?.response?.status == 404) {
          Router.push(`/site-explorer/list`);
        }
        self.loadingBacklinksDetail = false;
        return Promise.reject(e);
      }
    });
    const loadReferringDomainData = flow(function* (id, context, filters?: any, sortFilters?: any) {
      self.loadingRefDomainsDetail = true;
      const publicHash = getSingleUrlParam('public_hash');
      const payLoad = {
        context: context,
        date_str: self.siteExplorerDateStr || getSingleUrlParam('siteExplorer_date') || '',
      };
      if (context == 'refdomains') {
        payLoad['page_size'] = self.allRefDomainsParams?.page_size;
        payLoad['page'] = self.allRefDomainsParams?.page;
        payLoad['ordering'] = self.allRefDomainsParams?.ordering;
      }
      if (sortFilters) {
        const indexObj = toJS(self.refDomainsSortFilter)?.findIndex(z => z['type'] == sortFilters?.type);
        if (sortFilters?.value) {
          if (indexObj !== -1) {
            self.refDomainsSortFilter[indexObj] = sortFilters;
          } else {
            self.refDomainsSortFilter = cast([...toJS(self.refDomainsSortFilter), sortFilters]);
          }
        } else {
          self.refDomainsSortFilter = cast(toJS(self.refDomainsSortFilter)?.filter(i => i['type'] !== sortFilters?.type));
        }
      }
      try {
        const response = yield COMPETITOR_RESEARCHER_API.getRankingKeywordsDetail(id, payLoad, publicHash, [...formatFilters(filters), ...formatFilters(toJS(self.refDomainsSortFilter))]);
        if (response?.isCancel) {
          self.loadingRefDomainsDetail = true;
        } else {
          self.refDomainsData = cast(response);
          self.loadingRefDomainsDetail = false;
        }
      } catch (e) {
        if (e?.response?.status == 404) {
          Router.push(`/site-explorer/list`);
        }
        self.loadingRefDomainsDetail = false;
        return Promise.reject(e);
      }
    });
    const loadOutboundLinksData = flow(function* (id, context) {
      self.loadingOutLinksDetail = true;
      const payLoad = {
        context: context,
      };
      if (context == 'outgoing_links') {
        payLoad['page_size'] = self.allOutLinksParams?.page_size;
        payLoad['page'] = self.allOutLinksParams?.page;
      }
      try {
        const response = yield COMPETITOR_RESEARCHER_API.getOutLinksDetail(id, payLoad, self.outDomainsDomain);
        if (response?.isCancel) {
          self.loadingOutLinksDetail = true;
        } else {
          self.outLinksData = cast(response);
          self.loadingOutLinksDetail = false;
        }
      } catch (e) {
        if (e?.response?.status == 404) {
          Router.push(`/site-explorer/list`);
        }
        self.loadingOutLinksDetail = false;
        return Promise.reject(e);
      }
    });
    const loadOutboundDomainData = flow(function* (id, context, filters?: any) {
      self.loadingOutDomainsDetail = true;
      const publicHash = getSingleUrlParam('public_hash');
      const payLoad = {
        context: context,
      };
      if (context == 'outgoing_domains') {
        payLoad['page_size'] = self.allOutDomainsParams?.page_size;
        payLoad['page'] = self.allOutDomainsParams?.page;
      }
      const newFilterArr = [...filters];
      const indexDomain = filters?.findIndex(z => z?.filterField == 'domain_filter');
      if (indexDomain !== -1) {
        newFilterArr[indexDomain] = {
          ...newFilterArr[indexDomain],
          filterField: 'domain',
        };
      }
      try {
        const response = yield COMPETITOR_RESEARCHER_API.getRankingKeywordsDetail(id, payLoad, publicHash, [...formatFilters(newFilterArr), ...formatFilters(toJS(self.refDomainsSortFilter))]);
        if (response?.isCancel) {
          self.loadingOutDomainsDetail = true;
        } else {
          self.outDomainsData = cast(response);
          self.loadingOutDomainsDetail = false;
        }
      } catch (e) {
        if (e?.response?.status == 404) {
          Router.push(`/site-explorer/list`);
        }
        self.loadingOutDomainsDetail = false;
        return Promise.reject(e);
      }
    });
    const loadAnchors = flow(function* (id, context, filters?: any) {
      self.loadingAnchorsData = true;
      const publicHash = getSingleUrlParam('public_hash');
      const payLoad = {
        context: context,
        date_str: self.siteExplorerDateStr || getSingleUrlParam('siteExplorer_date') || '',
      };
      if (context == 'anchors') {
        payLoad['page_size'] = self.allAnchorsParams?.page_size;
        payLoad['page'] = self.allAnchorsParams?.page;
        payLoad['ordering'] = self.allAnchorsParams?.ordering;
      }
      try {
        const response = yield COMPETITOR_RESEARCHER_API.getRankingKeywordsDetail(id, payLoad, publicHash, formatFilters(filters));
        if (response?.isCancel) {
          self.loadingAnchorsData = true;
        } else {
          self.anchorsData = cast(response);
          self.loadingAnchorsData = false;
        }
      } catch (e) {
        if (e?.response?.status == 404) {
          Router.push(`/site-explorer/list`);
        }
        self.loadingAnchorsData = false;
        return Promise.reject(e);
      }
    });
    const loadReferringIps = flow(function* (id, context) {
      self.loadingReferringData = true;
      const publicHash = getSingleUrlParam('public_hash');
      const payLoad = {
        context: context,
        date_str: self.siteExplorerDateStr || getSingleUrlParam('siteExplorer_date') || '',
      };
      if (context == 'refips') {
        payLoad['page_size'] = self.allRefIpsParams?.page_size;
        payLoad['page'] = self.allRefIpsParams?.page;
        payLoad['ordering'] = self.allRefIpsParams?.ordering;
      }
      try {
        const response = yield COMPETITOR_RESEARCHER_API.getRankingKeywordsDetail(id, payLoad, publicHash);
        if (response?.isCancel) {
          self.loadingReferringData = true;
        } else {
          self.refIpsData = cast(response);
          self.loadingReferringData = false;
        }
      } catch (e) {
        if (e?.response?.status == 404) {
          Router.push(`/site-explorer/list`);
        }
        self.loadingReferringData = false;
        return Promise.reject(e);
      }
    });
    const loadIndexedPagesData = flow(function* (id, context) {
      self.loadingIndexedData = true;
      const publicHash = getSingleUrlParam('public_hash');
      const payLoad = {
        context: context,
        date_str: self.siteExplorerDateStr || getSingleUrlParam('siteExplorer_date') || '',
      };
      if (context == 'indexed_pages') {
        payLoad['page_size'] = self.allIndexedPagesParams?.page_size;
        payLoad['page'] = self.allIndexedPagesParams?.page;
        payLoad['ordering'] = self.allIndexedPagesParams?.ordering;
      }
      try {
        const response = yield COMPETITOR_RESEARCHER_API.getRankingKeywordsDetail(id, payLoad, publicHash);
        if (response?.isCancel) {
          self.loadingIndexedData = true;
        } else {
          self.indexedPageData = cast(response);
          self.loadingIndexedData = false;
        }
      } catch (e) {
        if (e?.response?.status == 404) {
          Router.push(`/site-explorer/list`);
        }
        self.loadingIndexedData = false;
        return Promise.reject(e);
      }
    });
    const loadGooglePageData = flow(function* (id, context, filters?: any) {
      self.loadingGooglePagesData = true;
      const publicHash = getSingleUrlParam('public_hash');
      const payLoad = {
        context: context,
        date_str: self.siteExplorerDateStr || getSingleUrlParam('siteExplorer_date') || '',
      };
      if (context == 'adwords_copies') {
        payLoad['page_size'] = self.allIndexedPagesParams?.page_size;
        payLoad['page'] = self.allIndexedPagesParams?.page;
        payLoad['ordering'] = self.allIndexedPagesParams?.ordering;
      }
      try {
        const response = yield COMPETITOR_RESEARCHER_API.getRankingKeywordsDetail(id, payLoad, publicHash, formatFilters(filters));
        if (response?.isCancel) {
          self.loadingGooglePagesData = true;
        } else {
          self.googlePagesData = cast(response);
          self.loadingGooglePagesData = false;
        }
      } catch (e) {
        if (e?.response?.status == 404) {
          Router.push(`/site-explorer/list`);
        }
        self.loadingGooglePagesData = false;
        return Promise.reject(e);
      }
    });
    const loadAdTrendData = flow(function* (id, context, keyword) {
      const findIndex = toJS(self.googleHistoryData)?.results?.findIndex(z => z?.keyword == keyword);
      self.googleHistoryData.results[findIndex].loadingAdTrendData = true;
      try {
        const response = yield COMPETITOR_RESEARCHER_API.getTrendData(id, context, keyword);
        if (response?.results?.length) {
          self.googleHistoryData.results[findIndex].adTrendResults = response?.results;
        }
      } catch (e) {
        return Promise.reject(e);
      } finally {
        self.googleHistoryData.results[findIndex].loadingAdTrendData = false;
      }
    });
    const loadGoogleAdsKeywordData = flow(function* (id, uniqHash, page, pageSize) {
      const publicHash = getSingleUrlParam('public_hash');
      const payLoad = {
        context: 'adwords_keywords',
        uniq_hash: uniqHash,
        date_str: self.siteExplorerDateStr || getSingleUrlParam('siteExplorer_date') || '',
      };
      const findIndex = toJS(self.googlePagesData)?.results?.findIndex(z => z?.uniqHash == uniqHash);
      const paramsData = {
        ...self.googlePagesData.results[findIndex].keywordsParams,
        page: page,
        page_size: pageSize,
      };
      if (findIndex !== -1) {
        payLoad['page'] = page;
        payLoad['page_size'] = pageSize;
      }
      self.googlePagesData.results[findIndex].keywordsParams = paramsData;
      self.googlePagesData.results[findIndex].keywordDataLoading = true;
      try {
        const response = yield COMPETITOR_RESEARCHER_API.getAdsCopyKeywordDetail(id, payLoad, publicHash);
        if (response?.isCancel) {
          self.googlePagesData.results[findIndex].keywordDataLoading = true;
        } else {
          self.googlePagesData.results[findIndex].keywordsData = cast(response?.results);
          self.googlePagesData.results[findIndex].keywordTotalCount = cast(response?.totalCount);
          self.googlePagesData.results[findIndex].keywordDataLoading = false;
        }
      } catch (e) {
        if (e?.response?.status == 404) {
          Router.push(`/site-explorer/list`);
        }
        self.googlePagesData.results[findIndex].keywordDataLoading = false;
        return Promise.reject(e);
      }
    });

    const setAllAdsCopiesParams = (data, id) => {
      self.allAdsCopiesParams = data;
      if (id) {
        loadGooglePageData(id, 'adwords_copies', toJS(self.adsCopyFilter)?.filter(z => z?.active)?.concat(self.adsCopyAdditionalFilter));
      }
    };
    const loadGoogleHistoryData = flow(function* (id, context, filters?: any) {
      self.loadingGooglePagesData = true;
      const publicHash = getSingleUrlParam('public_hash');
      const payLoad = {
        context: context,
        date_str: self.siteExplorerDateStr || getSingleUrlParam('siteExplorer_date') || '',
      };
      if (context == 'adwords_adhistory') {
        payLoad['page_size'] = self.allGoogleHistoryParams?.page_size;
        payLoad['page'] = self.allGoogleHistoryParams?.page;
        payLoad['ordering'] = self.allGoogleHistoryParams?.ordering;
      }
      try {
        const response = yield COMPETITOR_RESEARCHER_API.getRankingKeywordsDetail(id, payLoad, publicHash, formatFilters(filters));
        if (response?.isCancel) {
          self.loadingGooglePagesData = true;
        } else {
          self.googleHistoryData = cast(response);
          self.loadingGooglePagesData = false;
        }
      } catch (e) {
        if (e?.response?.status == 404) {
          Router.push(`/site-explorer/list`);
        }
        self.loadingGooglePagesData = false;
        return Promise.reject(e);
      }
    });
    const setAllGoogleHistoryParams = (data, id, context) => {
      self.allGoogleHistoryParams = data;
      if (id) {
        loadGoogleHistoryData(id, context, toJS(self.adsHistoryFilter)?.filter(z => z?.active)?.concat(self.adsHistoryAdditionalFilter));
      }
    };
    const setAllBacklinksParams = (data, id, context) => {
      self.allBacklinksParams = data;
      if (id) {
        loadBacklinksData(id, context, toJS(self.backlinksFilter)?.filter(z => z?.active)?.concat(self.backlinksAdditionalFilter));
      }
    };
    const setIndexedPagesParams = (data, id, context) => {
      self.allIndexedPagesParams = data;
      if (id) {
        loadIndexedPagesData(id, context);
      }
    };
    const setRefDomainsParams = (data, id, context) => {
      self.allRefDomainsParams = data;
      if (id) {
        loadReferringDomainData(id, context, toJS(self.refDomainsFilter)?.filter(z => z?.active)?.concat(self.refDomainsAdditionalFilter));
      }
    };
    const setOutDomainsParams = (data, id, context) => {
      self.allOutDomainsParams = data;
      if (id) {
        loadOutboundDomainData(id, context, toJS(self.outDomainsFilter)?.filter(z => z?.active));
      }
    };
    const setOutLinksParams = (data, id, context) => {
      self.allOutLinksParams = data;
      if (id) {
        loadOutboundLinksData(id, context);
      }
    };
    const setRefIpsParams = (data, id, context) => {
      self.allRefIpsParams = data;
      if (id) {
        loadReferringIps(id, context);
      }
    };
    const setAnchorsParams = (data, id, context) => {
      self.allAnchorsParams = data;
      if (id) {
        loadAnchors(id, context, toJS(self.anchorsFilter)?.filter(z => z?.active));
      }
    };
    const loadOrganicCompetitors = flow(function* (id, context) {
      self.loadingCompetitorsData = true;
      const publicHash = getSingleUrlParam('public_hash');
      const payLoad = {
        context: context,
        date_str: self.siteExplorerDateStr || getSingleUrlParam('siteExplorer_date') || '',
        ...self.allOrganicCompetitorParams,
      };
      try {
        const response = yield COMPETITOR_RESEARCHER_API.getRankingKeywordsDetail(id, payLoad, publicHash);
        if (response?.isCancel) {
          self.loadingCompetitorsData = true;
        } else {
          self.organicCompetitorData = cast(response);
          self.loadingCompetitorsData = false;
        }
      } catch (e) {
        if (e?.response?.status == 404) {
          Router.push(`/site-explorer/list`);
        }
        self.loadingCompetitorsData = false;
        return Promise.reject(e);
      }
    });
    const setAllOrganicCompetitorParams = (data, id, context) => {
      self.allOrganicCompetitorParams = data;
      if (id) {
        loadOrganicCompetitors(id, context);
      }
    };
    const loadPaidCompetitors = flow(function* (id, context) {
      self.loadingCompetitorsData = true;
      const publicHash = getSingleUrlParam('public_hash');
      const payLoad = {
        context: context,
        date_str: self.siteExplorerDateStr || getSingleUrlParam('siteExplorer_date') || '',
        ...self.allPaidCompetitorParams,
      };
      try {
        const response = yield COMPETITOR_RESEARCHER_API.getRankingKeywordsDetail(id, payLoad, publicHash);
        if (response?.isCancel) {
          self.loadingCompetitorsData = true;
        } else {
          self.paidCompetitorData = cast(response);
          self.loadingCompetitorsData = false;
        }
      } catch (e) {
        if (e?.response?.status == 404) {
          Router.push(`/site-explorer/list`);
        }
        self.loadingCompetitorsData = false;
        return Promise.reject(e);
      }
    });
    const setAllPaidCompetitorParams = (data, id, context) => {
      self.allPaidCompetitorParams = data;
      if (id) {
        loadPaidCompetitors(id, context);
      }
    };
    const loadNetworkGraphData = flow(function* (id) {
      self.loadingChartData = true;
      const publicHash = getSingleUrlParam('public_hash');
      const payLoad = {
        context: 'network_graph',
      };
      try {
        const response = yield COMPETITOR_RESEARCHER_API.getRankingKeywordsDetail(id, payLoad, publicHash);
        if (response) {
          self.networkGraphData = cast(response);
        }
      } catch (e) {
        if (e?.response?.status == 404) {
          Router.push(`/site-explorer/list`);
        }
        return Promise.reject(e);
      } finally {
        self.loadingChartData = false;
      }
    });
    const loadExtendedChartData = flow(function* (id, context) {
      self.loadingChartData = true;
      const publicHash = getSingleUrlParam('public_hash');
      const payLoad = {
        context: context,
      };
      try {
        const response = yield COMPETITOR_RESEARCHER_API.getExtendedData(id, payLoad, publicHash);
        if (response) {
          self.extendedChartData = cast(response);
        }
      } catch (e) {
        if (e?.response?.status == 404) {
          Router.push(`/site-explorer/list`);
        }
        return Promise.reject(e);
      } finally {
        self.loadingChartData = false;
      }
    });
    const loadExtendedPaidChartData = flow(function* (id, context) {
      self.loadingChartData = true;
      const publicHash = getSingleUrlParam('public_hash');
      const payLoad = {
        context: context,
      };
      try {
        const response = yield COMPETITOR_RESEARCHER_API.getExtendedData(id, payLoad, publicHash);
        if (response) {
          self.paidTrafficData = cast(response);
        }
      } catch (e) {
        return Promise.reject(e);
      } finally {
        self.loadingChartData = false;
      }
    });
    const loadExtendedBacklinksChartData = flow(function* (id, context) {
      self.loadingChartData = true;
      const publicHash = getSingleUrlParam('public_hash');
      const payLoad = {
        context: context,
      };
      try {
        const response = yield COMPETITOR_RESEARCHER_API.getExtendedData(id, payLoad, publicHash);
        if (response) {
          self.backlinksChartData = cast(response);
        }
      } catch (e) {
        if (e?.response?.status == 404) {
          Router.push(`/site-explorer/list`);
        }
        return Promise.reject(e);
      } finally {
        self.loadingChartData = false;
      }
    });

    const getKeywordsFilteredDataIndividualURL = flow(function* (id, params) {
      const publicHash = getSingleUrlParam('public_hash');
      self.loadingRankingKeywordsDetail = true;
      // self.targetPageResearchDetail.competitorResearch.rankingKeywords.data = null;
      try {
        const response = yield COMPETITOR_RESEARCHER_API.getRankingKeywordsFilteredDetail(id, params, publicHash);
        if (response) {
          // self.targetPageResearchDetail.competitorResearch.rankingKeywords.data = cast(response?.data?.data);
        }
      } catch (e) {
        return Promise.reject(e);
      } finally {
        self.loadingRankingKeywordsDetail = false;
      }
    });

    const setActiveTab = (key : string) => {
      self.activeTab = key;
    };

    const setIndividualUrlDrawer = (val, pageUrl, page) => {
      if (val) {
        self.loadingDrawerData = true;
      }
      self.individualUrlDrawer = val;
      self.individualUrlDrawerPage = page;
      self.individualUrlDrawerUrl = pageUrl;
    };

    const clearAllOrganicCompetitors = ()=>{
      self.allOrganicCompetitors = null;
      self.allRelevantPages = null;
      // self.allRankingKeywords = null;
      // self.allSubDomains = null;
      self.competitorResearchDetail = null;
      self.targetPageResearchDetail = null;
    };

    const setShowExportModal = value=>{
      self.showExportModal = value;
    };

    const setActiveMetricsKey = value => {
      self.activeMetricsKey = value;
    };

    const updateOrganicKeywordsFilter = (filters, id) => {
      self.allOrganicKeywordsParams.page = 1;
      self.organicKeywordsFilter = cast(filters);
      if (id) {
        loadAllOrganicKeywords(id, filters?.filter(z => z?.active)?.concat(self.organicKeywordsAdditionalFilter));
      }
    };
    const updateOrganicKeywordsAdditionalFilter = (filters, id) => {
      self.allOrganicKeywordsParams.page = 1;
      self.organicKeywordsAdditionalFilter = cast(filters);
      if (id) {
        loadAllOrganicKeywords(id, toJS(self.organicKeywordsFilter)?.filter(i => i?.active)?.concat(self.organicKeywordsAdditionalFilter));
      }
    };
    const updatePaidKeywordsFilter = (filters, id) => {
      self.allPaidKeywordsParams.page = 1;
      self.paidKeywordsFilter = cast(filters);
      if (id) {
        loadAllPaidKeywords(id, filters?.filter(z => z?.active)?.concat(self.paidKeywordsAdditionalFilter));
      }
    };
    const updatePaidKeywordsAdditionalFilter = (filters, id) => {
      self.allPaidKeywordsParams.page = 1;
      self.paidKeywordsAdditionalFilter = cast(filters);
      if (id) {
        loadAllPaidKeywords(id, toJS(self.paidKeywordsFilter)?.filter(i => i?.active)?.concat(self.paidKeywordsAdditionalFilter));
      }
    };

    const updateOrganicPositionsFilter = (filters, id) => {
      self.organicPositionChangesParams.page = 1;
      self.organicPositionsFilter = cast(filters);
      if (id) {
        loadOrganicPositionChanges(id, filters?.filter(z => z?.active)?.concat(self.organicPositionsAdditionalFilter));
      }
    };

    const updateOrganicPositionsFilterV2 = (filters, id) => {
      self.organicPositionChangesParams.page = 1;
      self.organicPositionsFilter = cast(filters);
      if (id) {
        loadOrganicPositionChangesV2(id, filters?.filter(z => z?.active)?.concat(self.organicPositionsAdditionalFilter));
      }
    };
    const updateOrganicPositionsAdditionalFilter = (filters, id) => {
      self.organicPositionChangesParams.page = 1;
      self.organicPositionsAdditionalFilter = cast(filters);
      if (id) {
        loadOrganicPositionChangesV2(id, self.organicPositionsFilter?.filter(z => z?.active)?.concat(self.organicPositionsAdditionalFilter));
      }
    };
    const updatePaidPositionsFilter = (filters, id) => {
      self.allPaidPositionChangesParams.page = 1;
      self.paidPositionsFilter = cast(filters);
      if (id) {
        getPaidPositionChanges(id, filters?.filter(z => z?.active)?.concat(self.paidPositionsAdditionalFilter));
      }
    };
    const updatePaidPositionsAdditionalFilter = (filters, id) => {
      self.allPaidPositionChangesParams.page = 1;
      self.paidPositionsAdditionalFilter = cast(filters);
      if (id) {
        getPaidPositionChanges(id, self.paidPositionsFilter?.filter(z => z?.active)?.concat(self.paidPositionsAdditionalFilter));
      }
    };

    const updateOrganicPagesFilter = (filters, id) => {
      self.allOrganicPagesParams.page = 1;
      self.organicPagesFilter = cast(filters);
      if (id) {
        loadOrganicPages(id, self.organicPagesFilter?.filter(z => z?.active)?.concat(self.organicPagesAdditionalFilter));
      }
    };
    const updateOrganicPagesAdditionalFilter = (filters, id) => {
      self.allOrganicPagesParams.page = 1;
      self.organicPagesAdditionalFilter = cast(filters);
      if (id) {
        loadOrganicPages(id, self.organicPagesFilter?.filter(z => z?.active)?.concat(self.organicPagesAdditionalFilter));
      }
    };
    const updatePaidPagesFilter = (filters, id) => {
      self.allPaidPagesParams.page = 1;
      self.paidPagesFilter = cast(filters);
      if (id) {
        loadPaidPages(id, filters?.filter(z => z?.active)?.concat(self.paidPagesAdditionalFilter));
      }
    };
    const updatePaidPagesAdditionalFilter = (filters, id) => {
      self.allPaidPagesParams.page = 1;
      self.paidPagesAdditionalFilter = cast(filters);
      if (id) {
        loadPaidPages(id, self.paidPagesFilter?.filter(z => z?.active)?.concat(self.paidPagesAdditionalFilter));
      }
    };

    const updateAdsCopyFilter = (filters, id) => {
      self.allIndexedPagesParams.page = 1;
      self.adsCopyFilter = cast(filters);
      if (id) {
        loadGooglePageData(id, 'adwords_copies', filters?.filter(z => z?.active)?.concat(self.adsCopyAdditionalFilter));
      }
    };
    const updateAdsCopyAdditionalFilter = (filters, id) => {
      self.allIndexedPagesParams.page = 1;
      self.adsCopyAdditionalFilter = cast(filters);
      if (id) {
        loadGooglePageData(id, 'adwords_copies', self.adsCopyFilter?.filter(z => z?.active)?.concat(self.adsCopyAdditionalFilter));
      }
    };
    const updateAdsHistoryFilter = (filters, id) => {
      self.allGoogleHistoryParams.page = 1;
      self.adsHistoryFilter = cast(filters);
      if (id) {
        loadGoogleHistoryData(id, 'adwords_adhistory', filters?.filter(z => z?.active)?.concat(self.adsHistoryAdditionalFilter));
      }
    };
    const updateAdsHistoryAdditionalFilter = (filters, id) => {
      self.allGoogleHistoryParams.page = 1;
      self.adsHistoryAdditionalFilter = cast(filters);
      if (id) {
        loadGoogleHistoryData(id, 'adwords_adhistory', self.adsHistoryFilter?.filter(z => z?.active)?.concat(self.adsHistoryAdditionalFilter));
      }
    };

    const updateBacklinksFilter = (filters, id, sortFilters, secondSortFilters) => {
      self.allBacklinksParams.page = 1;
      self.backlinksFilter = cast(filters);
      if (id) {
        loadBacklinksData(id, 'backlinks', filters?.filter(z => z?.active)?.concat(self.backlinksAdditionalFilter), sortFilters, secondSortFilters);
      }
    };
    const updateBacklinksAdditionalFilter = (filters, id, sortFilters, secondSortFilters) => {
      self.allBacklinksParams.page = 1;
      self.backlinksAdditionalFilter = cast(filters);
      if (id) {
        loadBacklinksData(id, 'backlinks', self.backlinksFilter?.filter(z => z?.active)?.concat(self.backlinksAdditionalFilter), sortFilters, secondSortFilters);
      }
    };
    const updateRefDomainsFilter = (filters, id, sortFilters) => {
      self.allRefDomainsParams.page = 1;
      self.refDomainsFilter = cast(filters);
      if (id) {
        loadReferringDomainData(id, 'refdomains', filters?.filter(z => z?.active)?.concat(self.refDomainsAdditionalFilter), sortFilters);
      }
    };
    const updateRefDomainsAdditionalFilter = (filters, id, sortFilters) => {
      self.allRefDomainsParams.page = 1;
      self.refDomainsAdditionalFilter = cast(filters);
      if (id) {
        loadReferringDomainData(id, 'refdomains', self.refDomainsFilter?.filter(z => z?.active)?.concat(self.refDomainsAdditionalFilter), sortFilters);
      }
    };
    const updateOutDomainsFilter = (filters, id, noApiCall?: boolean) => {
      self.allOutDomainsParams.page = 1;
      self.outDomainsFilter = cast(filters);
      if (id && !noApiCall) {
        loadOutboundDomainData(id, 'outgoing_domains', filters?.filter(z => z?.active));
      }
    };
    const updateOutLinksFilter = (id, noApiCall?: boolean) => {
      self.allOutLinksParams.page = 1;
      if (id && !noApiCall) {
        loadOutboundLinksData(id, 'outgoing_links');
      }
    };
    const updateAnchorsFilter = (filters, id) => {
      self.allAnchorsParams.page = 1;
      self.anchorsFilter = cast(filters);
      if (id) {
        loadAnchors(id, 'anchors', filters?.filter(z => z?.active));
      }
    };

    const resetAllFilters = () => {
      self.allOrganicKeywordsParams = cast(DEFAULT_PARAMS);
      self.allPaidKeywordsParams = cast(DEFAULT_PARAMS);
      self.allOrganicPagesParams = cast(DEFAULT_PARAMS);
      self.allPaidPagesParams = cast(DEFAULT_PARAMS);
      self.allGoogleHistoryParams = cast(DEFAULT_PARAMS);
      self.allBacklinksParams = cast(DEFAULT_PARAMS);
      self.allIndexedPagesParams = cast(DEFAULT_PARAMS);
      self.allRefDomainsParams = cast(DEFAULT_PARAMS);
      self.allOutDomainsParams = cast(DEFAULT_PARAMS);
      self.allOutLinksParams = cast(DEFAULT_PARAMS);
      self.allRefIpsParams = cast(DEFAULT_PARAMS);
      self.allAnchorsParams = cast(DEFAULT_PARAMS);
      self.organicPositionChangesParams = cast(DEFAULT_PARAMS_POSITION);
      self.allPaidPositionChangesParams = cast(DEFAULT_PARAMS_POSITION);
      self.allPaidCompetitorParams = cast(DEFAULT_PARAMS);
      self.allOrganicCompetitorParams = cast(DEFAULT_PARAMS);
      self.allOrganicSubdomainsParams = cast(DEFAULT_PARAMS);
      self.allPaidSubdomainsParams = cast(DEFAULT_PARAMS);
      self.allAdsCopiesParams = cast(DEFAULT_PARAMS);
      self.organicKeywordsFilter = cast(ORGANIC_KEYWORD_FILTER);
      self.organicKeywordsAdditionalFilter = cast([]);
      self.paidKeywordsFilter = cast(PAID_KEYWORD_FILTER);
      self.paidKeywordsAdditionalFilter = cast([]);
      self.organicPositionsFilter = cast(ORGANIC_POSITIONS_FILTER);
      self.organicPositionsAdditionalFilter = cast([]);
      self.paidPositionsFilter = cast(PAID_POSITIONS_FILTER);
      self.paidPositionsAdditionalFilter = cast([]);
      self.organicPagesFilter = cast(ORGANIC_PAGES_FILTER);
      self.organicPagesAdditionalFilter = cast([]);
      self.paidPagesFilter = cast(PAID_PAGES_FILTER);
      self.paidPagesAdditionalFilter = cast([]);
      self.adsCopyFilter = cast(ADS_COPY_FILTER);
      self.adsCopyAdditionalFilter = cast([]);
      self.adsHistoryFilter = cast(ADS_HISTORY_FILTER);
      self.adsHistoryAdditionalFilter = cast([]);
      self.refDomainsFilter = cast(REF_DOMAINS_FILTER);
      self.refDomainsAdditionalFilter = cast([]);
      self.outDomainsFilter = cast(OUT_DOMAINS_FILTER);
      self.backlinksFilter = cast(BACKLINKS_FILTER);
      self.backlinksAdditionalFilter = cast([]);
      self.anchorsFilter = cast(ANCHORS_FILTER);
      self.backlinksSortFilter = cast([]);
      self.refDomainsSortFilter = cast([]);
    };

    const exportRecords = flow(function* (id, context, rowsData, filters, params) {
      self.isExporting = true;
      let extraSortParams = [];
      if (context == 'backlinks') {
        extraSortParams = toJS(self.backlinksSortFilter);
      } else if (context == 'refdomains') {
        extraSortParams = toJS(self.refDomainsSortFilter);
      }
      let allParams = `?context=${context}&export=1&export_count=${rowsData?.rows}&export_type=${rowsData?.type}${filters?.length ? `&filter=${formatFilters(filters)?.join('&filter=')}` :
        ''}&page=${params?.page}&page_size=${params?.page_size}${params?.changes_type ? `&changes_type=${params?.changes_type}` : ''}${params?.search ? `&search=${params?.search}` : ''}${extraSortParams?.length ? `&filter=${formatFilters(extraSortParams)?.join('&filter=')}` : ''}`;
      try {
        if (context == 'backlinks') {
          allParams += `&url=${params?.url}`;
        }
        if (self.siteExplorerDateStr) {
          allParams += `&date_str=${self.siteExplorerDateStr}`;
        }
        if (self.positionParams && context === 'organic_position_changes_v2') {
          Object.keys(self.positionParams).forEach(key => {
            if (key !== 'date_key') allParams += `&${key}=${self.positionParams[key]}`;
          });
        }
        yield downloadExportFile(`${getApiUrl(BaseApi.KEYWORD_ENDPOINT, `/api/v2/competitor-research/${id}/view-more/${allParams}`)}`);
      } catch {
        stopExporting();
      } finally {
        stopExporting();
      }
    });
    const stopExporting = () => {
      self.isExporting = false;
    };
    const setSiteExplorerDateStr = value => {
      self.siteExplorerDateStr = value;
    };
    const setSiteExplorerCountry = val => {
      self.siteExplorerCountry = val;
    };
    const setOpenBacklinksDrawer = (open, url) => {
      self.openBacklinksDrawer = open;
      self.backlinksDrawerUrl = url;
    };
    const setOutDomainsVisible = (open, domain: string) => {
      self.outDomainsVisible = open;
      self.outDomainsDomain = domain;
    };

    const setPositionParams = val => {
      self.positionParams = val;
    };
    return {
      loadCompetitorResearcherDetail,
      loadAdTrendData,
      setSiteExplorerCountry,
      setSiteExplorerDateStr,
      recrawlCompetitorResearcher,
      loadIndexedPagesData,
      loadReferringDomainData,
      loadNetworkGraphData,
      loadReferringIps,
      loadAnchors,
      setActiveMetricsKey,
      loadBacklinksData,
      getKeywordsFilteredDataIndividualURL,
      activeMainLoader,
      recrawlCompetitorResearcherDetail,
      loadOrganicSubDomains,
      loadPaidSubDomains,
      loadGooglePageData,
      loadOrganicPages,
      loadPaidPages,
      loadOrganicPositionChanges,
      loadOrganicPositionChangesV2,
      loadExtendedPaidChartData,
      getPaidPositionChanges,
      getCompetitorResearcherData,
      loadExtendedChartData,
      setAllOrganicPagesParams,
      loadExtendedBacklinksChartData,
      setAllOrganicSubdomainsParams,
      setAllPaidSubdomainsParams,
      // getRankingKeywordsFilteredData,
      setDetailApiCall,
      loadGoogleHistoryData,
      loadAllOrganicKeywords,
      loadOrganicCompetitors,
      loadPaidCompetitors,
      loadGoogleAdsKeywordData,
      loadAllPaidKeywords,
      setAllAdsCopiesParams,
      setActiveTab,
      clearAllOrganicCompetitors,
      setTabCount,
      setIndividualUrlDrawer,
      setAllBacklinksParams,
      setIndexedPagesParams,
      setRefDomainsParams,
      setOutDomainsParams,
      setOutLinksParams,
      setRefIpsParams,
      setAnchorsParams,
      // getIndividualCategoryData,
      setShowExportModal,
      setLoadingDetail,
      setLoadingBacklinksDetail,
      setOpenBacklinksDrawer,
      resetNetworkGraphData,
      setOutDomainsVisible,
      setAllOrganicKeywordsParams,
      setAllPaidKeywordsParams,
      setAllPaidPagesParams,
      setAllGoogleHistoryParams,
      setOrganicPositionChangesParams,
      setOrganicPositionChangesParamsV2,
      setAllPaidPositionChangesParams,
      setAllPaidCompetitorParams,
      setAllOrganicCompetitorParams,
      updateOrganicKeywordsFilter,
      updateOrganicKeywordsAdditionalFilter,
      updatePaidKeywordsFilter,
      updatePaidKeywordsAdditionalFilter,
      updateOrganicPositionsFilter,
      updateOrganicPositionsFilterV2,
      updateOrganicPositionsAdditionalFilter,
      updatePaidPositionsFilter,
      updatePaidPositionsAdditionalFilter,
      updateOrganicPagesFilter,
      updateOrganicPagesAdditionalFilter,
      updatePaidPagesFilter,
      updatePaidPagesAdditionalFilter,
      updateAdsCopyFilter,
      updateAdsCopyAdditionalFilter,
      updateAdsHistoryFilter,
      updateAdsHistoryAdditionalFilter,
      updateBacklinksFilter,
      updateBacklinksAdditionalFilter,
      updateRefDomainsFilter,
      updateRefDomainsAdditionalFilter,
      updateOutDomainsFilter,
      updateOutLinksFilter,
      updateAnchorsFilter,
      exportRecords,
      resetAllFilters,
      setPositionParams,
    };
  });

export type OverviewStoreInstance = Instance<typeof OverviewStore>;

export const initOverviewStore = () => {
  return {
    loadingDetail: true,
    openBacklinksDrawer: false,
    outDomainsVisible: false,
    backlinksDrawerUrl: '',
    outDomainsDomain: '',
    loadingDetailTags: true,
    loadingDrawerData: false,
    individualUrlDrawer: false,
    loadingSubDomainsDetail: true,
    loadingRankingKeywordsDetail: false,
    loadingRelevantPagesDetail: true,
    loadingOrganicCompetitorsDetail: true,
    isExporting: false,
    data: [],
    activeTab: 'overview',
    activeMetricsKey: 'organic',
    individualUrlDrawerUrl: '',
    individualUrlDrawerPage: '',
    filteredCountRankingKeyword: 0,
    isError: false,
    showExportModal: false,
    allRankingKeywordsCount: 0,
    countAllRelevantPages: 0,
    countAllSubDomains: 0,
    countAllCategories: 0,
    tabCount: 0,
    allOrganicKeywordsParams: DEFAULT_PARAMS,
    allPaidKeywordsParams: DEFAULT_PARAMS,
    allOrganicPagesParams: DEFAULT_PARAMS,
    allPaidPagesParams: DEFAULT_PARAMS,
    allGoogleHistoryParams: DEFAULT_PARAMS,
    organicPositionChangesParams: DEFAULT_PARAMS_POSITION,
    allPaidPositionChangesParams: DEFAULT_PARAMS_POSITION,
    allPaidCompetitorParams: DEFAULT_PARAMS,
    allOrganicCompetitorParams: DEFAULT_PARAMS,
    allOrganicSubdomainsParams: DEFAULT_PARAMS,
    allPaidSubdomainsParams: DEFAULT_PARAMS,
    allBacklinksParams: DEFAULT_PARAMS,
    allIndexedPagesParams: DEFAULT_PARAMS,
    allRefDomainsParams: DEFAULT_PARAMS,
    allOutDomainsParams: DEFAULT_PARAMS,
    allOutLinksParams: DEFAULT_PARAMS,
    allRefIpsParams: DEFAULT_PARAMS,
    allAnchorsParams: DEFAULT_PARAMS,
    allAdsCopiesParams: DEFAULT_PARAMS,
    organicKeywordsFilter: ORGANIC_KEYWORD_FILTER,
    organicKeywordsAdditionalFilter: [],
    paidKeywordsFilter: PAID_KEYWORD_FILTER,
    organicPositionsFilter: ORGANIC_POSITIONS_FILTER,
    organicPositionsAdditionalFilter: [],
    paidPositionsFilter: PAID_POSITIONS_FILTER,
    paidPositionsAdditionalFilter: [],
    organicPagesFilter: ORGANIC_PAGES_FILTER,
    organicPagesAdditionalFilter: [],
    paidPagesFilter: PAID_PAGES_FILTER,
    paidPagesAdditionalFilter: [],
    adsCopyFilter: ADS_COPY_FILTER,
    adsCopyAdditionalFilter: [],
    adsHistoryFilter: ADS_HISTORY_FILTER,
    adsHistoryAdditionalFilter: [],
    backlinksFilter: BACKLINKS_FILTER,
    backlinksAdditionalFilter: [],
    refDomainsFilter: REF_DOMAINS_FILTER,
    refDomainsAdditionalFilter: [],
    outDomainsFilter: OUT_DOMAINS_FILTER,
    anchorsFilter: ANCHORS_FILTER,
    siteExplorerDateStr: '',
    siteExplorerCountry: '',
    positionParams: null,
    isSiteExplorerDeleted: false,
    projectDeleted: false,
  };
};
