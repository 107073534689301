import {observer} from 'mobx-react';
import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {SiteAuditWrapper} from '..';
import {Collapse, Form, Input, Radio, Select, Slider, Spin, Tooltip} from 'antd';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCaretDown, faImageSlash} from '@fortawesome/pro-solid-svg-icons';
import {urlValidationRegex} from '@/constants/regex';
import styled from 'styled-components';
import styles from '../styles.module.scss';
import {useRouter} from 'next/router';
import {useStore} from '@/store/root-store';
import {addProtocolToDomainHttps, getDomain} from '@/utils/url';
import {toJS} from 'mobx';
import {LoadingOutlined} from '@ant-design/icons';
import {debounce} from 'lodash';
import {faArrowLeft, faCheck, faTrash} from '@fortawesome/pro-regular-svg-icons';
import {SkeletonRingLoader} from '@/components/common-components';
import {faSquareInfo} from '@fortawesome/pro-duotone-svg-icons';
import {LargeBadge} from '@/components/common-components/components/badge';
import {UrlExclusionSection} from '../../site-audit-v2/common/CrawlSettingModal';
import {PRO_PLAN_ID} from '@/constants';
import {faLock} from '@fortawesome/pro-solid-svg-icons';
import {SwitchStyled} from '../Component/styledComponents';

const {Option} = Select;
interface Props {
  existSiteAudit: string;
  setExistSiteAudit: Dispatch<SetStateAction<string>>;
  setInputVal: Dispatch<SetStateAction<string>>;
  form?: any;
  userAgent: string;
  crawlBudgetVal: number;
  pagesToCrawlError: boolean;
  crawlSpeedVal: number;
  onCrawlSpeedChange: (value: any) => void;
  crawlSpeedError: boolean;
  crawlSelectOption: string;
  crawlCriteria: boolean;
  setCrawlSelectOption: Dispatch<SetStateAction<string>>;
  crawlFrequency: string;
  inputValue: string;
  setInputValue: Dispatch<SetStateAction<string>>;
  setWidgetStatus: Dispatch<SetStateAction<number>>;
  selectedSite: number;
  setSelectedSite: Dispatch<SetStateAction<number>>;
  setUserAgent: Dispatch<SetStateAction<string>>;
  setCrawlBudgetVal: Dispatch<SetStateAction<number>>;
  setCrawlCriteria: Dispatch<SetStateAction<boolean>>;
  setPagesToCrawlError: Dispatch<SetStateAction<boolean>>;
  setCrawlSpeedVal: Dispatch<SetStateAction<number>>;
  setCrawlSpeedError: Dispatch<SetStateAction<boolean>>;
  setCrawlFrequency: Dispatch<SetStateAction<string>>;
  setFrequencyError: Dispatch<SetStateAction<boolean>>;
  domainError: {
    message: string;
    errorState: boolean;
  };
  setDomainError: Dispatch<SetStateAction<{
    message: string;
    errorState: boolean;
  }>>;
  openInOtto: (value: any) => void;
  conditions?: {filter: string; type: string; text: string}[];
  setConditions?: React.Dispatch<React.SetStateAction<{filter: string; type: string; text: string}[]>>;
  customCrawlError: boolean;
}
export const StepOne: React.FC<Props> = observer((
  {
    existSiteAudit,
    setExistSiteAudit,
    setInputVal,
    form,
    userAgent,
    crawlBudgetVal,
    pagesToCrawlError,
    crawlSpeedVal,
    onCrawlSpeedChange,
    crawlSpeedError,
    crawlSelectOption,
    setCrawlSelectOption,
    crawlFrequency,
    crawlCriteria,
    inputValue,
    setInputValue,
    setWidgetStatus,
    selectedSite,
    setSelectedSite,
    setUserAgent,
    setCrawlBudgetVal,
    setPagesToCrawlError,
    setCrawlSpeedVal,
    setCrawlSpeedError,
    setCrawlFrequency,
    setFrequencyError,
    setCrawlCriteria,
    domainError,
    setDomainError,
    openInOtto,
    conditions,
    setConditions,
    customCrawlError,
  }) => {
  const {
    siteAuditorV2: {auditedSitesList, auditedSitesListCount, loadAuditedSitesList, params, setParams},
    plans: {
      showSidebarPaymentDrawer,
    },
    settings: {customer: {profile: {whitelabelOtto, planName, plan}}},
  } = useStore('');
  const [isActive, setIsActive] = useState(null);
  const router = useRouter();
  const debounceUpdate = debounce(() => loadAuditedSitesList(true, null, null, false), 1000);
  const isPublicPage = router?.pathname?.includes('public');
  const [loadMore, setLoadMore] = useState(false);
  const excludeCondition = (conditions?.filter(condition => condition?.type === 'Disallow')?.length > 1);
  const handleLoadMore = () => {
    if (!loadMore) {
      setLoadMore(true);
      setParams({
        ...params,
        page: params?.page + 1,
      }, true, true);
      loadAuditedSitesList(true, null, null, false, setLoadMore);
    }
  };
  const onUserAgentChange = (value: any) => {
    setUserAgent(value);
  };
  const onCrawlBudgetChange = (value: any) => {
    if (!isPublicPage) {
      setCrawlBudgetVal(value);
    }
  };
  const onCrawlBudgetInputChange = (value: any) => {
    const val = Number(value.target.value);
    if (val < 10001 && planName != 'Free') {
      setCrawlBudgetVal(val);
    } else if (val < 501 && planName == 'Free') {
      setCrawlBudgetVal(val);
    }
    if (val == 0) {
      setPagesToCrawlError(true);
    } else {
      setPagesToCrawlError(false);
    }
  };
  const onCrawlSpeedInputChange = (value: any) => {
    const val = Number(value.target.value);
    if (val < 51) {
      setCrawlSpeedVal(val);
    }
    if (val == 0) {
      setCrawlSpeedError(true);
    } else {
      setCrawlSpeedError(false);
    }
  };
  const onFrequencyChange = (value: any) => {
    const val = Number(value);
    if (val == 0) {
      setCrawlFrequency('');
      setFrequencyError(true);
    } else {
      setFrequencyError(false);
      if (val < 31 && val> -1) {
        setCrawlFrequency(val?.toString());
      }
    }
  };
  const onCriteriaChange = (value: boolean)=>{
    setCrawlCriteria(value);
  };
  const onSelectExisting = () => {
    setParams({
      ...params,
      page: 1,
      search: '',
    }, true, true);
    loadAuditedSitesList();
    setExistSiteAudit('existing-site');
    setFrequencyError(false);
  };
  const handleChange = event => {
    const value = event.target.value;
    setParams({
      ...params,
      page: params?.page + 1,
      search: value,
    }, true, true);
    debounceUpdate();
    setInputValue(value);
  };
  const onChangeDomain = e => {
    const value = e.target.value;
    setInputValue(value);
    if (value.match(urlValidationRegex)) {
      setDomainError({message: '', errorState: false});
    } else if (!value) {
      setDomainError({message: 'Domain is required', errorState: true});
    } else {
      setDomainError({message: 'Provide valid url like https://example.com', errorState: true});
    }
  };
  const handleConditionChange = (index: number, key: string, value: string) => {
    const updatedConditions = [...conditions];
    updatedConditions[index][key] = value;
    setConditions && setConditions(updatedConditions);
  };
  const handleAddCondition = () => {
    if (conditions?.length) {
      setConditions && setConditions([...conditions, {type: 'Disallow', filter: 'contains', text: ''}]);
    } else {
      setConditions && setConditions([{type: 'Disallow', filter: 'contains', text: ''}]);
    }
  };
  const handleDeleteCondition = (index: number) => {
    const updatedConditions = conditions.filter((_, i) => i !== index);
    const excludeCondition = (conditions?.filter(condition => condition?.type === 'Disallow')?.length > 1);
    if (updatedConditions?.length && !excludeCondition) {
      const excludeUpdatedCondition = [...updatedConditions];
      excludeUpdatedCondition[0] = {...excludeUpdatedCondition[0], type: 'Disallow'};
      setConditions && setConditions(excludeUpdatedCondition);
    } else {
      setConditions && setConditions(updatedConditions);
    }
  };
  const getDailyOptionLabel = () => {
    return plan !== PRO_PLAN_ID ? (
      <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <div style={{display: 'flex', gap: 6, alignItems: 'center'}}>
          Daily
          <div className='lockIcon' style={{backgroundColor: '#ededed', borderRadius: 50, fontSize: 12, paddingInline: 8, display: 'flex', alignItems: 'center', gap: 4, color: '#121212'}}>
            <FontAwesomeIcon icon={faLock} fontSize={12} />
            Pro only
          </div>
        </div>
        <UpgradeButton className='upgradeButton' type='button' onClick={() => showSidebarPaymentDrawer()}>Upgrade</UpgradeButton>
      </div>
    ) : 'Daily';
  };

  useEffect(()=>{
    if (existSiteAudit === 'new-site') {
      setCrawlCriteria(true);
    }
  }, [existSiteAudit]);

  return ( <>
    {existSiteAudit === 'new-site' ?
      <SiteAuditWrapper>
        <div className='existing-site' onClick={onSelectExisting}>
          <FontAwesomeIcon icon={faArrowLeft} fontSize={12} color='#2D6CCA'/>Select existing Site Audit</div>
        <Form.Item style={{marginBottom: 0, alignItems: 'center'}} name='domain_url'>
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <p style={{fontSize: 12, margin: '0px', color: '#4E5156'}}>Domain URL</p>
            <RoundInput
              style={{width: '260px'}}
              defaultValue={inputValue}
              // disabled
              onChange={onChangeDomain}
            />
          </div>
        </Form.Item>
        {domainError.errorState && <div style={{color: '#FF0000', fontSize: 10, marginLeft: 252, marginBottom: 8}}>{domainError.message}</div>}

        <Form.Item name='crawl_budget' style={isPublicPage ? {pointerEvents: 'none', filter: 'opacity(0.5)', marginBottom: 8} : {marginBottom: 8}}>
          <div style={{display: 'flex', justifyContent: 'space-between', height: 25, alignItems: 'center'}}>
            <p style={{fontSize: 12, color: '#4E5156', margin: 0}}>Pages to Crawl</p>
            <StyledSlider
              style={{width: 250, marginTop: 0, marginBottom: 0, borderRadius: '10px'}}
              value={crawlBudgetVal}
              trackStyle={{backgroundColor: '#2D6CCA', height: 8}}
              defaultValue={crawlBudgetVal}
              max={planName == 'Free' ? 500 : 10000}
              min={1}
              onChange={onCrawlBudgetChange}
            />
          </div>
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
            <div style={{display: 'flex', alignItems: 'center', width: '260px'}}>
              <span style={{marginTop: 0, fontSize: 12, color: '#4E5156'}}>Top </span>
              <span>
                <div style={{width: '65px', marginLeft: 10, marginRight: 8}}>
                  <RoundInput
                    style={{
                      padding: '2px 11px',
                      border: pagesToCrawlError ? '1px solid #FF0000' : '',
                      boxShadow: pagesToCrawlError ? '0 0 0 1px #FF0000' : '',
                    }}
                    tabIndex={-1}
                    onChange={onCrawlBudgetInputChange}
                    value={crawlBudgetVal}/>
                </div>
              </span>
              <span style={{fontSize: 12, marginTop: 0, color: '#4E5156'}}>pages</span>
            </div>
            {pagesToCrawlError && <div style={{color: 'red', marginLeft: '143px', fontSize: '12px', marginTop: '10px'}}>Crawl value should be greater than 0</div>}
          </div>
        </Form.Item>
        <Form.Item name='crawl_frequency' style={isPublicPage ? {pointerEvents: 'none', filter: 'opacity(0.5)', marginBottom: 8} : {marginBottom: 8}}>
          <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <p style={{fontSize: 12, margin: 0, color: '#4E5156'}}>Crawl Frequency</p>
            <CrawlFrequencySelect
              style={{width: 260, marginTop: 0}}
              className={styles.selectStyle}
              defaultValue={crawlSelectOption}
              showSearch
              disabled={isPublicPage}
              tabIndex={-1}
              placeholder='Select Crawl Frequency'
              optionFilterProp='children'
              onChange={(value: string) => {
                setCrawlSelectOption(value);
              }}
              value={isPublicPage ? `Daily (Registered only)` : crawlSelectOption}
            >
              <Option value='daily' disabled={plan !== PRO_PLAN_ID}><Tooltip title={plan !== PRO_PLAN_ID ? 'Available in PRO plan' : ''}>{getDailyOptionLabel()}</Tooltip></Option>
              <Option value='weekly'>Weekly</Option>
              <Option value='monthly'>Monthly</Option>
              <Option value='Custom'>Custom</Option>
              <Option value='never'>Never</Option>
            </CrawlFrequencySelect>
          </div>
          {crawlSelectOption == 'Custom' &&
            <div style={{display: 'flex', marginLeft: 143, marginTop: 10}}>
              <span style={{marginTop: 4, fontSize: 12, color: '#4E5156'}}>Every  </span>
              <span>
                <div style={{width: '65px', marginLeft: 10, marginRight: 10}}>
                  <RoundInput
                    crawlFrequency ={crawlFrequency}
                    value={crawlFrequency}
                    onChange={e=>onFrequencyChange(e?.target?.value)}
                  />
                </div>
              </span>
              <span style={{fontSize: 12, marginTop: 4, color: '#4E5156'}}>Days</span>
            </div>
          }
          {customCrawlError && <div style={{color: 'red', marginLeft: 90}}>The crawl frequency must be at least 7 days.</div>}

        </Form.Item>
        <Collapse
          ghost
          accordion
          expandIconPosition='right'
          activeKey={isActive}
          onChange={key => setIsActive(key)}
          // expandIcon={({isActive}) => isActive ? <ChevronUp/>:<ChevronDown/>}
        >
          <Collapse.Panel header={<><span className='span' style={{color: '#2D6CCA'}}>{isActive ? 'Show less' : 'Show more'}</span></>} key={'1'}>
            <Form.Item style={{marginBottom: 8, alignItems: 'center'}} name='selected_user_agent' >
              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <p style={{fontSize: 12, margin: '0px', color: '#4E5156'}}>User Agent</p>
                <StyledSelect
                  style={{width: 260, marginTop: 0, borderRadius: 6}}
                  className={styles.selectStyle}
                  showSearch
                  value={userAgent}
                  placeholder='Select user agent'
                  optionFilterProp='children'
                  onChange={onUserAgentChange}
                  filterOption={(input, option) =>
                    (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                  }
                >
                  <Option value='google_chrome_desktop'>Google Chrome Desktop</Option>
                  <Option value='google_chrome_mobile'>Google Chrome Mobile</Option>
                  <Option value='googlebot_desktop'>Googlebot Desktop</Option>
                  <Option value='googlebot_mobile'>Googlebot Mobile</Option>
                  <Option value='bingbot_desktop'>Bingbot Desktop</Option>
                  <Option value='bingbot_mobile'>Bingbot Mobile</Option>
                  <Option value='slurp'>Slurp</Option>
                  <Option value='yandexbot'>Yandexbot</Option>
                  <Option value='baiduspider'>Baiduspider</Option>
                  <Option value='screaming_frog'>Screaming frog</Option>
                  <Option value='duckduckgo'>Duckduckgo</Option>
                  <Option value='searchatlas'>Searchatlas</Option>
                </StyledSelect>
              </div>
            </Form.Item>
            <Form.Item style={{marginBottom: '10px', marginTop: '10px'}}>
              <CrawlCriteria>
                <p style={{fontSize: 12, color: '#121212', marginBottom: 0}}>Crawl Criteria</p>
                <div style={{width: '248px', height: 25, marginLeft: 10, marginRight: 10, display: 'flex', gap: '10px', alignItems: 'center'}}>
                  <SwitchStyled checked={crawlCriteria} onChange={checked=>{
                    onCriteriaChange(checked);
                  }}/>
                  {crawlCriteria ? 'Respect' : 'Ignore'} robots.txt
                </div>
              </CrawlCriteria>
            </Form.Item>
            <Form.Item name='crawl_concurrency'
              rules={[
                {
                  pattern: /^(?:\d*)$/,
                  message: 'Value should contain just number',
                },
                {
                  pattern: /^[\d]{0,50}$/,
                  message: 'Value should be less than 50 character',
                },
              ]}
              style={{marginBottom: 8}}
            >
              <div style={{display: 'flex', justifyContent: 'space-between', height: 25, alignItems: 'center'}}>
                <div style={{display: 'flex', alignItems: 'center', gap: '8px'}}>
                  <p style={{fontSize: 12, color: '#4E5156', margin: 0}}>Crawl Speed</p>
                  <Tooltip
                    overlayInnerStyle={{padding: '10px', backgroundColor: '#121212', borderRadius: '12px'}}
                    title={'Increase speed if you have large site for quick page crawls'}>
                    <FontAwesomeIcon icon={faSquareInfo} color='#A3A4A4' />
                  </Tooltip>
                </div>
                <StyledSlider
                  style={{width: 250, marginTop: 0, marginBottom: 0, borderRadius: '10px'}}
                  value={crawlSpeedVal}
                  trackStyle={{backgroundColor: '#2D6CCA', height: 8}}
                  defaultValue={crawlSpeedVal}
                  max={50}
                  min={1}
                  onChange={onCrawlSpeedChange}
                />
              </div>
              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                <div style={{display: 'flex', alignItems: 'center', width: '260px'}}>
                  <span style={{marginTop: 0, fontSize: 12, width: '110px', color: '#4E5156'}}>Pages per second</span>
                  <span style={{width: '40px'}}><div>
                    <RoundInput
                      style={{
                        padding: '2px 11px',
                        border: crawlSpeedError ? '1px solid #FF0000' : '',
                        boxShadow: crawlSpeedError ? '0 0 0 1px #FF0000' : '',
                      }}
                      onChange={onCrawlSpeedInputChange}
                      value={crawlSpeedVal}
                    />
                  </div></span>
                </div>
                {crawlSpeedError && <div style={{color: 'red', marginLeft: '143px', fontSize: '12px', marginTop: '10px'}}>Crawl speed should be greater than 0</div>}
              </div>
            </Form.Item>
            <div>
              <div style={{fontSize: 12, marginBottom: 10, display: 'flex'}}>URL Exclusion conditions<LargeBadge beta/></div>
              {conditions?.map((condition, index) => (
                <UrlExclusionSection key={index} style={{display: 'flex', gap: 8}}>
                  <div key={index} className='condition-row'>
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', color: '#4E5156', gap: 8}}>
                      <div style={{display: 'flex', color: '#4E5156', gap: 8}}>
                        <div>#{index+1}</div>
                        <div>
                          <Select dropdownMatchSelectWidth={false}
                            getPopupContainer={node=> node}
                            value={condition.type}
                            className='condition-dropdown'
                            onChange={value => handleConditionChange(index, 'type', value)}
                            suffixIcon={<FontAwesomeIcon color='#4E5156' icon={faCaretDown}/>}
                          >
                            <Option id='testm' value='Disallow'>Exclude</Option>
                            <Option id='testm' value='Allow' disabled={!excludeCondition}>
                              <Tooltip title={!excludeCondition && 'Please an Exclude condition first'}>
                                    Include
                              </Tooltip>
                            </Option>
                          </Select>
                          <span style={{marginRight: 5}}>all URLs that</span>
                          <Select
                            getPopupContainer={node=> node}
                            dropdownMatchSelectWidth={false}
                            value={condition.filter}
                            className='condition-dropdown'
                            onChange={value => handleConditionChange(index, 'filter', value)}
                            suffixIcon={<FontAwesomeIcon color='#4E5156' icon={faCaretDown}/>}
                          >
                            <Option value='contains'>contain</Option>
                            <Option value='starts_with'>start with</Option>
                          </Select>
                          <Input
                            width={470}
                            placeholder='Type here e.g /blog/ ....'
                            className='condition-input'
                            value={condition.text}
                            onChange={e => handleConditionChange(index, 'text', (e.target.value)?.trim())}
                          />
                        </div>
                        <FontAwesomeIcon icon={faTrash} style={{fontSize: 14, color: '#A3A4A4', marginLeft: -25, marginTop: 2, cursor: 'pointer'}} onClick={() => handleDeleteCondition(index)}/>
                      </div>
                    </div>
                  </div>
                </UrlExclusionSection>
              ))}
              <div onClick={handleAddCondition} style={{color: '#2D6CCA', fontSize: 12, cursor: 'pointer'}}>
                    + Add condition
              </div></div>
          </Collapse.Panel>
        </Collapse>
      </SiteAuditWrapper> :
      <div style={{marginBottom: '20px'}}>
        <InputStyle
          value={inputValue}
          onChange={handleChange}
          // suffix={<FontAwesomeIcon color='#272727' icon={faCaretDown} />}
          placeholder={'Select a site or add a new one'}
        />
        {(inputValue && selectedSite === -1) && <NewSiteContainer>
          <div className='heading' onClick={() => {
            setIsActive(null);
            setExistSiteAudit('new-site');
            if (inputValue.match(urlValidationRegex)) {
              setDomainError({message: '', errorState: false});
            } else if (!inputValue) {
              setDomainError({message: 'Domain is required', errorState: true});
            } else {
              setDomainError({message: 'Provide valid url like https://example.com', errorState: true});
            }
            setInputVal(inputValue);
            form.setFieldsValue({
              siteproperty: inputValue,
            });
          }}>{`+ Click here to add “${inputValue}”`}</div>
          <div className='description'>or continue typing to search for an existing Site Audit</div>
        </NewSiteContainer>}
        <SiteWrapper>
          {auditedSitesList === null ? <SkeletonRingLoader/> : toJS(auditedSitesList)?.map((item, index) => {
            return (
              <>
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}} onClick={() => {
                  if (item.ottoProjectUuid) {
                    return;
                  }
                  setSelectedSite(selectedSite != item?.id ? item?.id : -1);
                  setInputValue(selectedSite != item?.id ? addProtocolToDomainHttps(item?.propertyUrl) : '');
                  setWidgetStatus(-1);
                }} className={`site ${selectedSite === item?.id ? 'selected' : ''}`}>
                  <div style={{display: 'flex', alignItems: 'center', gap: '8px', width: '100%'}}>
                    {item.ottoProjectUuid ? <FontAwesomeIcon icon={faCheck} fontSize={16} color='rgb(42, 193, 85)'/> : <StyledRadio checked={selectedSite === item?.id}/>}
                    <div style={{display: 'flex', alignItems: 'center', gap: '8px', width: '100%'}}>
                      {item?.propertyUrl ? <img className={styles.gscFavicon} src={`https://t1.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${item?.propertyUrl && getDomain((!/^(https?:)?\/\//i.test(item?.propertyUrl)) ? 'https:' + item?.propertyUrl : item?.propertyUrl)}&size=64`} /> :
                        <FontAwesomeIcon style={{fontSize: 55, color: '#A3A4A4'}} icon={faImageSlash} />}
                      <Tooltip title={item?.propertyUrl || '-'} overlayInnerStyle={{whiteSpace: 'nowrap', width: 'fit-content', background: '#000'}}>
                        <BoldText style={{color: (selectedSite === item?.id) ? '#FFFFFF' : '#121212', display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', maxWidth: '100%'}}>
                          <>
                            <div className='url'>{item.propertyUrl}</div>
                            {item.ottoProjectUuid ? <OpenInOtto onClick={() => openInOtto(item)}>{`Open in ${whitelabelOtto}`}</OpenInOtto> : null}
                          </>
                        </BoldText>
                      </Tooltip>
                    </div>
                  </div>
                  {selectedSite === item?.id ? <BoldText style={{color: '#FFFFFF'}}>Selected</BoldText> : <></>}
                </div>
                {(index === auditedSitesList.length - 1) && (auditedSitesListCount > auditedSitesList?.length && (
                  <div style={{textAlign: 'center', color: 'green', cursor: `${loadMore ? 'not-allowed' : 'pointer'}`, alignItems: 'center'}} onClick={handleLoadMore}>
                    Load more
                    {loadMore && <Spin indicator={<LoadingOutlined style={{fontSize: 12, marginLeft: 10, marginBottom: 10}} spin />} />}
                  </div>
                ))}
              </>
            );
          })}
        </SiteWrapper>
      </div>}
  </>);
});

const StyledSelect = styled(Select)`
.ant-select-selector {
  border-radius: 6px !important;
}

.ant-select-arrow {
  top: 40%;
}
  @media screen and (max-width: 390px) {
    .ant-select-arrow {
      top: 26%;
    } 
  }
`;
const StyledSlider = styled(Slider)`
  .ant-slider-handle {
    background-color: #2d6cca !important;
    border-color: #6c96d4 !important;
    border-width: 4px !important;
    height: 20px !important;
    width: 20px !important;
    margin-top: -7px;
  }
  .ant-slider-rail {
    height: 8px;
    border-radius: 10px;
    background-color: #ebebeb;
  }
  .ant-slider-track {
    border-radius: 10px;
  }
`;
const RoundInput = styled(Input)<{crawlFrequency?: string}>`
  border-radius: 5px;
  font-size: 12px;
  border: ${p => p.crawlFrequency=='' || p.crawlFrequency == '0' ? '1px solid #FF0000' : ''};
    box-shadow:   ${p => p.crawlFrequency=='' || p.crawlFrequency == '0' ? ' 0 0 0 1px #FF0000' : ''};
    &:focus {
      border: ${p => p.crawlFrequency=='' || p.crawlFrequency == '0' ? '1px solid #FF0000' : ''};
      box-shadow:   ${p => p.crawlFrequency=='' || p.crawlFrequency == '0' ? ' 0 0 0 1px #FF0000' : ''};
    }
    &:hover {
      border: ${p => p.crawlFrequency=='' || p.crawlFrequency == '0' ? '1px solid #FF0000' : ''};
      box-shadow:   ${p => p.crawlFrequency=='' || p.crawlFrequency == '0' ? ' 0 0 0 1px #FF0000' : ''};

    }
`;
const InputStyle = styled(Input)`
  max-width: 100%;
  width: 100%;
  height: 38px;
  border-radius: 12px;
  border: 1px solid #D9D9D9;
  background: var(--White, #FFF);
  box-shadow: 0px 3px 2px 0px rgba(0, 0, 0, 0.02);
  margin-bottom: 10px;
  &:hover {
   border: 1px solid #D9D9D9 !important;
  }
  &:focus {
  box-shadow: 0px 3px 2px 0px rgba(0, 0, 0, 0.02) !important;
  }
`;
const SiteWrapper = styled.div`
  height: 400px;
  overflow: auto;
  .site {
    background-color: #ffff;
    padding: 10px;
    border-radius: 8px;
    :hover {
      background-color: #d0d0d0;
    }
    &.selected {
      background-color: #2D6CCA;
      &:hover {
        background-color: #2D6CCA;
      }
    }
  }
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  ::-webkit-scrollbar-track {
    background: #F9F9FB;
  }

  ::-webkit-scrollbar-thumb {
    background: #4E515680;
    border-radius: 5px;
  }
`;
const StyledRadio = styled(Radio)`
  margin-right: 0px;
  .ant-radio {
    .ant-radio-inner {
      height: 18px !important;
      width: 18px !important;
    }
    .ant-radio-inner::after {
      width: 14px !important;
      height: 14px !important;
      margin-top: -7px !important;
      margin-left: -7px !important;
    }
  }
`;
const BoldText = styled.div`
  white-space: nowrap;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 400;
  .url {
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
const NewSiteContainer = styled.div`
  .heading {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #2D6CCA;
    margin-bottom: 10px;
    cursor: pointer;
  }
  .description {
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #4E5156;
    margin-bottom: 5px;
  }
`;
const OpenInOtto = styled.div`
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: #2D6CCA;
  cursor: pointer;
`;

const UpgradeButton = styled.button`
  outline: none;
  border: none;
  background: transparent;
  cursor: pointer;
  color: #2D6CCA;
`;

const CrawlFrequencySelect = styled(StyledSelect)`
  .lockIcon {
    color: #121212 !important;
  }
  .ant-select-selection-item {
    .upgradeButton, .lockIcon {
      display: none !important;
    }
  }
`;

const CrawlCriteria = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    
    .ant-switch-checked {
      background: #2e904e !important;
    }
`;
