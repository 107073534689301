/* eslint-disable max-len */
import {getApiUrl, getAuthHeader, getTokenFromCookies} from '@/api/common-utils';
import {BaseApi} from '@/api/base-api';

export class ReportBuilderApi extends BaseApi {
  private static readonly baseUrl: string = getApiUrl(BaseApi.GSC_ENDPOINT, '/analytics/api');
  private static readonly baseGSCUrl: string = getApiUrl(BaseApi.GSC_ENDPOINT, '/search-console/api');
  private static readonly baseBacklinksUrl: string = getApiUrl(BaseApi.KEYWORD_ENDPOINT, '/api/v2/competitor-research');
  private static readonly baseUrlKRT: string = getApiUrl(BaseApi.KEYWORD_ENDPOINT, '/api');
  private static readonly baseLGUrl: string = getApiUrl(BaseApi.LINKGRAPH_ENDPOINT, '/api');
  private static readonly baseGSCDisconnect: string = getApiUrl(BaseApi.GSC_ENDPOINT, '/analytics/api/v1');
  private static readonly baseNotion: string = getApiUrl(BaseApi.GSC_ENDPOINT, '/api/customer');
  private static readonly baseOtto: string = getApiUrl(BaseApi.SA_ENDPOINT, '/api/v2/');
  private static readonly gscBaseUrl: string = getApiUrl(BaseApi.GSC_ENDPOINT, '');


  public async getFacebookAdsTableData(payload) {
    try {
      const response = await this.axios.get(`${ReportBuilderApi.gscBaseUrl}/facebookads/api/v1/campaign/list/?start_date=${payload.startDate}&end_date=${payload.endDate}&page_size=10${payload.next_page ?
        `&next_page=${payload.next_page}` : ''}&ad_account_id=${payload.id}${payload?.public_share_hash ? `&public_share_hash=${payload?.public_share_hash}` : ''}`, {
        headers: getAuthHeader(),
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getGoogleAdsTableData(payload) {
    try {
      const response = await this.axios.get(`${ReportBuilderApi.gscBaseUrl}/googleads/api/v1/metrics/list/?customer_client_id=${payload.customerId}&segment_end_date=${payload.endDate}&service_type=${payload.type}${payload.next_page ?
        `&next_page_token=${payload.next_page}` : ''}&segment_start_date=${payload.startDate}${payload?.public_share_hash ? `&public_share_hash=${payload?.public_share_hash}` : ''}`, {
        // eslint-disable-next-line max-len
        headers: getAuthHeader(),
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getGoogleAdsMatrixData(payload) {
    try {
      const response = await this.axios.get(`${ReportBuilderApi.gscBaseUrl}/googleads/api/v1/metrics/aggregated-totals/?customer_client_id=${payload.customerId}&segment_end_date=${payload.endDate}&service_type=${payload.type}&segment_start_date=${payload.startDate}${payload?.public_share_hash ? `&public_share_hash=${payload?.public_share_hash}` : ''}`, {
        // eslint-disable-next-line max-len
        headers: getAuthHeader(),
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getGoogleAdsOverTimeData(payload) {
    try {
      // eslint-disable-next-line max-len
      const response = await this.axios.get(`${ReportBuilderApi.gscBaseUrl}/googleads/api/v1/metrics/groupedby-date/?date_interval=date&segment_start_date=${payload.startDate}&segment_end_date=${payload.endDate}&customer_client_id=${payload.customerId}&service_type=${payload.type}${payload?.public_share_hash ? `&public_share_hash=${payload?.public_share_hash}` : ''}`, {
        // eslint-disable-next-line max-len
        headers: getAuthHeader(),
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getGoogleAdsByCampaign(payload) {
    try {
      const response = await this.axios.get(`${ReportBuilderApi.gscBaseUrl}/googleads/api/v1/metrics/groupedby-service/?customer_client_id=${payload.customerId}&segment_end_date=${payload.endDate}&service_type=${payload.type}&segment_start_date=${payload.startDate}&sort_by=${payload.sortBy}&limit=${payload?.public_share_hash ? `&public_share_hash=${payload?.public_share_hash}` : ''}`, {
        // eslint-disable-next-line max-len
        headers: getAuthHeader(),
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async facebookAdDisconnect(email) {
    try {
      const response = await this.axios.post(`${ReportBuilderApi.gscBaseUrl}/facebookads/api/v1/user-ad-accounts/disconnect-account/`, email, {
        // eslint-disable-next-line max-len
        headers: getAuthHeader(),
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async googleAdDisconnect(email) {
    try {
      const response = await this.axios.post(`${ReportBuilderApi.gscBaseUrl}/googleads/api/v1/customer-ad-accounts/disconnect-ads-account/`, email, {
        // eslint-disable-next-line max-len
        headers: getAuthHeader(),
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getFacebookAdsAdset(payload) {
    try {
      // eslint-disable-next-line max-len
      const response = await this.axios.get(`${ReportBuilderApi.gscBaseUrl}/facebookads/api/v1/adset/list/?start_date=${payload.startDate}&end_date=${payload.endDate}&ad_account_id=${payload.id}&page_size=10${payload.next_page ? `&next_page=${payload.next_page}` : ''}&campaign_ids=${payload.campaign_ids}${payload?.public_share_hash ? `&public_share_hash=${payload?.public_share_hash}` : ''}`, {
        headers: getAuthHeader(),
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getFacebookAdsAdGender(payload) {
    try {
      // eslint-disable-next-line max-len
      const response = await this.axios.get(`${ReportBuilderApi.gscBaseUrl}/facebookads/api/v2/demographic-insights/?report_id=${payload.reportId}&start_date=${payload.startDate}&end_date=${payload.endDate}&ad_account_id=${payload.id}${payload.next_page ? `&next_page=${payload.next_page}` : ''}&page_size=10&demographic_type=${payload.type}${payload?.public_share_hash ? `&public_share_hash=${payload?.public_share_hash} ` : ''}`, {
        headers: getAuthHeader(),
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getFacebookAdsAds(payload) {
    try {
      const response = await this.axios.get(`${ReportBuilderApi.gscBaseUrl}/facebookads/api/v1/ads/list/?start_date=${payload.startDate}&end_date=${payload.endDate}&ad_account_id=${payload.id}&ad_set_ids=${payload.ad_set_ids}&page_size=15${payload?.public_share_hash ? `&public_share_hash=${payload?.public_share_hash}` : ''}`, {
        headers: getAuthHeader(),
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getFbUserAdAccount() {
    try {
      const response = await this.axios.get(`${ReportBuilderApi.gscBaseUrl}/facebookads/api/v1/customer-ad-accounts/accounts-list`, {
        // eslint-disable-next-line max-len
        headers: getAuthHeader(),
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getGoogleAdUserAdAccount() {
    try {
      const response = await this.axios.get(`${ReportBuilderApi.gscBaseUrl}/googleads/api/v1/customer-ad-accounts/accounts-list`, {
        // eslint-disable-next-line max-len
        headers: getAuthHeader(),
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getFbPublisherAccount(payload) {
    try {
      const response = await this.axios.get(`${ReportBuilderApi.gscBaseUrl}/facebookads/api/v1/campaign/publisher-platform/?start_date=${payload.startDate}&end_date=${payload.endDate}&ad_account_id=${payload?.id}`, {
        // eslint-disable-next-line max-len
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getFbLineChartAccount(payload) {
    try {
      const response = await this.axios.get(`${ReportBuilderApi.gscBaseUrl}/facebookads/api/v1/campaign/line-chart/?start_date=${payload.startDate}&end_date=${payload.endDate}&date_interval=month&ad_account_id=${payload?.id}`, {
        // eslint-disable-next-line max-len
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }


  public async getFbBarChartAccount(payload) {
    try {
      const response = await this.axios.get(`${ReportBuilderApi.gscBaseUrl}/facebookads/api/v1/campaign/bar-chart/?start_date=${payload.startDate}&end_date=${payload.endDate}&insight_field_to_group_by=clicks&ad_account_id=${payload?.id}`, {
        // eslint-disable-next-line max-len
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async loadReportBuilderKrtListData(page = 1, isSimple?: false, isLoadMore = false, publicHash?: string) {
    const params = {};
    const checkToken = getTokenFromCookies();
    if (isSimple) params['status_only'] = 1;
    params['page_size'] = 500;
    if (page) params['page'] = page;
    if (publicHash) params['hash'] = publicHash;
    try {
      if (checkToken) {
        const response = await this.axios.get(`${ReportBuilderApi.baseUrlKRT}/v1/rank-tracker/`, {

          headers: getAuthHeader(),
          ...(!isLoadMore && {cancelToken: this.cancelToken}),
          params: params,
        });
        return response.data;
      }
    } catch (error) {
      return Promise.reject(error);
    }
  }
  // public async deleteBusiness(id) {
  //   try {
  //     const response = await this.axios.delete(`${ReportBuilderApi.baseUrl}/v1/google-business/${id}/delete/`, {
  //       headers: getAuthHeader(),
  //     });
  //     return response.data;
  //   } catch (error) {
  //     return Promise.reject(error);
  //   }
  // }

  // public async postNewBusiness(payload) {
  //   try {
  //     const response = await this.axios.post(`${ReportBuilderApi.baseUrl}/v1/google-business/`, payload, {
  //       headers: getAuthHeader(),
  //       cancelToken: this.cancelToken,
  //     });
  //     return response.data;
  //   } catch (error) {
  //     return Promise.reject(error);
  //   }
  // }

  // public async searchLocation(params) {
  //   try {
  //     const response = await this.axios.get(`${ReportBuilderApi.baseUrl}/v1/google-business/location-suggestions/`, {
  //       // params: {
  //       //   siteproperty: siteProperty,
  //       // },
  //       params: {query: params},
  //       headers: getAuthHeader(),
  //       cancelToken: this.cancelToken,
  //     });
  //     return response.data;
  //   } catch (error) {
  //     return Promise.reject(error);
  //   }
  // }

  public async loadReportBuilderGAListData() {
    try {
      const response = await this.axios.get(`${ReportBuilderApi.baseUrl}/v2/settings/ga-properties/`, {
        headers: getAuthHeader(),
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async loadReportBuilderOTListData() {
    try {
      const response = await this.axios.get(`${ReportBuilderApi.baseLGUrl}/customer/customer-clients`, {
        headers: getAuthHeader(),
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async disconnectGa(settingId: number) {
    try {
      const response = await this.axios.delete(`${ReportBuilderApi.baseGSCDisconnect}/settings/${settingId}/`, {
        headers: getAuthHeader(),
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async loadReportBuilderSeoList() {
    const params = {};
    params['page_size'] = 500;
    try {
      const response = await this.axios.get(`${ReportBuilderApi.baseUrlKRT}/v2/google-business/`, {
        headers: getAuthHeader(),
        params,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async loadReportBuilderSeoProjectDetail(id: number) {
    try {
      const response = await this.axios.get(`${ReportBuilderApi.baseUrlKRT}/v2/google-business/${id}/`, {
        headers: getAuthHeader(),
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async loadReportBuilderGSCListData(payload, publicHash) {
    const params = payload;
    if (publicHash) {
      params['hash'] = publicHash;
    }
    try {
      const response = await this.axios.get(`${ReportBuilderApi.baseGSCUrl}/v2/gsc-properties/`, {
        params: params,
        headers: getAuthHeader(),
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async loadReportBuilderBacklinksListData(params) {
    try {
      const response = await this.axios.get(`${ReportBuilderApi.baseUrlKRT}/v2/competitor-research/`, {
        params: params,
        headers: getAuthHeader(),
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async loadLocalSeoDetails(id, payload, publicHash?: any) {
    const params = payload;
    if (publicHash) {
      params['hash'] = publicHash;
    }
    try {
      const response = await this.axios.get(`${ReportBuilderApi.baseUrlKRT}/v1/google-business/${id}/custom-report-builder/`, {
        params: params,
        headers: getAuthHeader(),
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  // public async loadGMBDetailData(id, publicHash) {
  //   const params = {};
  //   if (publicHash) {
  //     params['hash'] = publicHash;
  //   }
  //   try {
  //     const response = await this.axios.get(`${ReportBuilderApi.baseUrl}/v1/google-business/${id}/`, {
  //       params: params,
  //       headers: getAuthHeader(),
  //     });
  //     return response.data;
  //   } catch (error) {
  //     return Promise.reject(error);
  //   }
  // }

  public async loadGADetails(data) {
    try {
      const response = await this.axios.get(`${ReportBuilderApi.baseUrl}/v2/ga-reports/`, {
        params: data,
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async loadBacklinksDetails(data) {
    try {
      const response = await this.axios.get(`${ReportBuilderApi.baseBacklinksUrl}/backlinks-report/`, {
        params: data,
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async loadDataForBacklinksTable(data) {
    try {
      const response = await this.axios.get(`${ReportBuilderApi.baseLGUrl}/customer/orders/placements-over-time/`, {
        params: data,
        headers: getAuthHeader(),
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async loadKrtDetails(id, data) {
    try {
      const response = await this.axios.get(`${ReportBuilderApi.baseUrlKRT}/v1/rank-tracker/${id}/custom-report-builder/`, {
        params: data,
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async loadGSCDetails(data) {
    try {
      const response = await this.axios.get(`${ReportBuilderApi.baseGSCUrl}/v2/gsc-custom-reports/`, {
        params: data,
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async deleteProject(id) {
    try {
      const response = await this.axios.delete(`${ReportBuilderApi.baseLGUrl}/customer/custom-reports/${id}/`, {
        headers: getAuthHeader(),
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async loadSingleProjectDetailData(id, publicHash) {
    try {
      const response = await this.axios.get(`${ReportBuilderApi.baseLGUrl}/customer/custom-reports/${id}/`, {
        headers: getAuthHeader(),
        params: {
          ...(publicHash && {public_share_hash: publicHash}),
        },
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async loadOttoProjects() {
    try {
      const response = await this.axios.get(`${ReportBuilderApi.baseOtto}otto-projects/dropdown`, {
        headers: getAuthHeader(),
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async loadAISummaries(id, publicHash) {
    try {
      const response = await this.axios.post(`${ReportBuilderApi.baseLGUrl}/customer/custom-reports/${id}/process-ai-summary/`, {modules: []}, {
        headers: getAuthHeader(),
        params: {
          ...(publicHash && {public_share_hash: publicHash}),
        },
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async loadAISummariesOverview(id, publicHash) {
    try {
      const response = await this.axios.post(`${ReportBuilderApi.baseLGUrl}/customer/custom-reports/${id}/process-ai-summary-overview/`, {modules: []}, {
        headers: getAuthHeader(),
        params: {
          ...(publicHash && {public_share_hash: publicHash}),
        },
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async createNotionBoard(payload) {
    try {
      const response = await this.axios.post(`${ReportBuilderApi.baseNotion}/notion-api/create_task_board/`, payload, {
        headers: getAuthHeader(),
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async createTask(payload) {
    try {
      const response = await this.axios.post(`${ReportBuilderApi.baseNotion}/notion-api/create_task/`, payload, {
        headers: getAuthHeader(),
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async boardsListAPI(customerClientid) {
    try {
      const response = await this.axios.get(`${ReportBuilderApi.baseNotion}/notion-api/get_all_boards/?customer_client_id=${customerClientid}`, {
        headers: getAuthHeader(),
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async loadProjectsListPaginated(params) {
    try {
      const paginationParams = params ? `paginated/?page=${params.page}&page_size=${params.pageSize}&search=${params?.search}` : '';
      const response = await this.axios.get(`${ReportBuilderApi.baseLGUrl}/customer/custom-reports/${paginationParams}`, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async loadProjectsList(params) {
    try {
      const paginationParams = params ? `?page=${params.page}&page_size=${params.pageSize}` : '';
      const response = await this.axios.get(`${ReportBuilderApi.baseLGUrl}/customer/custom-reports/${paginationParams}`, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async createNewProject(data) {
    try {
      const response = await this.axios.post(`${ReportBuilderApi.baseLGUrl}/customer/custom-reports/`, data, {
        headers: getAuthHeader(),
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async editProjectReport(id, data) {
    try {
      delete data.domain;
      data.gmb_sections = data.gmb_sections == '' ? undefined : data.gmb_sections;
      const response = await this.axios.patch(`${ReportBuilderApi.baseLGUrl}/customer/custom-reports/${id}/`, data, {
        headers: getAuthHeader(),
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getUpdatedEmailSettings(id, publicHash) {
    const params= {};
    if (publicHash) {
      params['public_share_hash'] = publicHash;
    }
    try {
      const response = await this.axios.get(`${ReportBuilderApi.baseLGUrl}/customer/custom-reports/${id}/email-configuration/`, {
        params,
        headers: getAuthHeader(),
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async bulkUpdateEmailSettings(data) {
    try {
      const response = await this.axios.patch(`${ReportBuilderApi.baseLGUrl}/customer/custom-reports/bulk-update-email-settings/`, data, {
        headers: getAuthHeader(),
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async loadLocalSeoList(params?: any) {
    try {
      const response = await this.axios.get(`${ReportBuilderApi.baseUrlKRT}/v2/google-business/report-builder-dropdown/`, {
        ...(params?.public_hash && {hash: params?.public_hash}),
        headers: getAuthHeader(),
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async loadGMBList(publicHash) {
    const params= {};
    if (publicHash) {
      params['public_share_hash'] = publicHash;
    }
    try {
      const response = await this.axios.get(getApiUrl(BaseApi.GSC_ENDPOINT, '/business/api/locations/'), {
        headers: getAuthHeader(),
        params,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async loadGmbReport(params, gmbIds) {
    try {
      const response = await this.axios.get(getApiUrl(BaseApi.GSC_ENDPOINT, `/business/api/locations/${gmbIds}/reports/`), {
        headers: getAuthHeader(),
        params,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async loadCustomTemplate(params) {
    try {
      const response = await this.axios.get(`${ReportBuilderApi.baseNotion}/custom-reports/custom_layouts/`, {
        headers: getAuthHeader(),
        params,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async createCustomTemplate(payload) {
    try {
      const response = await this.axios.post(`${ReportBuilderApi.baseNotion}/custom-reports/custom_layouts/`, payload, {
        headers: getAuthHeader(),
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async updateCustomTemplate(id, payload) {
    try {
      const response = await this.axios.patch(`${ReportBuilderApi.baseNotion}/custom-reports/custom_layouts/${id}/`, payload, {
        headers: getAuthHeader(),
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async deleteCustomTemplate(id) {
    try {
      const response = await this.axios.delete(`${ReportBuilderApi.baseNotion}/custom-reports/custom_layouts/${id}/`, {
        headers: getAuthHeader(),
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async generatePdf(payload) {
    try {
      const response = await this.axios.post(`${getApiUrl(BaseApi.GSC_ENDPOINT, '/misc/api/v1')}/linkgraph-frontend-pdf/generate-pdf`, payload, {
        headers: getAuthHeader(),
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  // public async loadLocationsReport(id, publicHash) {
  //   const params = {};
  //   if (publicHash) {
  //     params['hash'] = publicHash;
  //   }
  //   try {
  //     const response = await this.axios.get(`${ReportBuilderApi.baseUrl}/v1/google-business/${publicHash ? `` : `${id}/`}${publicHash ? 'public-' :''}location-report/`, {
  //       headers: getAuthHeader(),
  //       params: params,
  //     });
  //     return response.data;
  //   } catch (error) {
  //     return Promise.reject(error);
  //   }
  // }

  // public async loadGridData(id) {
  //   try {
  //     const response = await this.axios.get(`${ReportBuilderApi.baseUrl}/v1/google-business/${id}/grids/`, {
  //       headers: getAuthHeader(),
  //     });
  //     return response.data;
  //   } catch (error) {
  //     return Promise.reject(error);
  //   }
  // }

  public async loadFbCampaignList(params) {
    try {
      const response = await this.axios.get(`${ReportBuilderApi.gscBaseUrl}/facebookads/api/v2/campaigns-config/`, {
        headers: getAuthHeader(),
        params,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async loadSelectedCampaignsData(params) {
    try {
      const response = await this.axios.get(`${ReportBuilderApi.gscBaseUrl}/facebookads/api/v2/campaign/list/`, {
        headers: getAuthHeader(),
        params,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
}

export const REPORT_BUILDER_API = new ReportBuilderApi();
