import React, {useEffect, useState} from 'react';
import {Option, SocialMediaSelect, StyledFormItem, StyledInput, StyledModal} from './styles';
import {Button, Form, Select, Tooltip} from 'antd';
import {faTrashCan, faXmark} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCaretDown} from '@fortawesome/pro-solid-svg-icons';
import {socialMediaMapping} from '../../GbpOptimization';

export const SocialMediaUrlsModal = ({visible, setVisible, selectedValue, modalLoader, setModalLoader, updateData, currentData}) => {
  const [fieldsArr, setFieldsArr] = useState([{url: 'url_1', name: 'social_media_1'}]);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [showTooltip, setShowTooltip] = useState(false);

  const onClose = () => {
    setVisible(false);
  };

  useEffect(() => {
    if (visible) {
      const arr = selectedValue.map((item, index) => ({
        url: `url_${index + 1}`,
        name: `social_media_${index + 1}`,
      }));
      setFieldsArr(arr?.length ? arr : [{url: 'url_1', name: 'social_media_1'}]);
      setSelectedOptions(
        selectedValue.reduce((obj, item) => {
          const newObj = {...obj, ...item};
          for (const key in newObj) {
            if (key.includes('url')) {
              delete newObj[key];
            }
          }
          return newObj;
        }, {}),
      );
    }
  }, [visible, selectedValue]);

  const options = [
    {value: 'pinterest', label: 'Pinterest', img: '/img/pinterest.png'},
    {value: 'tiktok', label: 'TikTok', img: '/img/tiktok.png'},
    {value: 'twitter', label: 'X (Twitter)', img: '/img/twitter.png'},
    {value: 'youtube', label: 'YouTube', img: '/img/youtube.png'},
    {value: 'facebook', label: 'Facebook', img: '/img/facebook.png'},
    {value: 'instagram', label: 'Instagram', img: '/img/instagram.png'},
    {value: 'linkedin', label: 'LinkedIn', img: '/img/linkedin.png'},
  ];

  const handleChange = (name, value) => {
    setSelectedOptions(prevState => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const initialValues = () => {
    return selectedValue.reduce((obj, item) => ({...obj, ...item}), {});
  };

  const reversedSocialMedia = Object.fromEntries(
    Object.entries(socialMediaMapping).map(([key, value]) => [value, key]),
  );

  const replaceKeys = (obj1, obj2) => {
    const result = {};

    for (const key in obj1) {
      if (obj2[key]) {
        result[obj2[key]] = obj1[key];
      }
    }

    const arr1 = Object.keys(currentData)?.filter(i => Object.values(obj2)?.some(key => i === key));
    const arr2 = Object.keys(result);
    const uniqueValues = [
      ...arr1.filter(value => !arr2.includes(value)),
    ];

    uniqueValues.forEach(i => {
      result[i] = null;
    });

    return result;
  };

  const getPayload = data => {
    const obj = {};

    for (const key in data) {
      if (key.startsWith('social_media')) {
        const index = key.split('_').pop();
        const urlKey = `url_${index}`;

        if (data[urlKey]) {
          obj[data[key]] = data[urlKey];
        }
      }
    }

    const transformedData = Object.entries(replaceKeys(obj, reversedSocialMedia)).map(([fieldName, value]) => ({
      fieldName,
      value,
      fieldType: 'SOCIAL_PROFILES',
    }));

    return transformedData;
  };

  return (
    <StyledModal
      open={visible}
      onCancel={onClose}
      width={660}
      closable={true}
      closeIcon={<FontAwesomeIcon icon={faXmark} />}
      footer={false}
    >
      <div className='title'>Social media profiles</div>
      <div className='desc'>Build media profiles across all platforms to secure backlinks and expand your online reach.</div>
      <div className='fields-heading'>Social Profile</div>
      <Form
        initialValues={initialValues()}
        onFinish={async values => {
          setModalLoader(true);
          try {
            const payload = getPayload(values);
            await updateData(payload);
          } catch (error) {
            console.error(error);
          } finally {
            setModalLoader(false);
          }
        }}
      >
        {fieldsArr?.map(item => (
          <div className='social-media-fields-container' key={item?.name}>
            <StyledFormItem preserve={false} name={item?.name} style={{marginBottom: '0px'}} rules={[{required: true, message: 'This field is required'}]}>
              <SocialMediaSelect
                placeholder='Select profile'
                value={selectedOptions[item?.url]}
                onChange={value => handleChange(item?.url, value)}
                suffixIcon={<FontAwesomeIcon icon={faCaretDown} color='#121212' />}
                onDropdownVisibleChange={open => setShowTooltip(open)}
              >
                {options?.map(item => (
                  <Select.Option
                    key={item?.value}
                    value={item?.value}
                    disabled={Object.values(selectedOptions)?.includes(item.value)}
                  >
                    <Tooltip title={Object.values(selectedOptions)?.includes(item.value) && showTooltip ? 'It is not permissible to add the same social media profile more than once.' : ''}>
                      <Option>
                        <img src={item.img} alt={item.label} />
                        {item.label}
                      </Option>
                    </Tooltip>
                  </Select.Option>
                ))}
              </SocialMediaSelect>
            </StyledFormItem>
            <StyledFormItem
              name={item?.url}
              style={{width: '100%', marginBottom: '0px'}}
              rules={[
                {required: true, message: 'This field is required'},
                {type: 'url', message: 'Please enter a valid URL'},
              ]}
              preserve={false}
            >
              <StyledInput placeholder='Web address' />
            </StyledFormItem>
            {fieldsArr?.length > 1 && <FontAwesomeIcon onClick={() => setFieldsArr(prev => prev?.filter(i => i?.url !== item?.url))} className='delete-icon' icon={faTrashCan} color='#A3A4A4' />}
          </div>
        ))}
        <button
          onClick={e => {
            e.preventDefault();
            setFieldsArr(prev => [...prev, {url: `url_${prev?.length + 1}`, name: `social_media_${prev?.length + 1}`}]);
          }}
          className='add-phone-num-button'
        >
          + Add social profile
        </button>
        <div className='footer' style={{marginTop: '73px'}}>
          <Button
            className='save-button'
            htmlType='submit'
            loading={modalLoader}
          >Save Changes</Button>
          <button onClick={onClose} className='cancel-button'>Cancel</button>
        </div>
      </Form>
    </StyledModal>
  );
};
