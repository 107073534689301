import {Tooltip, Spin, Collapse} from 'antd';
import {observer} from 'mobx-react';
import React, {useCallback, useEffect, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircleCheck, faCircleExclamation, faHourglassStart} from '@fortawesome/pro-solid-svg-icons';
import {LoadingOutlined} from '@ant-design/icons';
import {useStore} from '@/store/root-store';
import {getSingleUrlParam, addProtocolToDomainHttps} from '@/utils/url';
import {openUrl} from '@/utils/router';
import {ChannelStatus, PaginationStyled, PressReleaseStatusWrapper, StyledIssuesCollapse, StyledIssuesTable, UrlWrapper} from '../../../style';
import {getDeployStatus} from '../../tableIssuesCollapse';
import {debounce} from 'lodash';
import {CloudStackStatusButton, StyledCloudStackInput} from '../../../style';
import {TableTopBar} from '../../tableTopBar';
import {faCheck, faWandMagicSparkles, faXmark, faCopy, faArrowUpFromBracket} from '@fortawesome/pro-regular-svg-icons';
import {apiError} from '@/utils/api';
import {notification} from '@/utils/notification-v2';
import AddPressReleaseModal from './addPressReleaseModal/addPressReleaseModal';
import {getOttoTablePageSize} from '@/api/common-utils';
import {saveOttoTablePageSize} from '@/api/common-utils';
import FreezeWrapper from '../../freezTooltip';
import {Links} from './addPressReleaseModal/pressReleaseStyles';
import {FiltersV2} from '@/components/common-components/v2/filtersV2';
import {trialExpiresAtDateCheck} from '@/utils/moment';
import styles from './styles.module.scss';
import * as XLSX from 'xlsx';
import {Button} from '@/components/common-components/v2';

interface Props {
  componentIssueType: string;
  setPageChanged: (value: boolean) => void;
  setIssueTable: (value: string) => void;
  issueTable: string;
  isStandalone?: boolean;
  maxHeight?: string;
  removeTopBar?: boolean;
}

interface SignalGenesys {
  genesysCategoryId: string;
  genesysId?: string;
}

interface RecordData {
  id?: string;
  status?: string;
  signalGenesys?: SignalGenesys;
}

const antIcon = <LoadingOutlined style={{fontSize: 16, color: '#2D6CCA'}} spin />;
export const PressRelease = observer(({componentIssueType, setPageChanged, setIssueTable, issueTable, isStandalone = false, maxHeight = null, removeTopBar = false}: Props) => {
  const {ottoV2Store: {
    getOttoUrls,
    getOttoV2Project,
    loadIssueTableData,
    ottoUrlLoader,
    ottoIssueType,
    creatingPressReleaseProject,
    setPressReleaseCurrentPage,
    setSelectedIssue,
    isActiveKeys,
    resetPRDistributionFilters,
    setSelectedCategory,
    setLoadingDetail,
    selectedCategory,
    setOttoSearchTerm,
    issueTypeArray,
    selectedIssue,
    getDistributionChannels,
    getCategories,
    getTaskResult,
    distributionAgainstUrl,
    loadingPublishOrDeplayPress,
    buildOrDeployPressRelease,
    getDistributionAgainstUrl,
    getIsAddPrModalVisible,
    getPressReleaseDistributionFilters,
    updatePRDistributionFilters,
    setIsAddPrModalVisible,
    setPressReleaseCurrentPageSize,
    setGbpLocationRepoll,
  }, settings: {customer: {getCustomerQuota, profile: {trialExpiresAt}}},
  } = useStore('');
  const [page, setPage] = useState(1);
  const getOttoUrlAgainstIssueType = urls => {
    if (isStandalone) {
      return urls?.find(url => url?.issueType === componentIssueType);
    }
    const filteredOttoUrls = urls ? urls?.filter(url => issueTypeArray.includes(url?.issueType)) : [];
    const url = filteredOttoUrls.find(url => url?.issueType === componentIssueType);

    return url;
  };


  useEffect(() => {
    if (getOttoUrls) {
      const url = getOttoUrlAgainstIssueType(getOttoUrls);
      if (url?.page === page) setOttoUrls(getOttoUrls);
    }
  }, [getOttoUrls]);

  const uuid = getSingleUrlParam('uuid');
  const [ottoUrls, setOttoUrls] = useState([]);
  const [cloudTargetKeywords, setCloudTargetKeywords] = useState({});
  const [creatingCloudContent, setCreatingCloudContent] = useState<number | null>(null);
  const url = getOttoUrlAgainstIssueType(ottoUrls);
  const data = url?.issueTable?.results ?? [];
  const filteredData = data;
  const [isTableOpen, setIsTableOpen] = useState<any>('open');
  const [isOpenSearch, setIsOpenSearch] = useState(false);
  const [isActiveFilter, setIsActiveFilter] = useState('all');
  const [searchText, setSearchText] = useState('');
  const [addRecord, setAddRecord] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [isEdit, setIsEdit] = useState(false);
  const [recordData, setRecord] = useState<RecordData>({});
  const [publishingCloudContent, setPublishingCloudContent] = useState(false);
  const [pageSize, setPageSize] = useState(isStandalone ? 10 : getOttoTablePageSize(componentIssueType));

  const taskId = localStorage.getItem('taskId');
  useEffect(() => {
    resetPRDistributionFilters();
    setPressReleaseCurrentPageSize(isStandalone ? 10 : getOttoTablePageSize(componentIssueType));
    if (taskId) {
      getTaskResult('press_release', taskId, false);
    }
  }, []);


  useEffect(() => {
    if (ottoUrls?.length) {
      const targetKeywordsList = ottoUrls[0]?.issueTable?.results?.map(item => ([item?.id, item?.targetKeywords?.join(', ') || '']));
      if (targetKeywordsList) {
        setCloudTargetKeywords(Object?.fromEntries(targetKeywordsList));
      }
    }
  }, [ottoUrls]);


  useEffect(() => {
    getDistributionChannels();
    getCategories();
    if (isStandalone) setGbpLocationRepoll(true);
    return () => {
      if (isStandalone) setGbpLocationRepoll(false);
    };
  }, []);

  const searchDebounce = debounce(async (params, isSitewide) => {
    await loadIssueTableData(params, isSitewide);
  }, 1000);

  const loadIssueTables = useCallback(async (issueArray: string[], issue: string, category: string, page: number, pageSize: number, stopLoading?: boolean, search?: string, rating?: string, activeKey?: string) => {
    if (isStandalone) {
      const params = constructParamsFromFilters(getPressReleaseDistributionFilters);
      params.page = page;
      params.page_size = pageSize;
      await loadIssueTableData(params, false);
      return;
    }
    setSelectedIssue(issue);
    setSelectedCategory(category);
    if (!stopLoading) {
      setLoadingDetail(true);
    }
    for (let index = 0; index < issueArray.length; index++) {
      const issueType = issueArray[index];
      if (issueTypeArray.includes(issueType)) {
        const params = {
          uuid,
          otto_project: getOttoV2Project?.id,
          issue_type: issueType,
          page_size: pageSize,
          page,
          is_loading: false,
          ...((activeKey == 'deployed' || activeKey == 'not_deployed') && {deploy_status: getDeployStatus(activeKey)}),
        };
        if (search) {
          params['search'] = search;
          setOttoSearchTerm(search);
        } else {
          setOttoSearchTerm('');
        }
        if (search) {
          searchDebounce(params, false);
        } else {
          await loadIssueTableData(params, false);
        }
      }
    }
    setLoadingDetail(false);
  }, [getPressReleaseDistributionFilters]);

  const updateCloudTargetKeywords = (recordId, keyword: string) => {
    setCloudTargetKeywords(prev => {
      return {
        ...prev,
        [recordId]: keyword,
      };
    });
  };

  const createCloudContent = async recordId => {
    const filter = ottoUrls.filter(d => d.issueType === ottoUrls[0]?.issueType);
    try {
      setCreatingCloudContent(recordId);
      await buildOrDeployPressRelease(recordId, true);
      setLoadingDetail(true);
      if (isStandalone) {
        const activeFilters = getPressReleaseDistributionFilters?.filter(item => item?.active === true);
        const params = {
          issue_type: 'press_release_distribution',
          page: filter?.length ? filter[0].page : 1,
          page_size: filter?.length ? filter[0].pageSize : 10,
        };
        activeFilters?.forEach(item => {
          if (item?.name === 'exclude') {
            params['search'] = item.query;
          }
          if (item?.name == 'radioButtonsFilter') {
            params['status__in'] = item.type;
          }
        });
        await loadIssueTableData(params);
      } else {
        await loadIssueTableData({uuid: getOttoV2Project?.uuid, page: filter?.length ? filter[0].page : 1, page_size: filter?.length ? filter[0].pageSize : 10, issue_type: ottoUrls[0]?.issueType, otto_project: getOttoV2Project?.id}, false, false, getCustomerQuota);
      }
      await getCustomerQuota();
      setLoadingDetail(false);
      setCreatingCloudContent(null);
    } catch (error) {
      const errorMessage = apiError(error) === 'Missing business_name on knowledge graph' ? 'Please update Business Name in knowledge graph modal' : apiError(error);
      notification.error('', errorMessage);
      setCreatingCloudContent(null);
    }
  };


  const renderWithStatus = record => {
    const status = record?.status;
    const allPublished = record?.channelsCountByStatus?.published > 0 && record?.channelsCount == record?.channelsCountByStatus?.published && !Object.keys(record?.channelsCountByStatus || {})?.filter(key => key !== 'published')?.some(key => record?.channelsCountByStatus[key]);

    if (status === 'Pending' || status === 'Unapproved') {
      return (
        <FreezeWrapper>
          <Tooltip overlayStyle={{maxWidth: 390}} title={!trialExpiresAtDateCheck(trialExpiresAt) ? 'Access to this feature is restricted for free trial users.' : 'AI Generate Content costs 3 HyperDrive Credits.'}>
            <div>
              <CloudStackStatusButton disabled={cloudTargetKeywords?.[record?.id]?.length < 0 || loadingPublishOrDeplayPress === record?.id || !trialExpiresAtDateCheck(trialExpiresAt)} background='#2D6CCA' textColor='#fff' onClick={() => createCloudContent(record?.id)}>
                <FontAwesomeIcon icon={faWandMagicSparkles} fontSize={14} color='#fff'/>
                <div style={{fontFamily: 'Inter', fontSize: '13px', fontWeight: 400, color: '#fff'}}>AI Generate Content (3 credits)</div>
                {loadingPublishOrDeplayPress === record?.id && <Spin indicator={antIcon}/>}
              </CloudStackStatusButton>
            </div>
          </Tooltip>
        </FreezeWrapper>
      );
    }

    if ((status === 'Published' || status === 'Publishing') && !allPublished) {
      return <div
        style={{display: 'flex', alignItems: 'center', gap: '6px', color: '#2D6CCA', fontSize: 13, cursor: 'pointer'}}
        onClick={() => {
          setIsEdit(true);
          setRecord(record);
          setAddRecord(true);
        }}
      >
        <FontAwesomeIcon icon={faHourglassStart} color='#2D6CCA' fontSize={18} /> View Status
      </div>;
    }

    if (status === 'Published' && allPublished) {
      return <div style={{display: 'flex', alignItems: 'center', gap: '6px'}}>
        <Tooltip title={trialExpiresAtDateCheck(trialExpiresAt) ? '' : 'Access to this feature is restricted for free trial users.'}>
          <div>
            <CloudStackStatusButton
              disabled={!trialExpiresAtDateCheck(trialExpiresAt)}
              isWhite={true}
              background='#fff'
              textColor='#2AC155'
              onClick={() => {
                setIsEdit(true);
                setRecord(record);
                setAddRecord(true);
              }}
            >
              <FontAwesomeIcon icon={faCircleCheck} fontSize={14} color='#2AC155' style={{cursor: 'pointer'}}/>
                  Published
            </CloudStackStatusButton>
          </div>
        </Tooltip>
      </div>;
    }

    // if (status === 'Publishing') {
    //   return <div style={{display: 'flex', alignItems: 'center', gap: '6px'}}>
    //     <CloudStackStatusButton
    //       isWhite
    //       background='white'
    //       textColor='#4E5156'
    //       onClick={() => {
    //         setIsEdit(true);
    //         setRecord(record);
    //         setActiveTab(2);
    //         setAddRecord(true);
    //       }}
    //     >
    //       <Tooltip title='Press Release publishing may take up to 3 days'>
    //         <span style={{color: '#4E5156'}}>
    //           <FontAwesomeIcon icon={faHourglassStart} style={{width: '12px', height: '16px', color: '#4E5156'}} />
    //             Publishing
    //         </span>
    //       </Tooltip>
    //     </CloudStackStatusButton>
    //   </div>;
    // }

    if (status === 'Generated') {
      return <div style={{display: 'flex', alignItems: 'center', gap: '6px'}}>
        <Tooltip title={trialExpiresAtDateCheck(trialExpiresAt) ? '' : 'Access to this feature is restricted for free trial users.'}>
          <div>
            <CloudStackStatusButton
              disabled={!trialExpiresAtDateCheck(trialExpiresAt)}
              background='#2D6CCA'
              textColor='#fff'
              onClick={() => {
                setIsEdit(true);
                setRecord(record);
                setActiveTab(1);
                setAddRecord(true);
                isStandalone && setIsAddPrModalVisible(true);
              }}
            >
              <img src='/img/icon/turned-arrows.svg' alt='' color='#fff' style={{cursor: 'pointer', width: 14, height: 14}}/>
                Publish content
            </CloudStackStatusButton>
          </div>
        </Tooltip>
      </div>;
    }

    if (status === 'Publish stuck' || status === 'Publish failed') {
      return (
        <PressReleaseStatusWrapper>
          <Tooltip title={trialExpiresAtDateCheck(trialExpiresAt) ? '' : 'Access to this feature is restricted for free trial users.'}>
            <div>
              <CloudStackStatusButton disabled={!trialExpiresAtDateCheck(trialExpiresAt)} isWhite={true} background='#fff' textColor={status === 'Publish stuck' ? '#FF8536' : '#F44343'}>
                <FontAwesomeIcon icon={faCircleExclamation} fontSize={16} color={status === 'Publish stuck' ? '#FF8536' : '#F44343'}/>
                  Publication {status === 'Publish stuck' ? 'stuck' : 'failed'}. <Tooltip overlayStyle={{maxWidth: 350}} overlayInnerStyle={{background: '#121212', borderRadius: 8}} title={record?.signalGenesys?.genesysStatusDescription || record?.signalGenesys?.genesysStatusMessage}>
                  <span className='tooltip-btn'>Why?</span>
                </Tooltip>
              </CloudStackStatusButton>
            </div>
          </Tooltip>
        </PressReleaseStatusWrapper>
      );
    }

    if (creatingCloudContent === record?.id || status === 'Generating') {
      return <div style={{color: '#4E5156', fontSize: 13, lineHeight: '15.73px'}}>
        <Spin indicator={<LoadingOutlined style={{fontSize: 14, color: '#4E5156', marginRight: 6}} spin />}/>
        Generating content ...
      </div>;
    }
  };

  const columns = [
    {
      title: <div className='column-title'>TARGET PAGE</div>,
      dataIndex: 'targetPage',
      key: 'targetPage',
      width: 670,
      render: (_, record) => {
        return (
          <UrlWrapper style={{maxWidth: 600}} onClick={() => openUrl(`${addProtocolToDomainHttps(record?.targetUrl)}`, '_blank')}>{record?.targetUrl}</UrlWrapper>
        );
      },
    },
    {
      title: <div className='column-title'>TARGET KEYWORD</div>,
      dataIndex: 'targetKeyword',
      key: 'targetKeyword',
      width: 300,
      render: (_, record) => {
        const isDisable = creatingPressReleaseProject || creatingCloudContent === record?.id || record.status === 'Generating';
        return (
          <div style={{minWidth: 300, maxWidth: 300}}>
            {!record?.viewableUrl ? (
              <StyledCloudStackInput disabled={isDisable} onChange={e => updateCloudTargetKeywords(record?.id, e.target.value)} value={cloudTargetKeywords[record?.id]} placeholder='Enter the Target Keyword here...' />
            ) : (
              <div style={{fontSize: 13}}>{record?.targetKeywords?.join(', ') || ''}</div>
            )}
          </div>
        );
      },
    },
    {
      title: <div className='column-title'>PRESS RELEASE STATUS</div>,
      dataIndex: 'status',
      key: 'status',
      width: 250,
      render: (_, record) => {
        return renderWithStatus(record);
      },
    },
  ];

  const renderStausObj = {
    'Published': {
      background: '#2AC1551F',
      color: '#18923B',
      label: 'Published',
      icon: faCheck,
    },
    'Publishing': {
      background: '#F1AA3E1F',
      color: '#F1AA3E',
      label: 'Publishing',
      icon: faHourglassStart,
    },
    'Publish failed': {
      background: '#F443431F',
      color: '#F44343',
      label: 'Rejected',
      icon: faXmark,
    },
    'Generated': {
      background: '#2AC1551F',
      color: '#18923B',
      label: 'Generated',
      icon: faCheck,
    },
  };

  const publishCloudStack = async obj => {
    if (publishingCloudContent) return;

    setPublishingCloudContent(false);
    const filter = ottoUrls.filter(d => d.issueType === ottoUrls[0]?.issueType);
    try {
      const genesysCategoryId = obj?.variation?.status ==='Generated' ? recordData?.signalGenesys?.genesysCategoryId : obj?.variation?.signalGenesys?.genesysCategoryId;
      const response = await buildOrDeployPressRelease(recordData?.id, false, {
        mock_code: 200,
        genesys_category_id: genesysCategoryId,
        distribution_ids: [obj?.distribution],
        variations: obj?.variation?.status ==='Generated',
      });
      await getTaskResult('press_release', response?.response?.taskId, false);
      setLoadingDetail(true);
      if (isStandalone) {
        const activeFilters = getPressReleaseDistributionFilters?.filter(item => item?.active === true);
        const params = {
          issue_type: 'press_release_distribution',
          page: filter?.length ? filter[0].page : 1,
          page_size: filter?.length ? filter[0].pageSize : 10,
        };
        activeFilters?.forEach(item => {
          if (item?.name === 'exclude') {
            params['search'] = item.query;
          }
          if (item?.name == 'radioButtonsFilter') {
            params['status__in'] = item.type;
          }
        });
        await loadIssueTableData(params);
      } else {
        await loadIssueTableData({uuid: getOttoV2Project?.uuid, page: filter?.length ? filter[0].page : 1, page_size: filter?.length ? filter[0].pageSize : 10, issue_type: ottoUrls[0]?.issueType, otto_project: getOttoV2Project?.id}, false, false, getCustomerQuota);
      }
      await getCustomerQuota();
      await getDistributionAgainstUrl(recordData?.id);
      setLoadingDetail(false);
      setPublishingCloudContent(false);
    } catch (error) {
      const errorMessage = apiError(error);
      notification.error(errorMessage, `There might be some issues related to content not covered on this page. Please review the content or check other relevant sections to resolve the issue.`);
      setPublishingCloudContent(false);
    }
  };

  const exportToExcel = (links: string[]) => {
    const data = links.map(link => [link]);
    const ws = XLSX.utils.aoa_to_sheet(data);

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Links');

    XLSX.writeFile(wb, 'hits.xlsx');
  };

  const urlsTooltip = (urls: string[]) => {
    return (
      <div className={styles.scrollable} style={{display: 'flex', flexDirection: 'column', gap: 12}}>
        <Button
          color='textGrayDark'
          variant='ghost'
          mode='light'
          background='white'
          style={{height: '28px', border: '1px solid #CDCDCD', marginRight: 0, marginLeft: 'auto', marginBottom: 4}}
          onClick={() => exportToExcel(urls)}
        >
          <FontAwesomeIcon icon={faArrowUpFromBracket} style={{fontSize: '12px'}}/>&nbsp;Export
        </Button>
        {urls.map((url, index) => (
          <div
            key={`${url}-${index}`}
            onClick={() => url && openUrl(addProtocolToDomainHttps(url), '_blank')}
            className={styles.promotedUrl}
            style={{display: 'flex', gap: 6}}
          >
            <span style={{color: '#000', fontWeight: 700}}>{index + 1}.</span> {url || '-'}
          </div>
        ))}
      </div>
    );
  };

  const renderActions = obj => {
    if (obj?.variation?.status ==='Publish failed' || obj?.variation?.status ==='Generated') {
      return <div style={{display: 'flex', alignItems: 'center', gap: '27px'}}>
        {obj?.variation?.editableUrl ? <UrlWrapper onClick={() => openUrl(`${addProtocolToDomainHttps(obj?.variation?.editableUrl)}`, '_blank')}>Edit</UrlWrapper> : '-'}
        <UrlWrapper onClick={() => publishCloudStack(obj)}>{publishingCloudContent ? <Spin indicator={antIcon}/> : 'Submit'}</UrlWrapper>
      </div>;
    }

    if (obj?.mediaUrls?.length) {
      const urls = obj?.mediaUrls?.map(media => media?.url);
      return (
        <div>
          {urls?.length > 0 ? (
            <Tooltip destroyTooltipOnHide placement='bottom' color='#fff' overlayStyle={{maxWidth: 700}} overlayClassName={styles.urlsTooltip} title={urlsTooltip(urls)}>
              <UrlWrapper>View {urls?.length} link{urls?.length === 1 ? '' : 's'}</UrlWrapper>
            </Tooltip>
          ) : '-'}
        </div>
      );
    } else {
      return <>-</>;
    }
  };

  const [copied, setCopied] = useState(false);

  const pressReleaseDropdownColumns = () => {
    return [
      {
        title: <div style={{fontSize: 12, lineHeight: '14px'}} className='column-title'>DISTRIBUTION CHANNEL</div>,
        dataIndex: 'channel',
        key: 'channel',
        width: 140,
        render: (_, record) => {
          const obj = distributionAgainstUrl?.results?.find(i => i?.distribution === record?.id);
          return (
            <div className='site'>
              <div style={{textTransform: 'capitalize', width: '290px', display: 'flex', alignItems: 'flex-start'}} className={record?.displayName !== 'Coming soon' ? '' : 'comingSoon'}>
                {record?.displayName !== 'Coming soon' ? <img
                  src={record?.icon}
                  alt=''
                  style={{width: 20, height: 20, marginRight: 6}}
                /> : ''}
                <Tooltip
                  overlayInnerStyle={{backgroundColor: '#121212', borderRadius: 12, padding: '6px 8px 6px 12px !important'}}
                  placement='right'
                  title={(
                    copied ? <div style={{display: 'flex', alignItems: 'center', gap: 8, fontSize: 12}}>
                      <FontAwesomeIcon
                        icon={faCheck}
                        color={'#18923B'}
                      />
            ID Copied to Clipboard
                    </div> :
                      obj?.variation?.signalGenesys?.genesysId ?
                        <div style={{display: 'flex', alignItems: 'center', gap: 17, fontSize: 12}}>
                      ID: {obj?.variation?.signalGenesys?.genesysId}
                          <div style={{backgroundColor: '#4E5156', borderRadius: 6, padding: '4px 8px 4px 8px', cursor: 'pointer'}} onClick={e => {
                            e.stopPropagation();
                            navigator.clipboard.writeText(obj?.variation?.signalGenesys?.genesysId);
                            setCopied(true);
                          }}
                          >
                            <FontAwesomeIcon
                              icon={faCopy}
                              style={{marginRight: 5}}
                            />
            Copy
                          </div>
                        </div> : ''
                  )}
                ><span style={{display: 'inline-block', width: '260px', whiteSpace: 'break-spaces', lineHeight: 'normal'}} onMouseEnter={() => setCopied(false)}>{record?.displayName === 'Financial Network' ? 'The Street + Financial Network' : record?.displayName || '-'}</span></Tooltip>
              </div>
            </div>
          );
        },
      },
      {
        title: (
          <div style={{fontSize: 12, lineHeight: '14px'}} className='column-title'>
            SITES
          </div>
        ),
        dataIndex: 'Links',
        key: 'Links',
        align: 'center',
        width: 100,
        render: (_, record) => {
          if (record?.displayName === 'Coming soon') return;
          const approxLinks = record?.linksApprox?.replace(' to ', '-');
          return <Links>{approxLinks}</Links>;
        },
      },
      {
        title: (
          <div style={{fontSize: 12, lineHeight: '14px'}} className='column-title'>
            STATUS
          </div>
        ),
        dataIndex: 'STATUS',
        key: 'STATUS',
        align: 'center',
        width: 100,
        render: (_, record) => {
          if (record?.displayName === 'Coming soon') return;
          const obj = distributionAgainstUrl?.results?.find(i => i?.distribution === record?.id);
          if (renderStausObj[obj?.variation?.status]) {
            return (
              <ChannelStatus background={renderStausObj[obj?.variation?.status]?.background} color={renderStausObj[obj?.variation?.status]?.color}>
                <FontAwesomeIcon icon={renderStausObj[obj?.variation?.status]?.icon}/>
                {renderStausObj[obj?.variation?.status]?.label}
              </ChannelStatus>
            );
          } else {
            return <>-</>;
          }
        },
      },
      {
        title: <div style={{fontSize: 12, lineHeight: '14px'}} className='column-title'>PUBLICATION</div>,
        dataIndex: 'id',
        key: 'id',
        width: 90,
        render: (_, record) => {
          if (record?.displayName === 'Coming soon') return;
          const obj = distributionAgainstUrl?.results?.find(i => i?.distribution === record?.id);
          return renderActions(obj);
        },
      },
    ];
  };


  const handlePaginationChange = (issueArray: string[], page, pageSize) => {
    const activeKey = issueArray?.length ? isActiveKeys?.find(item => item?.issue == issueArray[0])?.key : 'all';
    loadIssueTables(issueArray, selectedIssue, selectedCategory, page, pageSize, true, '', '', activeKey);
  };

  const constructParamsFromFilters = filters => {
    const activeFilters = filters?.filter(item => item?.active === true);
    const params = {
      issue_type: 'press_release_distribution',
      page: 1,
      page_size: pageSize,
    };
    activeFilters?.forEach(item => {
      if (item?.name === 'exclude') {
        params['search'] = item.query;
      }
      if (item?.name == 'radioButtonsFilter') {
        params['status__in'] = item.type;
      }
    });
    return params;
  };

  const handleStandaloneFilterChange = filters => {
    updatePRDistributionFilters(filters);
    setPageChanged(true);

    const params = constructParamsFromFilters(filters);
    setPage(1);
    loadIssueTableData(params, false);
  };

  return (
    <>
      <StyledIssuesCollapse
        ghost
        expandIconPosition='right'
        defaultActiveKey={[`${isTableOpen}`]}
        activeKey={[`${isTableOpen}`]}
        onChange={() => {
          if (isTableOpen === 'close') {
            setIsTableOpen('open');
            setIsOpenSearch(false);
          } else {
            setIsTableOpen('close');
            setIsOpenSearch(false);
          }
        }}
      >
        {isStandalone && <FiltersV2
          onChange={handleStandaloneFilterChange}
          width='fit-content'
          mainFilters={getPressReleaseDistributionFilters}
          hideTopSection={true}
          simpleMoreBtn={true}
          isShowSorters={true}
        />}
        {!isStandalone && !removeTopBar && <TableTopBar
          componentIssueType={componentIssueType}
          setPageChanged={setPageChanged}
          setIssueTable={setIssueTable}
          issueTable={issueTable}
          setSearchText={setSearchText}
          searchText={searchText}
          setIsOpenSearch={setIsOpenSearch}
          isOpenSearch={isOpenSearch}
          setIsTableOpen={setIsTableOpen}
          isTableOpen={isTableOpen}
          setAddRecord={setAddRecord}
          setIsActiveFilter={setIsActiveFilter}
          isActiveFilter={isActiveFilter}
        />
        }
        <Collapse.Panel key='open' header={<></>}>
          <StyledIssuesTable
            loading={ottoUrlLoader && ottoIssueType === componentIssueType}
            columns={columns}
            dataSource={filteredData}
            pagination={false}
            scroll={removeTopBar ? {x: 'auto', y: 'auto'} : {x: 850}}
            maxHeight={maxHeight}
          />
          <PaginationStyled
            onChange={(page, pageSize) => {
              setPageChanged(true);
              setIssueTable(url?.issueType);
              handlePaginationChange([url?.issueType], page, pageSize);
              setPressReleaseCurrentPage(page);
              setPressReleaseCurrentPageSize(pageSize);
              !isStandalone && saveOttoTablePageSize(componentIssueType, pageSize);
              setPageSize(pageSize);
              setPage(page);
            }}
            total={url?.issueTable?.count}
            pageSize={pageSize}
            current={page}
            showSizeChanger
            pageSizeOptions={['5', '10', '20', '50', '100']}
          />
        </Collapse.Panel>
      </StyledIssuesCollapse>
      {(addRecord || getIsAddPrModalVisible) && <AddPressReleaseModal
        visible={addRecord || getIsAddPrModalVisible}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        componentIssueType={componentIssueType}
        isEdit={isEdit}
        record={recordData}
        setAddRecord={setAddRecord}
        setIsEdit={setIsEdit}
        pressReleaseDropdownColumns={pressReleaseDropdownColumns}
        setCreatingCloudContent={setCreatingCloudContent}
        isStandalone={isStandalone}
      />}
    </>
  );
});
