import React, {useEffect, useState} from 'react';
import {Form} from 'antd';
import ModalContent from './modalContent';
import {transformToStateFormat} from './commonFolderSettingsUtils';
import {useStore} from '@/store/root-store';
import {observer} from 'mobx-react';
import {StyledModal} from './styles';

const FolderSettings = ({visible, setVisible, isEdit, setIsEdit, showBanner=true, setIsNewPageLoading}: {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  isEdit: boolean;
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
  showBanner?: boolean;
  setIsNewPageLoading?: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const {contentOptimizer: {pages: {projects, activeProjectId}}} = useStore('');

  const [termsToExclude, setTermsToExclude] = useState([]);
  const [wpId, setWpId] = useState(null);
  const [selectedToneOfVoice, setSelectedToneOfVoice] = useState('Professional');
  const [currentProjectData, setCurrentProjectData] = useState({});
  const [terms, setTerms] = useState('');
  const [formKey, setFormKey] = useState(-1);
  const [linksToIncludeState, setLinksToIncludeState] = useState<any>({
    isEmpty: true,
    items: [{
      value: '',
      anchorText: '',
      label: 'Link',
      placeholder: 'Link',
      fields: [

      ],
      required: true,
    },
    {
      value: '',
      anchorText: '',
      label: 'Link',
      placeholder: 'Link',
      fields: [

      ],
      required: false,
    },
    ],
  });

  const [externalLinksToIncludeState, setExternalLinksToIncludeState] = useState<any>({
    isEmpty: true,
    items: [{
      value: '',
      anchorText: '',
      label: 'Link',
      placeholder: 'Link',
      fields: [
      ],
      required: true,
    },
    {
      value: '',
      anchorText: '',
      label: 'Link',
      placeholder: 'Link',
      fields: [
      ],
      required: false,
    },
    ],
  });

  const [form] = Form.useForm();
  const onClose = () => {
    form.resetFields();
    setVisible(false);
    setIsEdit(false);
    setFormKey(-1);
    setIsNewPageLoading && setIsNewPageLoading(false);
  };

  // useEffect(() => {
  //   if (!visible) {
  //     form.resetFields();
  //     setCompanyName('');
  //     setWebsiteUrl('');
  //     setTargetUrl('');
  //     setBusinessAddress('');
  //     setGbpUrl('');
  //     setEmail('');
  //     setPhone('');
  //     setMediaContactName('');
  //     setMediaContactEmail('');
  //     setMediaContactPhone('');
  //   }
  // }, [visible]);

  useEffect(() => {
    if (isEdit) {
      const filteredProject = projects.find(item => item.id == activeProjectId);
      setCurrentProjectData(filteredProject);
      setSelectedToneOfVoice(filteredProject?.defaultAiSettings?.toneOfVoice || 'Professional');
      setTermsToExclude(filteredProject?.excludedFocusterms || []);
      setWpId(filteredProject?.wpWebsiteId);
      setFormKey(activeProjectId);

      const linksToIncludeData = transformToStateFormat(filteredProject?.requiredInternalLinks, filteredProject?.optionalInternalLinks)?.items?.length ? transformToStateFormat(filteredProject?.requiredInternalLinks, filteredProject?.optionalInternalLinks) : {
        isEmpty: true,
        items: [{
          value: '',
          anchorText: '',
          label: 'Link',
          placeholder: 'Link',
          fields: [

          ],
          required: true,
        },
        {
          value: '',
          anchorText: '',
          label: 'Link',
          placeholder: 'Link',
          fields: [

          ],
          required: false,
        },
        ],
      };

      setLinksToIncludeState(linksToIncludeData);

      const linksToExclude = transformToStateFormat(filteredProject?.requiredExternalLinks, filteredProject?.optionalExternalLinks)?.items?.length ? transformToStateFormat(filteredProject?.requiredExternalLinks, filteredProject?.optionalExternalLinks) : {
        isEmpty: true,
        items: [{
          value: '',
          anchorText: '',
          label: 'Link',
          placeholder: 'Link',
          fields: [
          ],
          required: true,
        },
        {
          value: '',
          anchorText: '',
          label: 'Link',
          placeholder: 'Link',
          fields: [
          ],
          required: false,
        },
        ],
      };
      setExternalLinksToIncludeState(linksToExclude);
    } else {
      setSelectedToneOfVoice('Professional');
      setTermsToExclude([]);
      setWpId(null);

      setLinksToIncludeState({
        isEmpty: true,
        items: [{
          value: '',
          anchorText: '',
          label: 'Link',
          placeholder: 'Link',
          fields: [

          ],
          required: true,
        },
        {
          value: '',
          anchorText: '',
          label: 'Link',
          placeholder: 'Link',
          fields: [

          ],
          required: false,
        },
        ],
      });
      setExternalLinksToIncludeState({
        isEmpty: true,
        items: [{
          value: '',
          anchorText: '',
          label: 'Link',
          placeholder: 'Link',
          fields: [
          ],
          required: true,
        },
        {
          value: '',
          anchorText: '',
          label: 'Link',
          placeholder: 'Link',
          fields: [
          ],
          required: false,
        },
        ],
      });
    }
  }, [JSON.stringify(projects), visible, activeProjectId]);

  return (
    <StyledModal
      width={830}
      visible={visible}
      onCancel={onClose}
      centered
      closable={false}
      footer={null}
    >
      <ModalContent
        form={form}
        isEdit={isEdit}
        termsToExclude={termsToExclude}
        setTermsToExclude={setTermsToExclude}
        wpId={wpId}
        setWpId={setWpId}
        selectedToneOfVoice={selectedToneOfVoice}
        setSelectedToneOfVoice={setSelectedToneOfVoice}
        currentProjectData={currentProjectData}
        terms={terms}
        setTerms={setTerms}
        linksToIncludeState={linksToIncludeState}
        setLinksToIncludeState={setLinksToIncludeState}
        externalLinksToIncludeState={externalLinksToIncludeState}
        setExternalLinksToIncludeState={setExternalLinksToIncludeState}
        onClose={onClose}
        formKey={formKey}
        showBanner={showBanner}
        setVisible={setVisible}
        setIsEdit={setIsEdit}
      />
    </StyledModal>
  );
};

export default observer(FolderSettings);
