import {Form, Input, Modal, Select} from 'antd';
import PhoneInput from 'react-phone-input-2';
import styled from 'styled-components';

export const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 12px;
    background: #F9F9FB;
    border: 1px solid #E8E8E8;
  }
  .title {
    font-family: Inter;
    font-size: 24px;
    font-weight: 600;
    line-height: 29.05px;
    text-align: left;
    color: #121212;
  }
  .desc {
    margin-top: 5px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: left;
    color: #4E5156;
  }
  .fields-container {
    margin-top: 20px;
  }
  .label {
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
    text-align: left;
    margin-bottom: 5px;
  }
  .ant-form-item {
    margin-bottom: 10px;
  }
  .add-phone-num-button {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #2D6CCA;
    background-color: transparent;
    border: 0px;
    cursor: pointer;
  }
  .input-field-container {
    position: relative;
  }
  .deleteIcon {
    position: absolute;
    right: 3%;
    top: 52%;
    width: 12.25px;
    height: 14px;
    color: #A3A4A4;
    cursor: pointer;
    &.category-delete-icon {
      top: 32px;
    }
  }
  .footer {
    margin-top: 28px;
    display: flex;
    align-items: center;
    gap: 30px;
    .save-button {
      width: 145px;
      height: 44px;
      border-radius: 8px;
      background-color: #2D6CCA;
      color: #FFFFFF;
    }
    .save-button:hover, .save-button:focus {
      background-color: #2D6CCA;
      color: #FFFFFF;
    }
    .cancel-button {
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 16.94px;
      text-align: left;
      color: #4E5156;
      background-color: transparent;
      border: 0px;
      cursor: pointer;
    }
  }
  .fields-heading {
    margin-top: 20px;
    margin-bottom: 5px;
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
    color: #4E5156;
  }
  .social-media-fields-container {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    margin-bottom: 10px;
  }
  .delete-icon {
    margin-top: 10px;
    cursor: pointer;
    width: 15.75px;
    height: 18px;
  }
  .ant-modal-close {
    .ant-modal-close-x {
      font-size: 28px;
      position: absolute;
      top: -17px;
      right: -52px;
  }
    color: #fff;
  }
`;

export const StyledPhoneInput = styled(PhoneInput)<{width?: string}>`
.form-control {
  width: ${p => p?.width ? p.width : ''};
  height: 39px;
  border-radius: 8px;
}
.country-list {
  max-height: 145px !important;
}
.flag-dropdown {
  background-color: #FFFFFF;
  border-radius: 8px 0 0 8px;
}
.selected-flag:hover {
  background-color: transparent !important;
}
`;

export const StyledInput = styled(Input)`
  height: 38px;
  border-radius: 8px;
  border: 1px solid #D8D8D8;
`;

export const StyledSelect = styled(Select)`
.ant-select-selector {
  border-radius: 8px !important;
  height: 38px !important;
}
.ant-select-selection-item, .ant-select-selection-search, .ant-select-selection-placeholder {
  margin-top: 2px;
}
`;

export const SocialMediaSelect = styled(Select)`
width: 160px !important;
height: 38px !important;
.ant-select-selector {
  border-radius: 8px !important;
  height: 38px !important;
}
.ant-select-selection-item {
  margin-top: 8px;
}
.ant-select-dropdown {
  border-radius: 8px !important;
}
.ant-select-selection-placeholder {
  margin-top: 2px;
}
`;

export const Option = styled.div`
  display: flex;
  align-items: center;
  gap: 7px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.94px;
  color: #121212;
  img {
    width: 20px;
    height: 20px;
    border-radius: 4px;
  }
`;

export const StyledFormItem = styled(Form.Item)`
.ant-form-item-explain-error {
  /* position: absolute; */
}
`;
