import {faXmark} from '@fortawesome/pro-regular-svg-icons';
import {faCaretDown} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Dropdown, Menu, Tooltip, Tooltip as AntdCommonTooltip} from 'antd';
import {observer} from 'mobx-react-lite';
import numeral from 'numeral';
import {useState, useEffect} from 'react';
import {TableDateRangePickerV2} from './sections/dateRangeAndCountryFilters';
import FilterKeywordPopUp from './sections/keywordsFilter';
import FilterIncludesKeywordPopUp from './sections/includeskeywordsFilter';
import MoreFilters from './sections/moreFiltersBtn';
import FilterMultipleCheckboxPopUp from './sections/multipleCheckbox';
import FilterMultipleRadioPopUp from './sections/multipleRadioButtons';
import FilterPopUp from './sections/rangeInputFilter';
import styles from './style.module.scss';
import {faMagnifyingGlass} from '@fortawesome/pro-light-svg-icons';
import {FiltersList, FilterTag, FilterTagText, SortContainer, SortItem, MainFilterContainer, LocationFilter, StyledBoldText} from './styledComponents';
import {RingLoaderSmall} from '../../components/skeleton/ring-loader-small';
import {useStore} from '@/store/root-store';
import {useTranslation} from 'next-i18next';
import FilterKeywordPopUpSimple from './sections/keywordsFilterSimple';
import FilterKeywordPopUpCheckboxes from './sections/keywordsFilterCheckboxes';
import {FilterWithData} from './sections/filterWithData';
import useWindowSize from '@/utils/hooks/useWindowSize';
import {CustomDateRangeList} from '../customRangeDatePicker';
import {LocationSelect} from '@/components/dashboard/pages/keyword-rank-tracker/add-new-website/location-select';
import FilterMultipleCheckPopUp from './sections/multipleCheck';
import FilterBrandedKeywordPopup from './sections/keywordBranded';

export const FiltersV2 = observer((props: any) => {
  const propsOnChange = props.onChange || function() {};
  // eslint-disable-next-line
  const onKeywordChange = props.onKeywordChange || function() {};
  const notKeywordTerm = props.notKeywordTerm || '';
  const keyWordValue = props.value || null;
  const dataCountText = props?.dataCountText || <></>;
  const isShowSorters = props.showSorters == false ? false : true;
  const popoverVisibile = props.popoverVisibile == true ? true : false;
  const mainFilters = props.mainFilters || [];
  const [keywordTerm, setKeywordTerm] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState(undefined);
  const [selectedId, setSelectedId] = useState(undefined);
  const {gsc: {pagesSearchTable: {filterList, searchTerm, isPagesInit}}} = useStore('');
  const {gsc: {keywordSearchTable: {keywordFilterList, keywordSearchTerm}}} = useStore('');
  const {settings: {customer: {profile: {isSubscriber}}}} = useStore('');
  const {t} = useTranslation('common');
  const location = window.location.pathname;
  const {localSeoScan: {paramsV3}} = useStore('');
  const {width} = useWindowSize();

  useEffect(() => {
    if (props?.keywordEmpty == true) {
      setKeywordTerm('');
    }
  }, [props?.keywordEmpty]);

  useEffect(() => {
    if (keyWordValue) {
      setKeywordTerm(keyWordValue);
    }
  }, [keyWordValue]);


  useEffect(() => {
    if (onKeywordChange && keywordTerm != null) {
      const timeOutId = setTimeout(() => onKeywordChange(keywordTerm), 500);
      return () => clearTimeout(timeOutId);
    }
  }, [keywordTerm]);

  const onChange = w => {
    const currentFilters = mainFilters.map(filter =>{
      if ( filter.name === w.name && filter?.id === w?.id) {
        return w;
      } else {
        return filter;
      }
    });
    propsOnChange(currentFilters);
  };

  const handleDeleteAll = (nameList, idList) => {
    propsOnChange(mainFilters.map(item => {
      if (nameList?.includes(item.name) && idList?.includes(item?.id)) {
        if (nameList?.includes('exclude')) {
          return ({
            ...item,
            text: '',
            query: '',
            type: undefined,
            category: undefined,
            from: undefined,
            equal: undefined,
            to: undefined,
            active: false,
          });
        } else {
          return ({
            ...item,
            type: undefined,
            category: undefined,
            from: undefined,
            equal: undefined,
            to: undefined,
            active: false,
          });
        }
      } else {
        return item;
      }
    }));
  };

  const handleDelete = (name, id) => {
    propsOnChange(mainFilters.map(item => {
      if (item.name === name && item?.id === id) {
        if (name==='exclude') {
          return ({
            ...item,
            text: '',
            query: '',
            type: undefined,
            category: undefined,
            from: undefined,
            to: undefined,
            equal: undefined,
            active: false,
          });
        } else if (name==='wordsInclude') {
          return ({
            ...item,
            text: '',
            query: '',
            type: undefined,
            category: undefined,
            from: undefined,
            to: undefined,
            equal: undefined,
            active: false,
          });
        } else if (name==='wordsExclude') {
          return ({
            ...item,
            text: '',
            query: '',
            type: undefined,
            category: undefined,
            from: undefined,
            to: undefined,
            equal: undefined,
            active: false,
          });
        } else if (name==='search_checkboxes') {
          return ({
            ...item,
            text: '',
            query: '',
            filterFields: [],
            active: false,
          });
        } else if (name==='search_simple') {
          return ({
            ...item,
            text: '',
            query: '',
            type: undefined,
            active: false,
          });
        } else if (name==='brandedkeyword') {
          return ({
            ...item,
            text: '',
            query: '',
            type: undefined,
            active: false,
          });
        } else {
          return ({
            ...item,
            type: undefined,
            category: undefined,
            from: undefined,
            to: undefined,
            equal: undefined,
            active: false,
          });
        }
      } else {
        return item;
      }
    }));
  };

  const handleClick = (value, id) =>{
    if (id == selectedId) {
      setSelectedId('');
    } else {
      setSelectedId(id);
    }
    setSelectedFilter(value);
  };

  return (
    <MainFilterContainer noMargin={props?.noMargin} className={props?.className} style={{width: props.width ? props.width : '100%'}}>
      {!props?.hideTopSection && <FiltersList className={typeof(props.renderManageColumn) == 'function' ? styles.manageColumnWrapper : styles.exportBtn}>
        <span style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: '10px'}}>
          {props?.dateAndCountryPicker ? <div><TableDateRangePickerV2 onDateChange={props?.onDateChange}/></div> : ''}
          {isShowSorters && <>
            <SortContainer style={{marginLeft: props?.dateAndCountryPicker ? 10 : 0, height: 'fit-content', flexWrap: 'wrap'}}>
              {props?.sortList?.length ? props?.sortList?.map(item => {
                return <Tooltip title={item?.isComingSoon ? 'Coming Soon' : item?.title} key={`sort_key_${item?.name}`} >
                  {props?.isSiteExplorer ? <SortItem selected={item?.active}
                    onClick={() => props?.onSortChange(item?.value)}>
                    {item?.name}
                  </SortItem> : <SortItem isComingSoon={item?.isComingSoon} selected={props?.activeSort == item?.value || item?.active}
                    onClick={item?.isComingSoon ? () => {} : () => props?.onSortChange(props?.activeSort == item?.value || item?.active ? '' : item?.value)}>
                    {item?.iconSrc && <img src={item?.iconSrc}/>}&nbsp;{item?.name}
                  </SortItem>}</Tooltip>;
              }) : ''}
              {props?.keywordSortList?.length ? props?.keywordSortList?.map(item => {
                return <SortItem key={`sort_key_${item?.name}`} selected={props?.activeSort == item?.value}
                  onClick={() => props?.onSortChange(props?.activeSort == item?.value ? '' : item?.value)}>
                  {item?.name}
                </SortItem>;
              }) : ''}
            </SortContainer>
            {props.sortSecondList?.length ? <SortContainer style={{height: 'fit-content', flexWrap: 'wrap'}}>
              {props?.sortSecondList?.map(item => {
                return <Tooltip title={item?.title} key={`sort_key_${item?.name}`} >
                  {props?.isSiteExplorer ? <SortItem selected={item?.active}
                    onClick={() => props?.onSecondSortChange(item?.value)}>
                    {item?.name}
                  </SortItem> : <SortItem selected={item?.active}
                    onClick={() => props?.onSecondSortChange(item?.active ? '' : item?.value)}>
                    {item?.name}
                  </SortItem>}</Tooltip>;
              })}
            </SortContainer> : ''}
          </>}
          <div className='data-count'>{dataCountText}</div>
          {props.selectedComponent}
        </span>
        {typeof(props.renderManageColumn) == 'function' && <div style={{display: 'flex'}}>{props?.renderManageColumn && props?.renderManageColumn()}
          {!(location == '/gsc/top-keywords') && props?.renderManageColumn && isPagesInit ?
            <Tooltip title='Data is being loaded'>
              <div style={{display: 'flex', alignItems: 'center'}}>
                <RingLoaderSmall height='1px' />
              </div>
            </Tooltip>:
            <AntdCommonTooltip
              title={!isSubscriber ? t('feature-disabled-on-free-plan') : ''}
              overlayClassName={styles.customTooltip}
              placement='top'>
              {props?.activeKey == '1' ?
                searchTerm !== '' || filterList.some(item => item.active) ?
                  <Dropdown trigger={['click']} placement='bottomLeft' visible={popoverVisibile}
                    overlay={
                      <Menu style={{width: 220, borderRadius: '10px', padding: '15px'}}>
                        <div>{props?.popoverContent}</div>
                      </Menu>
                    }>
                    <div>{props?.exportToCSVcode}</div>
                  </Dropdown> :
                  <div>{props?.exportToCSVcode}</div> :
                keywordSearchTerm !== '' || keywordFilterList.some(item => item.active) ?
                  <Dropdown trigger={['click']} placement='bottomLeft' visible={popoverVisibile}
                    overlay={
                      <Menu style={{width: 220, borderRadius: '10px', padding: '15px'}}>
                        <div>{props?.popoverContent}</div>
                      </Menu>
                    }>
                    <div>{props?.exportToCSVcode}</div>
                  </Dropdown> :
                  <div>{props?.exportToCSVcode}</div>}
            </AntdCommonTooltip>
          }
        </div>}
        <div style={{paddingRight: '10px', display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: '14px'}} className='font-adjust'>
          {props?.filtersV2AddToBtn ? props?.filtersV2AddToBtn : ''}
          {props?.createButton ? props?.createButton : ''}
          {props?.exportButton ? props?.exportButton : ''}
        </div>
      </FiltersList>}
      <div className='filter-container' style={{width: '100%', display: 'flex', justifyContent: 'space-between', whiteSpace: 'nowrap'}}>
        <FiltersList>
          {props?.isDatePicker && <CustomDateRangeList {...props}/>}
          {props?.renderExtraItemAtStart || ''}
          {mainFilters?.length ? mainFilters?.filter(z => !props?.inMoreBtnIds?.includes(z?.id))?.filter(filter => props?.hideOnUI?.length ? !props?.hideOnUI?.includes(filter?.id) : true)?.map(item => {
            return item.name !== 'location_filter' ? <Dropdown destroyPopupOnHide key={`popupId_${item?.id}`} trigger={['click']} placement='bottomLeft'
              overlay={
                <Menu style={{...(item?.name == 'filter_with_data' && {maxHeight: '380px', padding: '12px', marginLeft: width <= 420 ? '30px' :0, overflowY: 'auto'}),
                  width: item?.name == 'radioButtonsFilter' ? 300 : item?.name == 'filter_with_data' ? ( width <= 420 ? 350 : 420 ): item?.name == 'wordsInclude' || item?.name == 'wordsExclude' ? 280 : item.name == 'brandedkeyword' ? 448 : 220,
                  borderRadius: '10px'}}>{
                    item.name.includes('brandedkeyword') ? <FilterBrandedKeywordPopup
                      filterId={item.id}
                      type={item?.type}
                      onChange={onChange}
                      onApplyClick={props?.onApplyClick}
                      filterLabel={item.header}
                      filterName={item.name}
                      customFields={item?.customFields}
                      className={styles.popupClasses}
                      filterField={item?.filterField}
                      queryVal={item?.query}
                      brandedKeywordsList={props?.brandedKeywordsList}
                    /> : (selectedFilter?.includes('exclude') && item.name.includes('exclude')) ? (
                      <FilterKeywordPopUp
                        filterId={item.id}
                        type={item?.type}
                        onChange={onChange}
                        filterLabel={item.header}
                        filterName={item.name}
                        customFields={item?.customFields}
                        className={styles.popupClasses}
                        filterField={item?.filterField}
                        queryVal={item?.query}
                      />) : (
                      ( item.name !== 'exclude' && selectedFilter === item.name) && (
                        item?.name == 'checkboxes' && typeof item?.filterTypes !== 'undefined' ?
                          <FilterMultipleCheckboxPopUp
                            categories={item?.filterTypes}
                            options={item?.filterTypes}
                            checkedValues={item?.category}
                            allowSingleBox={item?.allowSingleBox}
                            className={styles.checkBoxFiltersStyled}
                            onChange={onChange}
                            filterId={item.id}
                            filterLabel={item.header}
                            filterName={item.name}
                            customCategoryType={item?.customCategoryType}
                          /> :
                          item?.name == 'checks' && typeof item?.filterTypes !== 'undefined' ?
                            <FilterMultipleCheckPopUp
                              categories={item?.filterTypes}
                              options={item?.filterTypes}
                              checkedValues={item?.category}
                              allowSingleBox={item?.allowSingleBox}
                              className={styles.checkBoxFiltersStyled}
                              onChange={onChange}
                              filterId={item.id}
                              filterLabel={item.header}
                              filterName={item.name}
                              customCategoryType={item?.customCategoryType}
                            /> :
                            item?.name == 'radioButtonsFilter' ?
                              <FilterMultipleRadioPopUp
                                options={item?.filterTypes}
                                className={styles.checkBoxFiltersStyled}
                                onChange={onChange}
                                filterId={item.id}
                                filterLabel={item.header}
                                filterName={item.name}
                                key={item.header}
                                customCategoryType={item?.customCategoryType}
                                initialValue={item?.type}
                              /> :
                              item?.name == 'radioButtonsFilterColumnAligned' ?
                                <FilterMultipleRadioPopUp
                                  options={item?.filterTypes}
                                  className={styles.checkBoxFiltersStyled}
                                  onChange={onChange}
                                  filterId={item.id}
                                  filterLabel={item.header}
                                  filterName={item.name}
                                  key={item.header}
                                  columnAligned={true}
                                  customCategoryType={item?.customCategoryType}
                                /> :
                                item?.name == 'search_checkboxes' ?
                                  <FilterKeywordPopUpCheckboxes
                                    filterId={item.id}
                                    categories={item?.filterTypes}
                                    options={item?.filterTypes}
                                    checkedValues={item?.filterFields}
                                    onChange={onChange}
                                    filterLabel={item.header}
                                    filterName={item.name}
                                    customFields={item?.customFields}
                                    className={styles.popupClasses}
                                    filterField={item?.filterField}
                                    queryVal={item?.query}
                                  /> :
                                  item?.name == 'search_simple' ?
                                    <FilterKeywordPopUpSimple
                                      filterId={item.id}
                                      type={item?.type}
                                      onChange={onChange}
                                      filterLabel={item.header}
                                      filterName={item.name}
                                      className={styles.popupClasses}
                                      filterField={item?.filterField}
                                      queryVal={item?.query}
                                    /> :
                                    item?.name == 'wordsInclude' ?
                                      <FilterIncludesKeywordPopUp
                                        filterId={item.id}
                                        type={item?.type}
                                        onChange={onChange}
                                        filterLabel={item.header}
                                        filterName={item.name}
                                        customFields={item?.customFields}
                                        className={styles.popupClasses}
                                        filterField={item?.filterField}
                                        queryVal={item?.query}
                                      /> :
                                      item?.name == 'wordsExclude' ?
                                        <FilterIncludesKeywordPopUp
                                          filterId={item.id}
                                          type={item?.type}
                                          onChange={onChange}
                                          filterLabel={item.header}
                                          filterName={item.name}
                                          customFields={item?.customFields}
                                          className={styles.popupClasses}
                                          filterField={item?.filterField}
                                          queryVal={item?.query}
                                        /> :
                                        item?.name == 'filter_with_data' ?
                                          <FilterWithData
                                            setAddMore={props?.setAddMore}
                                            selectedIds={props?.selectedIds}
                                            setSelectedIds={props?.setSelectedIds}
                                            dataOnChange={props?.dataOnChange}/> :
                                          <FilterPopUp
                                            removeItem={()=> {
                                              handleDelete(item.header, item?.id);
                                            }}
                                            type={item.type}
                                            initialFrom={item.from}
                                            initialTo={item.to}
                                            initialEqual={item?.equal}
                                            isDecimals={item?.isDecimals}
                                            maxLimit={item?.maxLimit}
                                            onChange={onChange}
                                            filterId={item?.id}
                                            filterLabel={item.header}
                                            filterName={item.name}
                                            className={styles.popupClasses}
                                            customOptions={item?.customOptions}
                                            customOptionsTop={item?.customOptionsTop}
                                            allowOneField={true}
                                          />)
                    )
                  }</Menu>
              }>
              <FilterTag isDarkTheme={props?.isDarkTheme} selected={item?.active} key={item.id} onClick={()=> handleClick(item.name, item.id)}>
                <FilterTagText isDarkTheme={props?.isDarkTheme} selected={item?.active}>
                  {!item?.active ? <>
                    {item?.isSearch ? <><FontAwesomeIcon icon={faMagnifyingGlass} style={{fontSize: '13px'}}/>&nbsp;</> : ''}
                    <span style={{marginRight: '4px'}}>{`${item.header}`}</span>
                    <div style={{marginLeft: '8px'}}>
                      <FontAwesomeIcon icon={faCaretDown} />
                    </div>
                  </> : <>
                    {item?.isSearch? <><FontAwesomeIcon icon={faMagnifyingGlass} style={{fontSize: '13px'}}/>&nbsp;</> : ''}
                    <span style={{marginRight: '4px'}}>{`${item.header}: `}</span>
                    {item.name == 'options' ? <StyledBoldText maxWidth={props?.maxSelectedTextWidth}>{item.type}</StyledBoldText> :
                      item?.name == 'filter_with_data' ? <StyledBoldText maxWidth={props?.maxSelectedTextWidth}>{paramsV3?.business_ids?.length} selected</StyledBoldText> :
                        item.name == 'search_checkboxes' ? <StyledBoldText maxWidth={props?.maxSelectedTextWidth}>{item.query}</StyledBoldText> :
                          item.name == 'search_simple' ? <StyledBoldText maxWidth={props?.maxSelectedTextWidth}>{item.query}</StyledBoldText> :
                            item.name == 'exclude' ? <StyledBoldText maxWidth={props?.maxSelectedTextWidth}>{item?.query}</StyledBoldText> :
                              item.name == 'checkboxes' ? <StyledBoldText maxWidth={props?.maxSelectedTextWidth}>{item?.category?.join(',')}</StyledBoldText> :
                                item?.name == 'radioButtonsFilter' ? <StyledBoldText maxWidth={props?.maxSelectedTextWidth}>{item?.filterTypes?.find(x => x?.value == item?.type)?.label || ''}</StyledBoldText> :
                                  item?.name == 'wordsInclude' ? <StyledBoldText maxWidth={props?.maxSelectedTextWidth}>{item?.query?.split(',')?.length}</StyledBoldText> :
                                    item?.name == 'wordsExclude' ? <StyledBoldText maxWidth={props?.maxSelectedTextWidth}>{item?.query?.split(',')?.length}</StyledBoldText> :
                                      item?.name == 'brandedkeyword' ? <StyledBoldText maxWidth={props?.maxSelectedTextWidth}>{item?.query == 'branded_keyword_filter=false' ? 'Exclude' : 'Only Branded'}</StyledBoldText> :
                                        <span style={{marginLeft: '4px'}}>
                                          {item?.equal ? <>
                                            <StyledBoldText maxWidth={props?.maxSelectedTextWidth}>{`${numeral(item.equal).format('0.[0][a]').toUpperCase()}`}</StyledBoldText>
                                          </> : <>
                                            {(item?.from == '0' && item?.to == '1') ?
                                              <StyledBoldText maxWidth={props?.maxSelectedTextWidth}>{`${item.to == '' ? 'Max' : numeral(item.to).format('0.[0][a]').toUpperCase()}`}</StyledBoldText> :
                                              <StyledBoldText maxWidth={props?.maxSelectedTextWidth}>{`${numeral(item.from).format('0.[0][a]').toUpperCase()} - ${item.to == '' ? 'Max' : numeral(item.to).format('0.[0][a]').toUpperCase()}`}</StyledBoldText>
                                            }
                                          </>}
                                        </span>
                    }
                    {item.text ? notKeywordTerm :''}
                    {<div
                      onClick={e=>{
                        e.stopPropagation();
                        handleDelete(item.name, item?.id);
                        item?.name == 'filter_with_data' && props?.setSelectedIds([]);
                      }}
                      style={{marginLeft: '8px', borderLeft: '1px solid #B498D0', paddingLeft: '7px'}}>
                      <FontAwesomeIcon icon={faXmark}/>
                    </div>}
                  </>}
                </FilterTagText>
              </FilterTag>
            </Dropdown> : (
              <LocationFilter>
                <LocationSelect
                  variant='light'
                  width={item?.query ? '180px' : 'auto'}
                  height='28px'
                  padding='0px 5px'
                  marginLeft='0px'
                  isFilter={true}
                  allowClear={true}
                  excludeWorldwide
                  suffixIcon={<FontAwesomeIcon color='#A3A4A4' icon={faCaretDown}/>}
                  clearIcon={<FontAwesomeIcon color='#000' icon={faXmark}/>}
                  onChange={location => {
                    if (typeof props?.handleLocationChange == 'function') {
                      props.handleLocationChange(mainFilters, location?.value || '', item);
                    }
                  }}
                />
              </LocationFilter>
            );
          }) : ''}
          {mainFilters?.filter(z => props?.inMoreBtnIds?.includes(z?.id))?.length ? (
            <MoreFilters
              onFilter={props.onFilter}
              isIssuePage={props?.isIssuePage}
              mainFilters={mainFilters?.filter(z => props?.inMoreBtnIds?.includes(z?.id))}
              onChange={onChange}
              handleDelete={handleDelete}
              handleDeleteAll={handleDeleteAll}
              specialFilterHeading={props?.specialFilterHeading}
              specialFilterKeys={props?.specialFilterKeys}
              simpleMoreBtn={props?.simpleMoreBtn}
            />
          ) : ''}
          {props?.renderExtraItemAfterFilters || ''}
          <div style={{display: 'flex'}}>
            {props?.isDigitalPrPage && <div style={{padding: '5px 10px'}}>{dataCountText}</div>}
          </div>
        </FiltersList>
        {props?.isDigitalPrPage && props?.exportButton ? <div>{props?.exportButton}</div> : ''}
      </div>
    </MainFilterContainer>
  );
});
